// validations.ts
import { 
  CampaignFormData, 
  ValidationErrors, 
  Sender, 
  ValidationResult,
  CampaignType,
  ApiCampaignType 
} from './interfaces';
import { WhatsAppTemplate } from './whatsappApi';
// Utility validation functions
const isValidURL = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
interface WhatsAppTemplateWithVariables extends WhatsAppTemplate {
  variables: Record<string, any>;
}
const isValidPhoneNumber = (number: string, type: CampaignType): boolean => {
  const cleanNumber = number.trim().replace(/[\s,-]/g, '');
  
  if (type === 'WhatsApp') {
    return /^\+91\d{10}$/.test(cleanNumber);
  }
  
  // For SMS, allow both formats
  if (cleanNumber.startsWith('+91')) {
    return /^\+91\d{10}$/.test(cleanNumber);
  }
  return /^\d{10}$/.test(cleanNumber);
};

const isValidCommaSeperatedNumbers = (numbers: string, type: CampaignType): boolean => {
  if (!numbers) return false;
  const numberArray = numbers.split(',').map(n => n.trim());
  return numberArray.every(num => isValidPhoneNumber(num, type));
};

const validateCampaignName = (campaignName: string): string | undefined => {
  if (!campaignName?.trim()) {
    return 'Campaign name is required';
  }
  if (campaignName.length < 3 || campaignName.length > 50) {
    return 'Campaign name must be between 3 and 50 characters';
  }
  if (!/^[a-zA-Z][a-zA-Z0-9_]{2,49}$/.test(campaignName)) {
    return 'Campaign name must start with a letter and can only contain letters, numbers, and underscores';
  }
  return undefined;
};

const validateWhatsAppHeaderFile = (file: File, headerType: string): string | undefined => {
  if (!file) {
    return `${headerType} file is required`;
  }

  const validations = {
    header_image: {
      types: ['.jpg', '.jpeg', '.png'],
      mimeTypes: ['image/jpeg', 'image/jpg', 'image/png'],
      maxSize: 5 * 1024 * 1024, // 5MB
      errorMessage: 'Only JPG, JPEG, PNG files under 5MB are allowed'
    },
    header_video: {
      types: ['.mp4'],
      mimeTypes: ['video/mp4'],
      maxSize: 16 * 1024 * 1024, // 16MB
      errorMessage: 'Only MP4 files under 16MB are allowed'
    },
    header_document: {
      types: ['.pdf', '.doc', '.docx'],
      mimeTypes: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ],
      maxSize: 10 * 1024 * 1024, // 10MB
      errorMessage: 'Only PDF, DOC, DOCX files under 10MB are allowed'
    }
  };

  const validation = validations[headerType as keyof typeof validations];
  if (!validation) {
    return 'Invalid header type';
  }

  // Keep existing validateFileUpload for bulk uploads
const validateBulkUploadFile = (file: File | null | undefined): string | undefined => {
  if (!file) {
    return 'File upload is required for bulk campaigns';
  }

  const allowedTypes = ['.csv', '.xlsx', '.xls'];
  const fileExt = file.name.toLowerCase().slice(
    Math.max(0, file.name.lastIndexOf('.'))
  );
  
  if (!allowedTypes.includes(fileExt)) {
    return 'Only CSV and Excel files are allowed';
  }

  const maxSize = 5 * 1024 * 1024; // 5MB
  if (file.size > maxSize) {
    return 'File size cannot exceed 5MB';
  }

  return undefined;
};
  // Check file extension
  const fileExt = file.name.toLowerCase().slice(
    Math.max(0, file.name.lastIndexOf('.'))
  );
  if (!validation.types.includes(fileExt)) {
    return validation.errorMessage;
  }

  // Check MIME type
  if (!validation.mimeTypes.includes(file.type)) {
    return validation.errorMessage;
  }

  // Check file size
  if (file.size > validation.maxSize) {
    return `File size cannot exceed ${validation.maxSize / (1024 * 1024)}MB`;
  }

  return undefined;
};

// In validations.ts

const validateSchedule = (formData: CampaignFormData, type: CampaignType): ValidationErrors => {
  const errors: ValidationErrors = {};

  // Basic date validation
  if (!formData.startDate) {
    errors.startDate = 'Start date is required';
  }
  if (!formData.endDate) {
    errors.endDate = 'End date is required';
  }
  if (formData.startDate && formData.endDate) {
    const start = new Date(formData.startDate);
    const end = new Date(formData.endDate);
    if (end < start) {
      errors.endDate = 'End date must be after start date';
    }
  }

  // Time validation
  if (!formData.runTimeStart || !formData.runTimeEnd) {
    errors.runTimeStart = !formData.runTimeStart ? 'Start time is required' : undefined;
    errors.runTimeEnd = !formData.runTimeEnd ? 'End time is required' : undefined;
    return errors;
  }

  const parseTime = (timeStr: string): { hours: number; minutes: number; isValid: boolean } => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return {
      hours,
      minutes,
      isValid: !isNaN(hours) && !isNaN(minutes)
    };
  };

  const startTime = parseTime(formData.runTimeStart);
  const endTime = parseTime(formData.runTimeEnd);

  if (!startTime.isValid || !endTime.isValid) {
    errors.runTimeStart = 'Invalid time format';
    return errors;
  }

  const startMinutes = startTime.hours * 60 + startTime.minutes;
  const endMinutes = endTime.hours * 60 + endTime.minutes;

  // Apply time restrictions only for promotional SMS
  if (type === 'SMS' && formData.templateType === 'promotional') {
    // Validate 9 AM to 8:59 PM restriction
    if (startTime.hours < 9 || startTime.hours > 20 || 
        (startTime.hours === 20 && startTime.minutes > 59)) {
      errors.runTimeStart = 'Start time must be between 09:00 and 20:59';
    }

    if (endTime.hours < 9 || endTime.hours > 20 || 
        (endTime.hours === 20 && endTime.minutes > 59)) {
      errors.runTimeEnd = 'End time must be between 09:00 and 20:59';
    }

    // Only check minimum duration for promotional SMS
    if (!errors.runTimeStart && !errors.runTimeEnd) {
      if (startMinutes >= endMinutes) {
        errors.runTimeEnd = 'End time must be after start time';
      } else {
        const duration = endMinutes - startMinutes;
        if (duration < 30) {
          errors.runTimeEnd = 'Campaign duration must be at least 30 minutes';
        }
      }
    }
  } else {
    // For transactional SMS and WhatsApp, only validate time sequence
    if (startMinutes >= endMinutes) {
      errors.runTimeEnd = 'End time must be after start time';
    }
  }

  return errors;
};


const validateBulkUploadFile = (file: File | null | undefined): string | undefined => {
  if (!file) {
    return 'File upload is required for bulk campaigns';
  }

  const allowedTypes = ['.csv', '.xlsx', '.xls'];
  const fileExt = file.name.toLowerCase().slice(
    Math.max(0, file.name.lastIndexOf('.'))
  );
  
  if (!allowedTypes.includes(fileExt)) {
    return 'Only CSV and Excel files are allowed';
  }

  const maxSize = 5 * 1024 * 1024; // 5MB
  if (file.size > maxSize) {
    return 'File size cannot exceed 5MB';
  }

  return undefined;
};
const validateFileUpload = (file: File | null | undefined): string | undefined => {
  if (!file) {
    return 'File upload is required for bulk campaigns';
  }

  const allowedTypes = ['.csv', '.xlsx', '.xls'];
  const fileExt = file.name.toLowerCase().slice(
    Math.max(0, file.name.lastIndexOf('.'))
  );
  
  if (!allowedTypes.includes(fileExt)) {
    return 'Only CSV and Excel files are allowed';
  }

  const maxSize = 5 * 1024 * 1024; // 5MB
  if (file.size > maxSize) {
    return 'File size cannot exceed 5MB';
  }

  return undefined;
};

export const validateCampaign = (
  formData: CampaignFormData, 
  type: CampaignType,
  availableSenders: Sender[] = [],
  whatsappTemplates?: WhatsAppTemplate[]
): ValidationResult => {
  const errors: ValidationErrors = {};

  // Form data validation
  if (!formData) {
    errors.form = 'Form data is required';
    return { isValid: false, errors };
  }



  // Common validations
  const campaignNameError = validateCampaignName(formData.campaignName);
  if (campaignNameError) {
    errors.campaignName = campaignNameError;
  }

  // Schedule validation
// Update this line in validateCampaign
  Object.assign(errors, validateSchedule(formData, type));

  // Add in validateCampaign function, inside the WhatsApp specific validations
if (type === 'WhatsApp') {

  const selectedTemplate = whatsappTemplates?.find((t: WhatsAppTemplate) => 
  t.name === formData.templateId
);

if (selectedTemplate && selectedTemplate.variables) {
  Object.entries(selectedTemplate.variables).forEach(([name, required]) => {
    if (required) {
      if (name.startsWith('header_')) {
        const value = formData.extraData[name];
        if (value instanceof File) {
          const fileError = validateWhatsAppHeaderFile(value, name);
          if (fileError) {
            errors[name] = fileError;
          }
        } else if (!value) {
          errors[name] = `${name.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} is required`;
        }
      } else if (!formData.extraData[name]) {
        errors[name] = `${name.split('_').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ')} is required`;
      }
    }
  });
}


// Validate file uploads
if (selectedTemplate && formData.extraData) {
  Object.entries(formData.extraData).forEach(([key, value]) => {
    if (key.includes('header_') && value instanceof File) {
      const fileError = validateFileUpload(value);
      if (fileError) {
        errors[key] = fileError;
      }
    }
  });
}

   // Validate bulk upload file if needed
   if (formData.messageType === 'Bulk' && formData.fileUpload) {
    const fileError = validateBulkUploadFile(formData.fileUpload);
    if (fileError) {
      errors.fileUpload = fileError;
    }
  }
} else {
  // SMS bulk upload validation
  if (formData.messageType === 'Bulk') {
    const fileError = validateBulkUploadFile(formData.fileUpload);
    if (fileError) {
      errors.fileUpload = fileError;
    }
  }

  // Validate file uploads
  Object.entries(formData.extraData).forEach(([key, value]) => {
    if (key.includes('header_') && value instanceof File) {
      const fileError = validateFileUpload(value);
      if (fileError) {
        errors[key] = fileError;
      }
    }
  });
}

  // Type-specific validations
  if (type === 'SMS') {
    // SMS Category validation
    if (!formData.category) {
      errors.category = 'Category is required';
    } else if (!['promotional', 'transactional', 'service_implicit', 'service_explicit', 'simple', 'dynamic'].includes(formData.category)) {
      errors.category = 'Invalid campaign category';
    }

    // Message type validation
    if (!formData.messageType) {
      errors.messageType = 'Message type is required';
    }

    // Validate sender ID more strictly
    if (!formData.senderId) {
      errors.senderId = 'Sender ID is required';
    } else {
      const selectedSender = availableSenders.find(s => 
        s.id.toString() === formData.senderId ||
        s.name === formData.senderId
      );
      if (!selectedSender) {
        errors.senderId = 'Please select a valid Sender ID';
      } else if (!selectedSender.approved) {
        errors.senderId = 'Selected Sender ID is not approved';
      }
    }

    if (!formData.smsVendor) {
      errors.smsVendor = 'SMS Vendor is required';
    }

    // Template validation
    if (!formData.templateId) {
      errors.templateId = 'SMS template is required';
    }

    // Numbers/File validation
    if (formData.messageType === 'Simple') {
      if (!formData.numbers?.trim()) {
        errors.numbers = 'Phone numbers are required for simple messages';
      } else if (!isValidCommaSeperatedNumbers(formData.numbers, type)) {
        errors.numbers = 'Invalid phone number format. Use comma-separated 10-digit numbers';
      }
    } else if (formData.messageType === 'Bulk') {
      const fileError = validateFileUpload(formData.fileUpload);
      if (fileError) {
        errors.fileUpload = fileError;
      }
    }
  } else {
    // WhatsApp specific validations
    if (!formData.category) {
      errors.category = 'Category is required';
    } else if (!['dynamic', 'simple'].includes(formData.category)) {
      errors.category = 'Invalid category';
    }
  
    if (!formData.senderId) {
      errors.senderId = 'WhatsApp number is required';
    } else if (!/^\+91\d{10}$/.test(formData.senderId.trim())) {
      errors.senderId = 'WhatsApp number must be in format: +91XXXXXXXXXX';
    }
  
    if (!formData.templateId) {
      errors.templateId = 'Template name is required';
    }
  
    if (!formData.templateText) {
      errors.templateText = 'Template text is required';
    }
  
    // Numbers validation only for Simple message type
    if (formData.messageType === 'Simple') {
      if (!formData.numbers) {
        errors.numbers = 'At least one phone number is required';
      } else if (!isValidCommaSeperatedNumbers(formData.numbers, type)) {
        errors.numbers = 'All numbers must be in +91XXXXXXXXXX format';
      }
    }
  
    // File validation only for Bulk message type
    if (formData.messageType === 'Bulk') {
      const fileError = validateFileUpload(formData.fileUpload);
      if (fileError) {
        errors.fileUpload = fileError;
      }
    }
  
    // Callback URL validation if provided
    if (formData.callbackUrl && !isValidURL(formData.callbackUrl)) {
      errors.callbackUrl = 'Please enter a valid URL';
    }
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};