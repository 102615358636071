import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  SelectChangeEvent
} from '@mui/material';
import { Search, Plus, Edit } from 'lucide-react';
import axios from 'axios';
import { getAuthToken, getUserId } from '../../../services/auth-service';

const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';

// Type definitions
interface RateTransaction {
  id: number;
  rateType: string;
  transactionType: string;
  transactionValue: number;
  createdBy: number;
  createdOn: string;
  userId: number;
  userName: string;
}

interface SnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

interface RateTransactionDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmitSuccess: (message: string) => void;
  userId: number | null;
}

interface FormData {
  rateType: string;
  transactionType: string;
  transactionValue: string | number;
}

// Shared Components
const ErrorSnackbar: React.FC<SnackbarProps> = ({ open, message, onClose }) => (
  <Snackbar 
    open={open} 
    autoHideDuration={6000} 
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert 
      onClose={onClose} 
      severity="error" 
      sx={{ 
        width: '100%',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: '#FEE2E2',
        color: '#DC2626',
        '& .MuiAlert-icon': {
          color: '#DC2626'
        }
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

const SuccessSnackbar: React.FC<SnackbarProps> = ({ open, message, onClose }) => (
  <Snackbar 
    open={open} 
    autoHideDuration={3000} 
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert 
      onClose={onClose} 
      severity="success" 
      sx={{ 
        width: '100%',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: '#DCFCE7',
        color: '#16A34A',
        '& .MuiAlert-icon': {
          color: '#16A34A'
        }
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

// Create Dialog Component
const RateTransactionDialog: React.FC<RateTransactionDialogProps> = ({ 
  open, 
  onClose, 
  onSubmitSuccess, 
  userId 
}) => {
  const [formData, setFormData] = useState<FormData>({
    rateType: '',
    transactionType: '',
    transactionValue: ''
  });
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [rateTypes, setRateTypes] = useState<string[]>([]);
  const [transactionTypes, setTransactionTypes] = useState<string[]>([]);

  useEffect(() => {
    if (userId) {
      loadRateTypes();
      loadTransactionTypes();
    }
  }, [userId]);

  const loadRateTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/rateTransaction/rateTypes`, {
        headers: {
          userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      if (response.data?.data) {
        setRateTypes(response.data.data);
      }
    } catch (error) {
      console.error('Failed to load rate types:', error);
    }
  };

  const loadTransactionTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/rateTransaction/transactionTypes`, {
        headers: {
          userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      if (response.data?.data) {
        setTransactionTypes(response.data.data);
      }
    } catch (error) {
      console.error('Failed to load transaction types:', error);
    }
  };

  const handleChange = (field: keyof FormData) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const value = field === 'transactionValue' 
      ? parseFloat(event.target.value) || ''
      : event.target.value;
    
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    setError('');
  };

  const validateForm = (): boolean => {
    if (!formData.rateType) {
      setError('Rate type is required');
      return false;
    }
    if (!formData.transactionType) {
      setError('Transaction type is required');
      return false;
    }
    if (!formData.transactionValue || Number(formData.transactionValue) <= 0) {
      setError('Valid transaction value is required');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/rateTransaction`,
        formData,
        {
          headers: {
            userId: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        }
      );

      if (response.data?.status === 'success') {
        onSubmitSuccess('Rate transaction created successfully');
        onClose();
      } else {
        throw new Error(response.data.message || 'Operation failed');
      }
    } catch (error) {
      console.error('Failed to submit rate transaction:', error);
      setError(error instanceof Error ? error.message : 'Failed to save rate transaction. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '8px',
          width: '100%',
          maxWidth: '600px'
        }
      }}
    >
      <Box sx={{ borderBottom: '1px solid #E6E6E6', p: 3 }}>
        <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 500 }}>
          Create Rate Transaction
        </Typography>
      </Box>

      <DialogContent sx={{ p: 3 }}>
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 2,
              fontFamily: 'Roboto',
              fontSize: '12px',
              backgroundColor: '#FEE2E2',
              color: '#DC2626'
            }}
          >
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <FormControl fullWidth>
              <Select
                value={formData.rateType}
                onChange={handleChange('rateType')}
                displayEmpty
                renderValue={selected => selected || "Rate Type"}
                sx={{ height: '48px' }}
              >
                {rateTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <Select
                value={formData.transactionType}
                onChange={handleChange('transactionType')}
                displayEmpty
                renderValue={selected => selected || "Transaction Type"}
                sx={{ height: '48px' }}
              >
                {transactionTypes.map(type => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Transaction Value *"
                type="number"
                value={formData.transactionValue}
                onChange={handleChange('transactionValue')}
                required
                inputProps={{ 
                  step: "0.01",
                  min: "0"
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: '48px'
                  }
                }}
              />
            </FormControl>
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 2, 
            mt: 4,
            borderTop: '1px solid #E6E6E6',
            pt: 3
          }}>
            <Button
              onClick={onClose}
              variant="outlined"
              disabled={loading}
              sx={{
                height: '36px',
                textTransform: 'none',
                borderColor: '#E6E6E6',
                color: '#666666'
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                height: '36px',
                textTransform: 'none',
                backgroundColor: '#4B87FF',
                '&:hover': {
                  backgroundColor: '#3D78E6'
                }
              }}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
              ) : 'Create'}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const RateTransactions: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [rateTransactions, setRateTransactions] = useState<RateTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [userId, setUserId] = useState<number | null>(null);

  useEffect(() => {
    const id = getUserId();
    if (!id) {
      handleError('User ID not found');
      return;
    }
    setUserId(parseInt(id));
  }, []);

  const handleError = (message: string) => {
    setError(message);
    setTimeout(() => setError(''), 6000);
  };

  const handleSuccess = (message: string) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const loadRateTransactions = async () => {
    if (!userId) return;
    
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/rateTransaction`, {
        params: { page, size: rowsPerPage },
        headers: {
          userId: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      
      if (response.data?.data?.content) {
        setRateTransactions(response.data.data.content);
      }
    } catch (error) {
      console.error('Error loading rate transactions:', error);
      handleError('Failed to load rate transactions. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      loadRateTransactions();
    }
  }, [userId, page, rowsPerPage]);

  const handleCloseDialog = () => {
    setCreateDialogOpen(false);
  };

  const handleSubmitSuccess = (message: string) => {
    handleSuccess(message);
    loadRateTransactions();
  };

  const filteredData = rateTransactions.filter(transaction =>
    transaction.rateType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    transaction.transactionType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    { id: 'rateType', label: 'Rate Type' },
    { id: 'transactionType', label: 'Transaction Type' },
    { id: 'transactionValue', label: 'Transaction Value' },
    { id: 'createdOn', label: 'Created On' }
  ];

  return (
    <Box sx={styles.container}>
      <ErrorSnackbar 
        open={!!error} 
        message={error} 
        onClose={() => setError('')} 
      />
      <SuccessSnackbar 
        open={!!success} 
        message={success} 
        onClose={() => setSuccess('')} 
      />

      <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          Rate Transactions
        </Typography>
        <Button
          variant="contained"
          startIcon={<Plus size={16} />}
          onClick={() => setCreateDialogOpen(true)}
          sx={styles.createButton}
        >
          New Rate Transaction
        </Button>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Box sx={styles.searchContainer}>
          <Typography sx={styles.listTitle}>
            List of Rate Transactions
            <span className="total">Total {filteredData.length}</span>
          </Typography>
          <Box sx={styles.searchControls}>
            <TextField
              size="small"
              placeholder="Search rate transactions..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={styles.searchInput}
              InputProps={{
                startAdornment: (
                  <Box sx={styles.searchIcon}>
                    <Search size={20} />
                  </Box>
                )
              }}
            />
          </Box>
        </Box>

        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow key={transaction.id} hover>
                  <TableCell>{transaction.rateType}</TableCell>
                  <TableCell>{transaction.transactionType}</TableCell>
                  <TableCell>{transaction.transactionValue}</TableCell>
                  <TableCell>{transaction.createdOn}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Box sx={styles.paginationContainer}>
          <Box sx={styles.rowsPerPage}>
            <Typography>Show</Typography>
            <Select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              size="small"
              sx={{
                height: '32px',
                minWidth: '80px',
                marginLeft: '8px',
                marginRight: '8px'
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography>entries</Typography>
          </Box>

          <Box sx={{ color: '#666666', fontSize: '12px' }}>
            {`${page * rowsPerPage + 1}–${Math.min(
              (page + 1) * rowsPerPage,
              filteredData.length
            )} of ${filteredData.length}`}
          </Box>

          <Box sx={styles.pagination}>
            <Button
              onClick={() => setPage(prev => Math.max(0, prev - 1))}
              disabled={page === 0}
              sx={styles.paginationButton(false)}
            >
              Previous
            </Button>
            {Array.from(
              { length: Math.ceil(filteredData.length / rowsPerPage) },
              (_, i) => (
                <Button
                  key={i}
                  onClick={() => setPage(i)}
                  sx={styles.paginationButton(page === i)}
                >
                  {i + 1}
                </Button>
              )
            )}
            <Button
              onClick={() => setPage(prev => 
                Math.min(Math.ceil(filteredData.length / rowsPerPage) - 1, prev + 1)
              )}
              disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1}
              sx={styles.paginationButton(false)}
            >
              Next
            </Button>
          </Box>
        </Box>
      </TableContainer>

      {loading && (
        <Box sx={styles.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}

      <RateTransactionDialog
        open={createDialogOpen}
        onClose={handleCloseDialog}
        onSubmitSuccess={handleSubmitSuccess}
        userId={userId}
      />
    </Box>
  );
};

// Styles
const styles = {
  container: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '24px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px'
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#111827'
  },
  createButton: {
    backgroundColor: '#4B87FF',
    color: '#fff',
    textTransform: 'none',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#3D78E6'
    }
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    boxShadow: 'none'
  },
  searchContainer: {
    padding: '16px',
    borderBottom: '1px solid #E5E7EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#111827',
    '& .total': {
      marginLeft: '8px',
      fontSize: '14px',
      color: '#6B7280'
    }
  },
  searchControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  searchInput: {
    width: '240px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#E5E7EB'
      },
      '&:hover fieldset': {
        borderColor: '#B0B9C5'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4B87FF'
      }
    }
  },
  searchIcon: {
    color: '#9CA3AF'
  },
  tableHead: {
    backgroundColor: '#F9FAFB',
    '& .MuiTableCell-head': {
      color: '#374151',
      fontWeight: 600,
      fontSize: '14px',
      padding: '12px 16px'
    }
  },
  tableCell: {
    padding: '12px 16px',
    fontSize: '14px',
    color: '#111827'
  },
  actionButton: {
    padding: '8px',
    marginRight: '8px',
    color: '#6B7280',
    '&:hover': {
      backgroundColor: '#F3F4F6'
    }
  },
  paginationContainer: {
    padding: '16px',
    borderTop: '1px solid #E5E7EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowsPerPage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#6B7280',
    fontSize: '14px'
  },
  pagination: {
    display: 'flex',
    gap: '8px'
  },
  paginationButton: (active: boolean) => ({
    minWidth: 'unset',
    padding: '4px 12px',
    color: active ? '#fff' : '#374151',
    backgroundColor: active ? '#4B87FF' : 'transparent',
    border: active ? 'none' : '1px solid #E5E7EB',
    '&:hover': {
      backgroundColor: active ? '#3D78E6' : '#F9FAFB'
    },
    '&:disabled': {
      color: '#D1D5DB',
      borderColor: '#E5E7EB'
    }
  }),
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300
  }
};

export default RateTransactions;