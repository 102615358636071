import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material';
import { Search, Plus, Edit, Trash2, X } from 'lucide-react';
import axios,{AxiosError} from 'axios';
import { getAuthToken, getUserId } from '../../../services/auth-service';

const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';

// Type definitions
interface RateCard {
  id: number;
  rateType: string;
  transactionType: string;
  transactionValue: number;
  createdOn: string;
  updatedOn: string;
}

interface SnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

interface RateCardDialogProps {
    open: boolean;
    onClose: () => void;
    editData: RateCard | null;
    onSubmitSuccess: (message: string) => void;
    userId: number | null;
  }
  
  interface FormData {
    rateType: string;
    transactionType: string;
    transactionValue: string | number;
  }

// Shared Components
const ErrorSnackbar: React.FC<SnackbarProps> = ({ open, message, onClose }) => (
  <Snackbar 
    open={open} 
    autoHideDuration={6000} 
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert 
      onClose={onClose} 
      severity="error" 
      sx={{ 
        width: '100%',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: '#FEE2E2',
        color: '#DC2626',
        '& .MuiAlert-icon': {
          color: '#DC2626'
        }
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

const SuccessSnackbar: React.FC<SnackbarProps> = ({ open, message, onClose }) => (
  <Snackbar 
    open={open} 
    autoHideDuration={3000} 
    onClose={onClose}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Alert 
      onClose={onClose} 
      severity="success" 
      sx={{ 
        width: '100%',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: 500,
        backgroundColor: '#DCFCE7',
        color: '#16A34A',
        '& .MuiAlert-icon': {
          color: '#16A34A'
        }
      }}
    >
      {message}
    </Alert>
  </Snackbar>
);

const RateCardDialog: React.FC<RateCardDialogProps> = ({ 
    open, 
    onClose, 
    editData, 
    onSubmitSuccess, 
    userId 
  }) => {
    const [formData, setFormData] = useState<FormData>(
      editData ? {
        rateType: editData.rateType || '',
        transactionType: editData.transactionType || '',
        transactionValue: editData.transactionValue || ''
      } : {
        rateType: '',
        transactionType: '',
        transactionValue: ''
      }
    );
    const [errors, setErrors] = useState<Record<string, string[]>>({});
    const [loading, setLoading] = useState<boolean>(false);
  
    useEffect(() => {
      if (editData) {
        setFormData({
          rateType: editData.rateType || '',
          transactionType: editData.transactionType || '',
          transactionValue: editData.transactionValue || ''
        });
      }
    }, [editData]);
  
    const handleChange = (field: keyof FormData) => (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
      const value = field === 'transactionValue' 
        ? parseFloat(event.target.value) || ''
        : event.target.value;
      
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
      setErrors({});
    };
  
    const validateForm = (): boolean => {
      const newErrors: Record<string, string[]> = {};
      
      if (!formData.rateType) {
        newErrors.rateType = ['Rate Type is required'];
      }
      
      if (!formData.transactionType) {
        newErrors.transactionType = ['Transaction Type is required'];
      }
      
      if (!formData.transactionValue) {
        newErrors.transactionValue = ['Transaction Value is required'];
      } else if (Number(formData.transactionValue) <= 0) {
        newErrors.transactionValue = ['Transaction Value must be greater than 0'];
      }
      
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!validateForm()) return;
  
      setLoading(true);
      try {
        const endpoint = `${BASE_URL}/rateCard${editData ? `/${editData.id}` : ''}`;
        const method = editData ? 'put' : 'post';
        
        const response = await axios({
          method,
          url: endpoint,
          data: formData,
          headers: {
            userid: userId,
            Authorization: `Bearer ${getAuthToken()}`
          }
        });
  
        if (response.data?.status === 'success') {
          onSubmitSuccess(editData ? 'Rate card updated successfully' : 'Rate card created successfully');
          onClose();
        } else {
          throw new Error(response.data.message || 'Operation failed');
        }
      } catch (err: unknown) {
        console.error('Failed to submit rate card:', err);
        if (axios.isAxiosError(err)) {
          const error = err as AxiosError<{errors?: Record<string, string[]>}>;
          if (error.response?.data?.errors) {
            setErrors(error.response.data.errors);
          } else {
            setErrors({
              general: [error.message || 'Failed to save rate card. Please try again.']
            });
          }
        } else {
          setErrors({
            general: [(err as Error).message || 'Failed to save rate card. Please try again.']
          });
        }
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Dialog 
        open={open} 
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '8px',
            width: '100%',
            maxWidth: '600px'
          }
        }}
      >
        <Box sx={{ borderBottom: '1px solid #E6E6E6', p: 3 }}>
          <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 500 }}>
            {editData ? 'Edit' : 'Create'} Rate Card
          </Typography>
          <IconButton 
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: '16px',
              top: '16px'
            }}
          >
            <X size={20} />
          </IconButton>
        </Box>
  
        <DialogContent sx={{ p: 3 }}>
          {Object.keys(errors).length > 0 && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                fontFamily: 'Roboto',
                fontSize: '12px',
                backgroundColor: '#FEE2E2',
                color: '#DC2626'
              }}
            >
              <ul style={{ margin: 0, paddingLeft: '20px' }}>
                {Object.entries(errors).map(([field, messages]) => 
                  messages.map((message, index) => (
                    <li key={`${field}-${index}`}>{message}</li>
                  ))
                )}
              </ul>
            </Alert>
          )}
  
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
              <FormControl fullWidth error={!!errors.rateType}>
                <Select
                  value={formData.rateType}
                  onChange={handleChange('rateType')}
                  displayEmpty
                  renderValue={selected => selected || "Rate Type"}
                  sx={{ height: '48px' }}
                >
                  <MenuItem value="sms">SMS</MenuItem>
                  <MenuItem value="whatsapp">WhatsApp</MenuItem>
                  <MenuItem value="rcs">RCS</MenuItem>
                </Select>
                {errors.rateType?.map((error, index) => (
                  <FormHelperText key={index} error>
                    {error}
                  </FormHelperText>
                ))}
              </FormControl>
  
              <FormControl fullWidth error={!!errors.transactionType}>
                <Select
                  value={formData.transactionType}
                  onChange={handleChange('transactionType')}
                  displayEmpty
                  renderValue={selected => selected || "Transaction Type"}
                  sx={{ height: '48px' }}
                >
                  <MenuItem value="promotional">Promotional</MenuItem>
                  <MenuItem value="transactional">Transactional</MenuItem>
                  <MenuItem value="service-implicit">Service Implicit</MenuItem>
                  <MenuItem value="service-explicit">Service Explicit</MenuItem>
                </Select>
                {errors.transactionType?.map((error, index) => (
                  <FormHelperText key={index} error>
                    {error}
                  </FormHelperText>
                ))}
              </FormControl>
  
              <FormControl fullWidth error={!!errors.transactionValue}>
                <TextField
                  label="Transaction Value *"
                  type="number"
                  value={formData.transactionValue}
                  onChange={handleChange('transactionValue')}
                  required
                  inputProps={{ 
                    step: "0.01",
                    min: "0"
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: '48px'
                    }
                  }}
                />
                {errors.transactionValue?.map((error, index) => (
                  <FormHelperText key={index} error>
                    {error}
                  </FormHelperText>
                ))}
              </FormControl>
            </Box>
  
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end', 
              gap: 2, 
              mt: 4,
              borderTop: '1px solid #E6E6E6',
              pt: 3
            }}>
              <Button
                onClick={onClose}
                variant="outlined"
                disabled={loading}
                sx={{
                  height: '36px',
                  textTransform: 'none',
                  borderColor: '#E6E6E6',
                  color: '#666666'
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={loading}
                sx={{
                  height: '36px',
                  textTransform: 'none',
                  backgroundColor: '#4B87FF',
                  '&:hover': {
                    backgroundColor: '#3D78E6'
                  }
                }}
              >
                {loading ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : (
                  editData ? 'Update' : 'Create'
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

const RateCards: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
    const [editingRateCard, setEditingRateCard] = useState<RateCard | null>(null);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [rateCards, setRateCards] = useState<RateCard[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [userId, setUserId] = useState<number | null>(null);
  
    useEffect(() => {
      const id = getUserId();
      if (!id) {
        handleError('User ID not found');
        return;
      }
      setUserId(parseInt(id));
    }, []);
  
    const handleError = (message: string) => {
      setError(message);
      setTimeout(() => setError(''), 6000);
    };
  
    const handleSuccess = (message: string) => {
      setSuccess(message);
      setTimeout(() => setSuccess(''), 3000);
    };

  const loadRateCards = async () => {
    if (!userId) return;
    
    try {
      setLoading(true);
      const response = await axios.get(`${BASE_URL}/rateCard`, {
        params: { page, size: rowsPerPage },
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      
      if (response.data?.data?.content) {
        setRateCards(response.data.data.content);
      }
    } catch (error) {
      console.error('Error loading rate cards:', error);
      handleError('Failed to load rate cards. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      loadRateCards();
    }
  }, [userId, page, rowsPerPage]);

//   const handleDelete = async (id:number) => {
//     if (window.confirm('Are you sure you want to delete this rate card?')) {
//       try {
//         await axios.delete(`${BASE_URL}/rateCard/${id}`, {
//           headers: {
//             userid: userId,
//             Authorization: `Bearer ${getAuthToken()}`
//           }
//         });
//         handleSuccess('Rate card deleted successfully');
//         loadRateCards();
//       } catch (error) {
//         console.error('Error deleting rate card:', error);
//         handleError('Failed to delete rate card. Please try again.');
//       }
//     }
//   };

  const handleCloseDialog = () => {
    setCreateDialogOpen(false);
    setEditingRateCard(null);
  };

  const handleSubmitSuccess = (message: string) => {
    handleSuccess(message);
    loadRateCards();
  };

  const filteredData = rateCards.filter(card =>
    card.rateType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.transactionType.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const columns = [
    { id: 'rateType', label: 'Rate Type' },
    { id: 'transactionType', label: 'Transaction Type' },
    { id: 'transactionValue', label: 'Transaction Value' },
    { id: 'createdOn', label: 'Created On' },
    { id: 'updatedOn', label: 'Last Modified' }
  ];

  return (
    <Box sx={styles.container}>
      <ErrorSnackbar 
        open={!!error} 
        message={error} 
        onClose={() => setError('')} 
      />
      <SuccessSnackbar 
        open={!!success} 
        message={success} 
        onClose={() => setSuccess('')} 
      />

      <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          Rate Cards
        </Typography>
        <Button
          variant="contained"
          startIcon={<Plus size={16} />}
          onClick={() => setCreateDialogOpen(true)}
          sx={styles.createButton}
        >
          New Rate Card
        </Button>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Box sx={styles.searchContainer}>
          <Typography sx={styles.listTitle}>
            List of Rate Cards
            <span className="total">Total {filteredData.length}</span>
          </Typography>
          <Box sx={styles.searchControls}>
            <TextField
              size="small"
              placeholder="Search rate cards..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={styles.searchInput}
              InputProps={{
                startAdornment: (
                  <Box sx={styles.searchIcon}>
                    <Search size={20} />
                  </Box>
                )
              }}
            />
          </Box>
        </Box>

        <Table>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              {columns.map(column => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((rateCard) => (
                <TableRow key={rateCard.id} hover>
                  <TableCell>{rateCard.rateType}</TableCell>
                  <TableCell>{rateCard.transactionType}</TableCell>
                  <TableCell>{rateCard.transactionValue}</TableCell>
                  <TableCell>{rateCard.createdOn}</TableCell>
                  <TableCell>{rateCard.updatedOn}</TableCell>
                  <TableCell>
                    <IconButton 
                      sx={styles.actionButton}
                      onClick={() => {
                        setEditingRateCard(rateCard);
                        setCreateDialogOpen(true);
                      }}
                    >
                      <Edit size={16} />
                    </IconButton>
                    {/* <IconButton 
                      sx={styles.actionButton}
                      onClick={() => handleDelete(rateCard.id)}
                    >
                      <Trash2 size={16} />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Box sx={styles.paginationContainer}>
          <Box sx={styles.rowsPerPage}>
            <Typography>Show</Typography>
            <Select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              size="small"
              sx={{
                height: '32px',
                minWidth: '80px',
                marginLeft: '8px',
                marginRight: '8px'
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography>entries</Typography>
          </Box>

          <Box sx={{ color: '#666666', fontSize: '12px' }}>
            {`${page * rowsPerPage + 1}–${Math.min(
              (page + 1) * rowsPerPage,
              filteredData.length
            )} of ${filteredData.length}`}
          </Box>

          <Box sx={styles.pagination}>
            <Button
              onClick={() => setPage(prev => Math.max(0, prev - 1))}
              disabled={page === 0}
              sx={styles.paginationButton(false)}
            >
              Previous
            </Button>
            {Array.from(
              { length: Math.ceil(filteredData.length / rowsPerPage) },
              (_, i) => (
                <Button
                  key={i}
                  onClick={() => setPage(i)}
                  sx={styles.paginationButton(page === i)}
                >
                  {i + 1}
                </Button>
              )
            )}
            <Button
              onClick={() => setPage(prev => 
                Math.min(Math.ceil(filteredData.length / rowsPerPage) - 1, prev + 1)
              )}
              disabled={page >= Math.ceil(filteredData.length / rowsPerPage) - 1}
              sx={styles.paginationButton(false)}
            >
              Next
            </Button>
          </Box>
        </Box>
      </TableContainer>

      {loading && (
        <Box sx={styles.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}

      <RateCardDialog
        open={createDialogOpen}
        onClose={handleCloseDialog}
        editData={editingRateCard}
        onSubmitSuccess={handleSubmitSuccess}
        userId={userId}
      />
    </Box>
  );
};

// Styles
const styles = {
  container: {
    width: '100%',
    backgroundColor: '#fff',
    padding: '24px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px'
  },
  headerTitle: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#111827'
  },
  createButton: {
    backgroundColor: '#4B87FF',
    color: '#fff',
    textTransform: 'none',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#3D78E6'
    }
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    border: '1px solid #E5E7EB',
    borderRadius: '8px',
    boxShadow: 'none'
  },
  searchContainer: {
    padding: '16px',
    borderBottom: '1px solid #E5E7EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  listTitle: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#111827',
    '& .total': {
      marginLeft: '8px',
      fontSize: '14px',
      color: '#6B7280'
    }
  },
  searchControls: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px'
  },
  searchInput: {
    width: '240px',
    '& .MuiOutlinedInput-root': {
      height: '40px',
      '& fieldset': {
        borderColor: '#E5E7EB'
      },
      '&:hover fieldset': {
        borderColor: '#B0B9C5'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4B87FF'
      }
    }
  },
  searchIcon: {
    color: '#9CA3AF'
  },
  tableHead: {
    backgroundColor: '#F9FAFB',
    '& .MuiTableCell-head': {
      color: '#374151',
      fontWeight: 600,
      fontSize: '14px',
      padding: '12px 16px'
    }
  },
  tableCell: {
    padding: '12px 16px',
    fontSize: '14px',
    color: '#111827'
  },
  actionButton: {
    padding: '8px',
    marginRight: '8px',
    color: '#6B7280',
    '&:hover': {
      backgroundColor: '#F3F4F6'
    }
  },
  paginationContainer: {
    padding: '16px',
    borderTop: '1px solid #E5E7EB',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rowsPerPage: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#6B7280',
    fontSize: '14px'
  },
  pagination: {
    display: 'flex',
    gap: '8px'
  },
  paginationButton: (active:boolean) => ({
    minWidth: 'unset',
    padding: '4px 12px',
    color: active ? '#fff' : '#374151',
    backgroundColor: active ? '#4B87FF' : 'transparent',
    border: active ? 'none' : '1px solid #E5E7EB',
    '&:hover': {
      backgroundColor: active ? '#3D78E6' : '#F9FAFB'
    },
    '&:disabled': {
      color: '#D1D5DB',
      borderColor: '#E5E7EB'
    }
  }),
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300
  }
};

export default RateCards;