import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Formik, getIn } from 'formik';
import OZAutocompletes from "../../../components/admin/common/AutoComplete/OZAutocompleteGroups";
import { Button, Grid, Typography, ListItemButton, Chip,
    TextField, Tooltip, Stack, Box, Autocomplete, IconButton,
    Checkbox,
    ListItem,
    List,
    ListItemIcon,
    Collapse,
    ListItemText,
    FormHelperText,
    FormControl} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OZInput from '../../../components/admin/common/OZInput';
import OZDrawer from '../../../components/admin/common/OZDrawer/OZDrawer';
import OZSelect from '../../../components/admin/common/OZSelect';
import { ADMIN_FORM_MODES } from '../../../utils/constants';
import { addNewAgent, deleteAgentsById, getgroups, getsipLocation } from '../../../services/api-service';
import OZFCheckbox from '../../../components/admin/common/OZFCheckbox';
import Divider from '@mui/material/Divider';
import OZDialog from "../../../components/admin/common/OZDialog";
import Menu from "@mui/material/Menu";
import SimpleSwitch from '../../../components/admin/common/OZSwitch/SimpleSwitch';
import DeleteDialog from '../../../components/admin/common/CustomDeleteDialog';
import { showErrorNotification, showSuccessNotification } from '../../../components/admin/common/NotiService';
import * as yup from "yup";
import './agent.css'
import { checkRoles } from '../../../utils/util';
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from '../../../services/page-service';
import { AgentSkillList } from './AgentSkillList';
import { ScrollToFieldError } from '../../../components/admin/common/ScrollToFieldError';
import CustomizedCheckbox from '../../../components/admin/common/CustomCheckBox';
import { ReactComponent as NextIcon } from '../../../static/icons/forward.svg';
import { DropDownIcon } from '../BulkUpdateSettings/AgentSelector';

function generatePassWord() {
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const specialChars = "!@#$%^&*()_+-=[]{}|;':,.<>?";
    let password = "";
    password += lowercaseChars[Math.floor(Math.random() * lowercaseChars.length)];
    password += uppercaseChars[Math.floor(Math.random() * uppercaseChars.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    let charList = lowercaseChars + uppercaseChars + numbers + specialChars;
    while (password.length < 8) {
        const index = Math.floor(Math.random() * charList.length);
        const char = charList[index];
        charList = charList.slice(0, index) + charList.slice(index + 1);
        password += char;
    }
    password = password
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("");
    return password;
}

const getSelectedCallControls = (callControls) => {
    let selectedCallControls = [];
    for (const key in callControls) {
        if (callControls[key] === true) {
            selectedCallControls.push(key);
        } else if (typeof callControls[key] === 'object') {
            if (key === 'conference' && Object.values(callControls[key]).includes(true)) {
                selectedCallControls.push(key);
            } else if (key === 'transfer') {
                for (const subKey in callControls[key]) {
                    if (typeof callControls[key][subKey] === 'object' && Object.values(callControls[key][subKey]).includes(true)) {
                        selectedCallControls.push(key);
                        break;
                    }
                }
            }
        }
    }
    return selectedCallControls;
}

const AgentForm = (parentAgentFormProps) => {

    let currentAgentFormMode = parentAgentFormProps.data ? ADMIN_FORM_MODES.view : ADMIN_FORM_MODES.add;

    useEffect(() => {
        localStorage.setItem("agentEncryptedPassword", parentAgentFormProps?.data?.password)
    }, [parentAgentFormProps?.data?.password]);

    useEffect(() => {
        getSipLocationsList();
    }, []);

    const [agentFormMode, setAgentFormMode] = useState(currentAgentFormMode);
    const [openAgentForm, setOpenAgentForm] = useState(true)
    const [sipLocationList, setSipLocationList] = useState([]);
    const [editAgentBaiscInfoAnchorE1, setEditAgentBaiscInfoAnchorE1] = useState(false);
    const anchorRef = useRef();
    const [openDelete, setDeleteDialog] = useState(false)
    let newPassword = useMemo(
        () => (
            generatePassWord()
        ),
        []
    );
    const validationSchema = useMemo(() => (yup.object({
        agentId: yup
            .string('Enter Agent ID')
            .min(2, "The Agent ID must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -")
            .max(50, "The Agent ID must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -")
            .matches(/([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "The Agent ID must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -")
            .required('Agent ID is required'),
        agentName: yup
            .string("Enter Agent Name")
            .min(2, "The Agent name must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -.")
            .max(50, "The Agent name must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -.")
            .matches(/([A-Za-z\d]((\.|@|_|-| )?[A-Za-z\d])+)$/, "The Agent name must be between 2 and 50 alphanumeric characters and allow special characters such as., @, _, -.")
            .required('Agent Name is required'),
        // password: checkRoles("ROLE_APWD_ENCRYPT") ? agentFormMode === "Create" && yup
        //     .string("Enter Password")
        //     .required('Password is required').min(7, "Minimun 7 characters required")
        //     .matches(
        //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/,
        //         "Must Contain 7 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        //     ) : yup
        //         .string("Enter Password")
        //         .required("Password is required")
        //         .min(7, "Password must have atleast 7 characters"),
        // password: checkRoles("ROLE_APWD_ENCRYPT") ?
        //     agentFormMode === "Create" &&
        //     yup
        //         .string("Enter Password")
        //         .required('Password is required')
        //         .min(7, "Password should be between 7 to 50 characters.")
        //         .max(50, "Password should be between 7 to 50 characters.")
        //         .matches(
        //             /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/,
        //             "The password must not contain spaces. The password must be 7 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters."
        //         )
        //     :
        //     yup
        //         .string("Enter Password")
        //         .required("Password is required")
        //         .min(3, "Password should be between 3 to 50 characters.")
        //         .max(50, "Password should be between 3 to 50 characters."),
        email: yup
            .string()
            .when([], {
                is: () => (!checkRoles('ROLE_AGENT_CREATION_EMAIL_ALERT')),
                then: yup
                    .string()
                    .min(5, "Email should be between 5 to 50 characters.")
                    .max(50, "Email should be between 5 to 50 characters.")
                    .email("Invalid Email Address format"),
                otherwise: yup
                    .string()
                    .min(5, "Email should be between 5 to 50 characters.")
                    .max(50, "Email should be between 5 to 50 characters.")
                    .email("Invalid Email Address format")
                    .required("Email Address is required"),
            }),
        // blendedControls: yup
        //     .array()
        //     .when([], {
        //         is: () => (checkRoles('ROLE_CHAT_CALL') && agentFormMode === "Create"),
        //         then: yup
        //             .array()
        //             .required("Agent mode is required")
        //             .min(1, "Select atleast 1 agent mode"),
        //         otherwise: yup
        //             .array()
        //             .nullable()
        //             .notRequired(),
        //     }),

        blendedControls: yup
            .array()
            .when([], {
                is: () => (checkRoles('ROLE_CHAT_CALL') && (agentFormMode === "Create" || agentFormMode === "blendedControls")),
                then: yup
                    .array()
                    .test(
                        "validate-blended-controls",
                        "Select atleast 1 agent mode / Select at least 2 sub-modes if selecting Blended",
                        (value) => {
                            if (!value || value.length === 0) return false;
                            const blendedKeys = value.filter((item) =>
                                item.key.includes("blended")
                            );
                            if (blendedKeys.length > 0) {
                                return blendedKeys.length >= 2;
                            }
                            return value.length >= 1;
                        }
                    ),
                otherwise: yup.array().nullable().notRequired(),
            }),
        agentModes: yup
            .array()
            .when([], {
                is: () => (!checkRoles('ROLE_CHAT_CALL') && agentFormMode === "Create"),
                then: yup
                    .array()
                    .of(yup.string()).required("Agent mode is required").min(1, "Select atleast 1 agent mode"),
                otherwise: yup
                    .array()
                    .notRequired(),
            }),
        // agentModes: agentFormMode === "Create" && yup.array().of(yup.string()).required("Agent mode is required").min(1, "Select atleast 1 agent mode"),
        autoAnswer: yup.string("Enter Auto answer").required("Auto answer is required"),
        skills: yup
            .array()
            .of(yup.object().shape({
                priority: yup
                    .number()
                    .required("Priority is required.")
                    .min(1, 'Priority should be between 1 and 999')
                    .typeError('The value must be a number')
                    .integer('The value must be a number')
                    .max(999, 'Priority should be between 1 and 999'),
                /* skillList: yup.array().of(yup.object().shape({
                    id: yup.number().required("Skills is required"),
                    skillName: yup.string().required("skills is required"),
                })).required("Skills is required").min(1, "Skills field must have at least 1 item."), */
            }))
            .required("Skills is required")
            .min(1, "Skills field must have at least 1 item."),
        agentData: yup
            .string()
            .min(2, "Agent data must be between 2 and 100 alphanumeric characters and allow special characters such as., @, _, -.")
            .max(100, "Agent data must be between 2 and 100 alphanumeric characters and allow special characters such as., @, _, -.")
            .matches(/^[A-Za-z\d]((\.|@|_|-)?[A-Za-z\d])*$/, "Agent data must be between 2 and 100 alphanumeric characters and allow special characters such as., @, _, -.")
            .nullable(),
    })), [agentFormMode]);

    async function getSipLocationsList() {
        try {
            const resp = await getsipLocation();
            if (resp.Status === "Success") {
                if (resp.Data.length > 0) {
                    setSipLocationList(resp.Data.reduce((acc, item) => {
                        acc.push({ id: item.id, location: item.location });
                        return acc;
                    }, []));
                }
            } else {
                console.log("Unable to get sip location list", resp);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function getAgentModes() {
        let agentModesList = ['Inbound Call', 'Manual Dial']
        if (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_OUTBOUND")) {
            agentModesList.push("Preview  Dial");
            agentModesList.push("Progressive Dial");
        }
        if (checkRoles("ROLE_ADMIN") || checkRoles("ROLE_BLENDED")) {
            agentModesList.push("Blended");
        }
        if ((checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CHAT"))) {
            agentModesList.push("Digital");
        }
        return agentModesList;
    }

    const onBtnDelete = () => {
        deleteAgentById(parentAgentFormProps.data.id)
    }

    function openDeleteDialog() {
        setEditAgentBaiscInfoAnchorE1(false);
        setDeleteDialog(true)
    }

    const morevertClick = () => {
        setTimeout(() => setEditAgentBaiscInfoAnchorE1(anchorRef?.current), 1);
    };

    const handleMenuClose = () => {
        setEditAgentBaiscInfoAnchorE1(false);
    };

    function openBasicInfoEditDialog() {
        setAgentFormMode('BasicInfo')
        setEditAgentBaiscInfoAnchorE1(false);
    }

    const closeAgentForm = () => {
        localStorage.removeItem("agentEncryptedPassword");
        parentAgentFormProps.onClose();
        setOpenAgentForm(false);
    }

    const AgentViewDrawer = (agentViewProps) => { //view
        let styleFieldHeader = useMemo(
            () => ({
                fontSize: "14px",
                fontWeigth: 400,
                color: '#212121',
            }),
            []
        );

        let editButtonStyle = useMemo(
            () => ({
                fontWeight: 400,
                fontSize: '14px',
                color: '#3D8BF8',
                width: "32px",
                minWidth: "29px"
            }),
            []
        );

        let chipStyle = useMemo(
            () => ({
                height: "24px",
                "& .MuiChip-label": {
                    color: '#536580',
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "16px",
                },
                flexShrink: 0
            }),
            []
        );

        const [locked, setLocked] = useState(agentViewProps.data.locked);
        const [status, setStatus] = useState(!agentViewProps.data.active);
        // const [agentList, setAgentList] = useState([]);
        const [openDelete, setOpenDelete] = useState(false);
        const [openLockedDialog, setOpenLockedDialog] = useState(false);
        let agentModeLabelBlended = ["Inbound Call", "Manual Dial", "Preview", "Progressive Dial", "Digital", 'Blended(Inbound)', 'Blended(Chat)', 'Blended(Manual)', 'Blended(Preview)', 'Blended(Progressive)']
        let agentModeLabel = ["Inbound Call", "Manual Dial", "Preview", "Progressive Dial", "Blended", "Digital"]
        let callControls = agentViewProps.data?.callControls || {
            hold: true,
            mute: true,
            endCall: true,
            answer: true,
            decline: true,
            manualDial: true,
            callRecording: true,
            transfer: {
                agent: { warm: true, blind: true },
                skill: { warm: true, blind: true },
                phone: { warm: true, blind: true },
                ivr: { warm: true, blind: true },
            },
            conference: {
                agent: true,
                phone: true,
            },
        };
        let agentModeName = ["inbound", "manual", "preview", "progressive", "blended", "chat"]
        let agentModeNameBlended = ["inbound", "manual", "preview", "progressive", "chat", 'blendedInbound', 'blendedChat', 'blendedManual', 'blendedPreview', 'blendedProgressive'];
        let dontClose = true;

        // const agentList = agentViewProps.getAgentFunction()

        useEffect(() => {
            // const response = agentViewProps.getAgentFunction()
            // setAgentList(response)
            setStatus(!agentViewProps.data.active);
            setLocked(agentViewProps.data.locked);
        }, [agentViewProps.data.active, agentViewProps.data.locked]);


        const handleChangeLocked = (e) => {
            setLocked(e.target.checked)
            setOpenLockedDialog(true)
            agentViewProps.data.locked = e.target.checked;
        };

        function handleCloseLocked() {
            agentViewProps.data.locked = !agentViewProps.data.locked
            setOpenLockedDialog(false)
        }

        const handleChangeStatus = (e) => {
            if (agentViewProps.hideButton()) {
                setStatus(e.target.checked)
                setOpenDelete(true)
                agentViewProps.data.active = !e.target.checked;
            }
            else {
                showErrorNotification("Agent cannot be activated. Limit exceeded")
            }

        };
        const handleActivateChangeStatus = (e) => {
            setStatus(e.target.checked)
            setOpenDelete(true)
            agentViewProps.data.active = !e.target.checked;
        };


        function handleCloseDelete() {
            agentViewProps.data.active = !agentViewProps.data.active
            setOpenDelete(false)
        }

        function handleUpdateInViewMode() {
            let cloneOfAgentFromValues = structuredClone(agentViewProps.data)
            let newSkillsData = cloneOfAgentFromValues.skills;
            let newSkillsList = []
            // eslint-disable-next-line array-callback-return
            newSkillsData?.map((skills) => {
                let priority = skills.priority
                skills.skillList.map((skillValue) => {
                    newSkillsList.push({ "priority": priority, "skillName": skillValue.skillName, "id": skillValue.id })
                    return null
                })
            })
            cloneOfAgentFromValues.skills = newSkillsList
            let mode = "View";
            let index = undefined;
            let length = "-1";
            if (cloneOfAgentFromValues.sipLocation === "") {
                cloneOfAgentFromValues.sipLocation = null
            }
            cloneOfAgentFromValues.callControls = { ...cloneOfAgentFromValues.callControls, ...Object.fromEntries(Object.entries(cloneOfAgentFromValues.callControls.transfer).flatMap(([key, value]) => Object.entries(value).map(([type, val]) => [`transfer_${key}_${type}`, val]))) };
            delete cloneOfAgentFromValues.callControls.transfer;
            cloneOfAgentFromValues.callControls.conference_agent = cloneOfAgentFromValues.callControls.conference.agent;
            cloneOfAgentFromValues.callControls.conference_phone = cloneOfAgentFromValues.callControls.conference.phone;
            delete cloneOfAgentFromValues.callControls.conference;
            cloneOfAgentFromValues.callControls.endcall = cloneOfAgentFromValues.callControls.endCall;
            delete cloneOfAgentFromValues.callControls.endCall;
            cloneOfAgentFromValues.callControls.callrecording = cloneOfAgentFromValues.callControls.callRecording;
            delete cloneOfAgentFromValues.callControls.callRecording;
            cloneOfAgentFromValues.callControls.manualdial = cloneOfAgentFromValues.callControls.manualDial;
            delete cloneOfAgentFromValues.callControls.manualDial;
            cloneOfAgentFromValues.callControls = JSON.stringify(cloneOfAgentFromValues.callControls);
            agentViewProps.updateFunction(cloneOfAgentFromValues, dontClose, index, length, agentViewProps.editmode, mode)
        }


        function SwitchRenderer(switchProps) {
            return (
                <div className={switchProps.mainDivClassName}>
                    <Typography sx={styleFieldHeader}>
                        {switchProps.name}
                    </Typography>
                    <div className={switchProps.switchDivClassName}>
                        {/* <SimpleSwitch checked={switchProps.value} onChange={(e) => { authPage(AdminPages.AGENTS_EDIT) && switchProps.handleChange(e) }} /> */}
                        <SimpleSwitch checked={switchProps.value} onChange={(e) => { (newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && switchProps.handleChange(e) }} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                {openDelete && <DeleteDialog mode="Add" title={"Update Agent Status"} content={`Do you want to change the status of Agent: ${parentAgentFormProps.data.agentName}?`} onClose={() => { handleCloseDelete() }
                } open={openDelete} deleteFunction={() => { handleUpdateInViewMode(); setOpenDelete(false) }} />
                }
                {openLockedDialog && <DeleteDialog mode="Add" title={"Update Locked"} content={`Do you want to change the lock of Agent: ${parentAgentFormProps.data.agentName}?`} onClose={() => { handleCloseLocked() }
                } open={openLockedDialog} deleteFunction={() => { handleUpdateInViewMode(); setOpenLockedDialog(false) }} />
                }
                <div className='oz-agent-form-view-maindiv'>
                    <Typography sx={styleFieldHeader}>
                        Current Status :
                    </Typography>
                    <Typography sx={{ ...styleFieldHeader, fontWeight: 500, marginLeft: '5px' }}>
                        {!status ? "Active" : "InActive"}</Typography>
                </div>

                <SwitchRenderer name="Locked" value={locked} handleChange={handleChangeLocked} mainDivClassName="oz-agent-form-view-maindiv" switchDivClassName="oz-agent-view-switch-container" />
                <SwitchRenderer name="Deactivate" value={status} handleChange={agentViewProps?.data?.active ? handleActivateChangeStatus : handleChangeStatus} mainDivClassName="oz-agent-form-view-maindiv oz-agent-form-view-maindiv-deactivate" switchDivClassName="oz-agent-view-switch-deactivate-container" />

                {checkRoles('ROLE_CHAT_CALL') ? 
                <>
                    <Grid marginTop='10px' marginLeft={'-10px'} marginRight={'-10px'}>  <Divider /></Grid>
                    <div className='oz-agent-form-view-maindiv oz-agent-form-view-maindiv-edit-modes'>
                        <div className='oz-agent-form-view-agent-mode-edit-button-container'>
                            <Typography sx={{ ...styleFieldHeader, marginBottom: "10px" }} flexGrow={1} alignSelf='center'>Agent Modes *</Typography>
                            {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <Button
                                size='small'
                                variant="text"
                                onClick={() => { agentViewProps?.editmode('blendedControls') }}
                                sx={{ ...editButtonStyle }}
                            >
                                Edit
                            </Button>}
                        </div>
                        <div className='oz-agent-form-view-agent-modes-container'>
                            {agentModeNameBlended?.map((modeName, index) => {
                                return <div key={index}>{agentViewProps.data[modeName] === true && <Chip sx={chipStyle} size='small' label={agentModeLabelBlended[index]} />}</div>
                            })}
                        </div>
                    </div>
                    <Grid marginTop='10px' marginLeft={'-10px'} marginRight={'-10px'}>  <Divider /></Grid>
                </>
                :
                <>
                    <Grid marginTop='10px' marginLeft={'-10px'} marginRight={'-10px'}>  <Divider /></Grid>
                    <div className='oz-agent-form-view-maindiv oz-agent-form-view-maindiv-edit-modes'>
                        <div className='oz-agent-form-view-agent-mode-edit-button-container'>
                            <Typography sx={{ ...styleFieldHeader, marginBottom: "10px" }} flexGrow={1} alignSelf='center'>Agent Modes *</Typography>
                            {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <Button
                                size='small'
                                variant="text"
                                onClick={() => { agentViewProps?.editmode('AgentMode') }}
                                sx={{ ...editButtonStyle }}
                            >
                                Edit
                            </Button>}
                        </div>
                        <div className='oz-agent-form-view-agent-modes-container'>
                            {agentModeName?.map((modeName, index) => {
                                return <div key={index}>{agentViewProps.data[modeName] === true && <Chip sx={chipStyle} size='small' label={agentModeLabel[index]} />}</div>
                            })}
                        </div>
                    </div>
                    <Grid marginTop='10px' marginLeft={'-10px'} marginRight={'-10px'}>  <Divider /></Grid>
                </>
                }
                <div className='oz-agent-form-view-maindiv oz-agent-form-view-maindiv-edit-modes'>
                    <div className='oz-agent-form-view-agent-mode-edit-button-container'>
                        <Typography sx={{ ...styleFieldHeader, marginBottom: "10px" }} flexGrow={1} alignSelf='center'>Call Controller Settings *</Typography>
                        {/* {authPage(AdminPages.AGENTS_EDIT) && <Button */}
                        {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <Button
                            size='small'
                            variant="text"
                            onClick={() => { agentViewProps?.editmode('callControls') }}
                            sx={{ ...editButtonStyle }}
                        >
                            Edit
                        </Button>}
                    </div>
                    <div className='oz-agent-form-view-call-control-container'>
                        <Grid container spacing={1} columnGap={1.5}>
                            {getSelectedCallControls(callControls).map((controlName, index) => {
                                let label = controlName[0].toUpperCase() + controlName.slice(1);
                                label = label === "EndCall" ? "End Call" : label === "CallRecording" ? "Call Recording" : label === "ManualDial" ? "Manual Dial" : label;
                                return <Grid item key={index} display="flex" >
                                    <Chip sx={chipStyle} size='small' label={label} />
                                </Grid>
                            })}
                        </Grid>
                    </div>
                </div>

                <Grid marginTop='1rem' marginLeft={'-10px'} marginRight={'-10px'}>  <Divider /></Grid>
                {agentViewProps?.data?.skills?.length > 0 &&
                    <div className="oz-agent-form-view-maindiv oz-agent-form-view-maindiv-edit-modes oz-agent-form-view-maindiv-page-not-edit" >
                        <div className='oz-agent-form-view-edit-container'>
                            <Typography sx={styleFieldHeader} alignSelf='center'>Skills</Typography>
                            <Typography sx={{
                                fontSize: '12px',
                                color: "#99A0A8",
                                alignSelf: 'center',
                                marginLeft: '4px'
                            }} flexGrow={1}>(Choose specific levels for different skills)</Typography>
                            {/* {authPage(AdminPages.AGENTS_EDIT) && <Button */}
                            {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <Button
                                sx={editButtonStyle}
                                onClick={() => { agentViewProps?.editmode('AgentSkill') }}
                            >
                                Edit
                            </Button>}
                        </div>
                        <div className='oz-agent-form-view-skill-list-container'>
                            <Typography sx={{
                                ...styleFieldHeader, width: "58px", display: 'flex', justifyContent: 'center'
                            }}>
                                Priority
                            </Typography>
                            <Divider orientation='vertical' />
                            <Typography sx={{
                                ...styleFieldHeader, marginLeft: '18px'
                            }}>
                                Agent’s skills
                            </Typography>
                        </div>
                        {agentViewProps?.data?.skills?.map((skillData, mainIndex) => {
                            return (
                                < div key={mainIndex}
                                    className={"oz-agent-form-view-skill-list-inner-container " + (agentViewProps?.data?.skills?.length - 1 === mainIndex ? 'oz-agent-form-view-skill-list-inner-true-border-container' : 'oz-agent-form-view-skill-list-inner-false-border-container')}>
                                    <Typography sx={{
                                        ...styleFieldHeader, minWidth: '58px', display: 'flex', justifyContent: 'center'
                                    }}>{skillData.priority}
                                    </Typography>
                                    <Divider orientation='vertical' />
                                    <Typography sx={{ ...styleFieldHeader, marginLeft: '18px', }}>
                                        {skillData?.skillList?.map((value, index) =>
                                            (index < 2 && (value?.skillName + (((skillData?.skillList?.length - 1) > index) ? ", " : ""))))
                                        }
                                    </Typography>
                                    <Typography sx={{
                                        ...styleFieldHeader,
                                        paddingRight: '5px',
                                        paddingLeft: "5px"
                                    }}>
                                        {(skillData?.skillList?.length > 2) ? "..." : ""}
                                    </Typography>

                                    <Typography sx={{
                                        ...styleFieldHeader,
                                        color: "#99A0A8",
                                        paddingRight: '27px',
                                        paddingLeft: "5px"
                                    }}>
                                        {(skillData?.skillList?.length > 2) ? (("+" + (skillData?.skillList?.length - 2))) : ""}
                                    </Typography>
                                </div>
                            )
                        })}
                    </div >
                }
            </div >
        )
    }

    async function addAgent(agentFormValues) {
        try {
            if(checkRoles('ROLE_CHAT_CALL') && agentFormValues.blendedControls){
                const blendedKeys = [
                    "blendedInbound",
                    "blendedManual",
                    "blendedPreview",
                    "blendedChat",
                    "blendedProgressive",
                    "inbound",
                    "manual",
                    "preview",
                    "progressive",
                    "chat",
                ];
        
                blendedKeys.forEach((key) => {
                    agentFormValues[key] = false;
                });

                agentFormValues.blendedControls && agentFormValues.blendedControls.forEach((item) => {
                    if (blendedKeys.includes(item.key)) {
                        agentFormValues[item.key] = true;
                    }
                });

                const hasBlendedKey = blendedKeys
                    .filter((key) => key.startsWith("blended"))
                    .some((key) => agentFormValues[key]);

                if (hasBlendedKey) {
                    agentFormValues.blended = true;
                } else {
                    agentFormValues.blended = false;
                }
            }else{
                for (let i = 0; i < agentFormValues.agentModes.length; i++) {
                    switch (agentFormValues.agentModes[i]) {
                        case "Inbound Call":
                            agentFormValues.inbound = true
                            break;
                        case "Manual Dial":
                            agentFormValues.manual = true
                            break;
                        case "Preview  Dial":
                            agentFormValues.preview = true
                            break;
                        case "Blended":
                            agentFormValues.blended = true
                            break;
                        case "Progressive Dial":
                            agentFormValues.progressive = true
                            break;
                        case "Digital":
                            agentFormValues.chat = true
                            break;
                        default: showErrorNotification(`Agent mode isn't selected`);
                    }
                };
                delete agentFormValues.blendedInbound;
                delete agentFormValues.blendedManual;
                delete agentFormValues.blendedPreview;
                delete agentFormValues.blendedChat;
                delete agentFormValues.blendedProgressive;
            }
            delete agentFormValues.blendedControls;

            addNewAgent(agentFormValues)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        parentAgentFormProps.refreshAgentGrid();
                        closeAgentForm();
                        showSuccessNotification(`${agentFormValues.agentId} is created Successfully`)
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));
                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteAgentById() {
        setEditAgentBaiscInfoAnchorE1(false);
        setDeleteDialog(false)
        try {
            deleteAgentsById(parentAgentFormProps.data.id)
                .then((resp) => {
                    if (resp.Status === "Success") {
                        parentAgentFormProps.refreshAgentGrid();
                        closeAgentForm();
                        setDeleteDialog(false)
                        showSuccessNotification(`${parentAgentFormProps.data.agentId} is Deactivated Successfully`)
                    }
                    else {
                        showErrorNotification(JSON.stringify(resp.Message));
                    }
                })
                .catch((e) => {
                    showErrorNotification(JSON.stringify(e));

                });
        } catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    const styleListItemButton = useMemo(
        () => ({
            "&.MuiButtonBase-root": {
                color: '#212121',
                fontFamily: "Roboto",
                fontSize: "12px",
                fontWeight: 400,
                height: '18px',
                lineHeight: "14px",
            },
            '&:hover': {
                borderRadius: "4px",
            },
        }),
        []
    );

    const DataForm = (agentFormProps) => {

        const [groupList, setGroupList] = useState([]);
        const [editGroupList, setEditGroupList] = useState([]);

        useEffect(() => {
            getGroupsList();
        }, [])

        async function getGroupsList() {
            try {
                getgroups()
                    .then((resp) => {
                        const updatedData = resp.Data.map(item => ({
                            ...item,
                            id: item.SeqID
                        }));
                        const EditGroupData = resp.Data.map(item => ({
                            ...item,
                            id: item.SeqID,
                            name: item.GroupName,
                        }));
                        setEditGroupList(EditGroupData);
                        setGroupList(updatedData);
                    })
                    .catch((e) => {
                        setGroupList([]);
                    });
            } catch (e) {
                showErrorNotification("Error in groups list");
            }
        };


        let cancelButtonStyle = useMemo(
            () => ({
                height: '44px',
                width: "91px",
                borderRadius: '0.5rem',
                fontSize: "0.875rem",
                fontWeight: 400,
                color: '#3D8BF8',
                border: '0.0625rem solid #008DFF',
                marginRight: '0.9375rem'
            }),
            []
        );

        let initialAgentFormData = agentFormProps.data || {
            agentId: '',
            agentName: '',
            password: checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? newPassword : "",
            email: '',
            autoAnswer: "None",
            agentModes: [],
            skills: [{ priority: 1, skillList: [] }],
            agentData: null,
            oncall: false,
            sipLocation: "",
            callControls: {
                hold: true,
                mute: true,
                endCall: true,
                answer: true,
                decline: true,
                callRecording: true,
                manualDial: true,
                transfer: {
                    agent: { warm: true, blind: true },
                    skill: { warm: true, blind: true },
                    phone: { warm: true, blind: true },
                    ivr: { warm: true, blind: true },
                },
                conference: {
                    agent: true,
                    phone: true,
                },
            },
            screenRecording: false,
            priority: 1,
            progressive: false,
            chat: false,
            blended: false,
            preview: false,
            manual: false,
            inbound: false,
            blendedProgressive: false,
            blendedChat: false,
            blendedPreview: false,
            blendedManual: false,
            blendedInbound: false,
            agentGroups: [],
            blendedControls: []
        };

        initialAgentFormData.password = agentFormProps.data ? agentFormProps.data.password : newPassword;

        if (agentFormProps.data) {
            switch (agentFormProps.data.autoAnswer) {
                case null:
                    initialAgentFormData.autoAnswer = "None";
                    break;
                case 0:
                    initialAgentFormData.autoAnswer = "None";
                    break;
                case 1:
                    initialAgentFormData.autoAnswer = "All";
                    break;
                case 2:
                    initialAgentFormData.autoAnswer = "Inbound";
                    break;
                case 3:
                    initialAgentFormData.autoAnswer = "Outbound";
                    break;
                default: break;
            }
        }

        function AgentFormDialog(props) {
            const agentFormDialogueProps = props?.props;
            let helperText = "";

            function checkValidation() {


                if (checkRoles("ROLE_APWD_ENCRYPT")) {
                    if (agentFormDialogueProps.values.password === localStorage.getItem("agentEncryptedPassword") && agentFormDialogueProps.values.password?.length == 40) {

                        helperText = ""
                        return false;
                    }
                    else {
                        if (agentFormDialogueProps.values.password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{7,})/) !== null) {
                            helperText = ""
                            return false;
                        }
                        else {
                            if (agentFormDialogueProps.values.password.length === 0 || agentFormDialogueProps.values.password === null || agentFormDialogueProps.values.password === "") {
                                helperText = "Password is required."
                                return true;
                            }
                            else {
                                helperText = "The password must not contain spaces.The password must be 7 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters."
                                return true;
                            }
                        }
                    }
                }
                else {
                    if (agentFormDialogueProps.values.password.length === 0 || agentFormDialogueProps.values.password === null || agentFormDialogueProps.values.password === "") {
                        helperText = "Password is required."
                        return true;
                    } else {
                        if (agentFormDialogueProps.values.password.length < 3 || agentFormDialogueProps.values.password.length > 51) {
                            helperText = "Password should be between 3 to 50 characters.";
                            return true;
                        } else {
                            helperText = "";
                            return false;
                        }
                    }
                }
            }

            const error = checkValidation();

            return (
                <form onSubmit={(e) => {
                    e.preventDefault()
                    if (!error) {
                        agentFormDialogueProps.handleSubmit(e);
                    } else {
                        showErrorNotification(helperText);
                        return;
                    }
                }}
                    id='agentForm'
                    className='ozcwScrollbar'
                >
                    <Grid container={true} spacing={2} display='flex'>
                        {(agentFormMode === 'Create' || agentFormMode === 'BasicInfo') && (
                            <div className={agentFormMode === 'Create' ? 'oz-agent-form-add' : 'oz-agent-form-edit'}                                    >

                                {agentFormMode === 'Create' &&
                                    <div>
                                        <Typography sx={{
                                            fontSize: '12px',
                                            fontWeight: 500,
                                            heigth: '41px',
                                            maxHeight: '50px',
                                            lineHeight: '41px',
                                            marginLeft: '20px'
                                        }}>Role: Agent</Typography>
                                        <Divider />
                                    </div>}

                                <Grid container={true} sx={{
                                    paddingTop: agentFormMode === 'Create' ? '20px' : '14px',
                                    paddingLeft: agentFormMode === 'Create' ? '20px' : '15px',
                                }} spacing={3}>

                                    <Grid item xs={agentFormMode === 'Create' ? 4.5 : 12}>
                                        <OZInput
                                            name="agentId"
                                            label="Agent ID *"
                                            placeholder="Agent 1"
                                        />
                                    </Grid>

                                    <Grid item xs={agentFormMode === 'Create' ? 4.5 : 12}>
                                        <OZInput
                                            name="agentName"
                                            label="Agent Name *"
                                            placeholder="Agent Name"
                                        />
                                    </Grid>

                                    <Grid item xs={agentFormMode === 'Create' ? 4.5 : 12}>
                                        <OZInput
                                            name="email"
                                            label={checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? "Email *" : "Email"}
                                            // type="email"
                                            placeholder="Email Address"
                                        />
                                    </Grid>

                                    <Grid item xs={agentFormMode === 'Create' ? 4.5 : 12}>
                                        <Typography
                                            fontSize='12px'
                                            fontWeight={400}
                                            lineHeight='14px'
                                            marginBottom={'6px'}
                                            marginLeft='1px'
                                            color={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") : false ? '#99A0A8' : '#212121'}
                                        >
                                            {agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? "Generated new Password *" : "Password *" : "Password *"}
                                        </Typography>
                                        <TextField
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '8px',
                                                    backgroundColor: '#ffff',
                                                    fontSize: '14px',
                                                    height: '44px',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    border: '1px solid #D6D6D7'
                                                },
                                                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                                    // backgroundColor: '#D6D6D7', //TODO
                                                    border: '1px solid #D6D6D7'
                                                }
                                            }}
                                            fullWidth
                                            disabled={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") : false}
                                            type={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? 'string' : "password" : "password"}
                                            onChange={(e) => {
                                                agentFormDialogueProps.handleChange(e)
                                            }
                                            }
                                            onBlur={agentFormDialogueProps.handleBlur}
                                            error={error}
                                            value={agentFormDialogueProps.values.password}
                                            helperText={helperText}
                                            name={"password"}
                                            id={"password"}
                                            placeholder={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? "Generated new Password" : "Password" : "Password"}

                                        />
                                        {/* <TextField
                                            name=""
                                        // label={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? "Generated new Password" : "Password" : "Password"}
                                        // type={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") ? 'string' : "password" : "password"}
                                        // disabled={agentFormMode === 'Create' ? checkRoles("ROLE_AGENT_CREATION_EMAIL_ALERT") : false}
                                        // storeEncryptPassword
                                        // onChange={(e) => {
                                        //     agentFormFormikProps.handleChange(e)
                                        //     localStorage.setItem("agentEncryptedPassword", e.target.value)
                                        // }
                                        // }


                                        /> */}
                                    </Grid>

                                    {checkRoles('ROLE_CHAT_CALL') ? 
                                        <>
                                            {agentFormMode === 'Create' && //Add
                                                <Grid item xs={4.5}>
                                                    <NewAgentMode formik={agentFormDialogueProps} />
                                                </Grid>
                                            }
                                        </>
                                        :
                                        <>
                                            {agentFormMode === 'Create' &&
                                                <Grid item xs={4.5}>
                                                    <OZSelect
                                                        name="agentModes"
                                                        multiSelect
                                                        label="Please Select Agent Modes *"
                                                        placeholder='Select Agent Modes'
                                                        showCheckbox={true}
                                                        showChip={true}
                                                        options={getAgentModes()}
                                                    />
                                                </Grid>
                                            }
                                        </>
                                    }
                                    {console.log("here value error", agentFormDialogueProps)}

                                    {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_SIP")) &&
                                        < Grid item xs={4.5}>
                                            <OZSelect
                                                name="autoAnswer"
                                                label="Auto answer SIP calls"
                                                placeholder='None'
                                                options={['None', 'All', 'Inbound', 'Outbound']}
                                            />
                                        </Grid>
                                    }

                                    <Grid item xs={4.5} >
                                        <OZInput
                                            name="agentData"
                                            label="Agent Data"
                                            type="string"
                                            placeholder="Agent Data"
                                        />
                                    </Grid>
                                    <Grid item xs={agentFormMode === "Create" ? 4.5 : 15} >
                                        {agentFormMode === "Create" &&
                                            <Stack>
                                                <label style={{ fontSize: '12px' }}>
                                                    Add Groups
                                                </label>
                                                <OZAutocompletes
                                                    name="agentGroups"
                                                    PlaceHolder={"please select Groups"}
                                                    Datas={groupList}
                                                    optionLabelName="GroupName"
                                                />
                                            </Stack>}
                                        {agentFormMode === "BasicInfo" &&
                                            <Stack>
                                                <label style={{ fontSize: '12px', marginBottom: '6px' }}>
                                                    Add Groups
                                                </label>
                                                <div style={{ marginTop: "30px" }}>
                                                    <OZAutocompletes
                                                        name="agentGroups"
                                                        PlaceHolder={"please select Groups"}
                                                        Datas={editGroupList}
                                                        optionLabelName="name"
                                                        mode="Edit"
                                                    />
                                                </div>
                                            </Stack>
                                        }
                                    </Grid>

                                    <Grid item xs={11}>
                                        <div className='oz-agent-form-add-checkbox'>
                                            {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_AGENT_ONCALL")) && < OZFCheckbox labelPosition="end" name="oncall" label="On Call" />}
                                            {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_SCREEN_RECORDING")) && <OZFCheckbox labelPosition="end" name="screenRecording" label="Screen Recording" />}
                                        </div>
                                    </Grid>

                                    {<Grid item xs={agentFormMode === "Create" ? 4.5 : 15}>
                                        <OZSelect
                                            name="sipLocation"
                                            label="SIP Locations"
                                            placeholder='Select'
                                            options={sipLocationList || []}
                                            optionLabel={"location"}
                                        />
                                    </Grid>
                                    }

                                    {agentFormMode === 'Create' &&
                                        <>
                                            <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: '#000000',
                                                }}>Call Controller Settings</Typography>

                                                <Grid item ml={.2} display={'flex'} gap={'24px'}>
                                                    {Object.keys(agentFormDialogueProps.values.callControls).map((key, index) => {
                                                        let label = key[0].toUpperCase() + key.slice(1);
                                                        label = label === "EndCall" ? "End Call" : label === "CallRecording" ? "Call Recording" : label === "ManualDial" ? "Manual Dial" : label;
                                                        return !(key === "transfer" || key === "conference") &&
                                                            <OZFCheckbox
                                                                name={`callControls.${key}`}
                                                                label={label}
                                                                labelPosition="end"
                                                            />
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'8px'}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: '#000000',
                                                }}>Conference Settings</Typography>
                                                <Grid item ml={.2} display="flex" gap="18px">
                                                    {Object.keys(agentFormDialogueProps.values.callControls.conference).map((key, index) => {
                                                        return <OZFCheckbox
                                                            name={`callControls.conference.${key}`}
                                                            label={key[0].toUpperCase() + key.slice(1)}
                                                            labelPosition="end"
                                                        />
                                                    })}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} display={'flex'} flexDirection={'column'} gap={'16px'}>
                                                <Typography sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    color: '#000000',
                                                }}>Transfer Settings</Typography>
                                                <Grid item display={'flex'} flexDirection={'row'} gap={'80px'}>
                                                    {Object.keys(agentFormDialogueProps.values.callControls.transfer).map((key, index) => {
                                                        return <Grid display={'flex'} flexDirection={'column'}>
                                                            <Typography sx={{
                                                                fontSize: '14px',
                                                                fontWeight: 400,
                                                                color: '#000000',
                                                            }}>{key[0].toUpperCase() + key.slice(1)} Transfer</Typography>
                                                            {Object.keys(agentFormDialogueProps.values.callControls.transfer[key]).map((subKey, subIndex) => {
                                                                return <div style={{ marginTop: subIndex === 1 ? '-12px' : '0px', marginLeft: "2px" }}>
                                                                    <OZFCheckbox
                                                                        name={`callControls.transfer.${key}.${subKey}`}
                                                                        label={subKey[0].toUpperCase() + subKey.slice(1)}
                                                                        labelPosition="end"
                                                                    />
                                                                </div>
                                                            })}
                                                        </Grid>
                                                    })}
                                                </Grid>
                                            </Grid>
                                        </>
                                    }

                                    {agentFormMode === 'Create' &&
                                        <Grid item xs={12} sx={{ display: 'flex', gap: 1, marginTop: !(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_SCREEN_RECORDING")) ? "-25px" : "" }}>
                                            <Typography sx={{
                                                fontSize: '18px',
                                                fontWeight: 400,
                                            }}>Skills</Typography>

                                            <Typography sx={{
                                                fontSize: '12px',
                                                color: "#99A0A8",
                                                alignSelf: 'center',
                                            }} >(Choose specific levels for different Skills)</Typography>
                                        </Grid>
                                    }
                                    {agentFormMode === 'Create' &&
                                        <Grid item xs={10}>
                                            < AgentSkillList mode={"Create"} autoCompleteMode={"Add"} autoCompletePlaceHolder={"Select skills or type name to search"} formikValues={agentFormDialogueProps} skillData={parentAgentFormProps.skillData} />
                                        </Grid>
                                    }

                                    {agentFormMode === 'Create' &&
                                        <Grid item xs={12} display={'flex'} gap={2} justifyContent='flex-end' padding='20px' >
                                            <Button
                                                sx={cancelButtonStyle}
                                                onClick={() => { closeAgentForm(); }}>
                                                Cancel
                                            </Button>
                                            <Button
                                                type='submit'
                                                variant="contained"
                                                sx={{ borderRadius: '0.5rem', backgroundColor: '#3D8BF8', }}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    }
                                </Grid>
                            </div>
                        )}

                        {(agentFormMode === 'AgentMode') && //edit
                            <Grid container={true} sx={{
                                width: '458px',
                                paddingLeft: '17px',
                                paddingTop: '12px'

                            }} spacing={0.1}>
                                <Grid item sx={{
                                    fontWeight: "400",
                                    fontSize: '12px',
                                }} >
                                    Agent Modes given below
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ marginTop: '15px' }}>
                                    <OZFCheckbox
                                        name="inbound"
                                        label='Inbound Call'
                                        labelPosition="end" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <OZFCheckbox
                                        name="manual"
                                        label='Manual Dial'
                                        labelPosition="end" />
                                </Grid>
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_OUTBOUND")) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <OZFCheckbox
                                        name="preview"
                                        label='Preview Dial'
                                        labelPosition="end" />
                                </Grid>}
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_OUTBOUND")) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <OZFCheckbox
                                        name="progressive"
                                        label='Progressive Dial'
                                        labelPosition="end" />
                                </Grid>}
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_BLENDED")) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <OZFCheckbox
                                        name="blended"
                                        label='Blended'
                                        labelPosition="end" />
                                </Grid>}
                                {(checkRoles("ROLE_ADMIN") || checkRoles("ROLE_CHAT")) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <OZFCheckbox
                                        name="chat"
                                        label='Digital'
                                        labelPosition="end" />
                                </Grid>}
                            </Grid>
                        }
                        {(agentFormMode === 'blendedControls') &&
                            <NewAgentMode formik={agentFormDialogueProps} />
                        }
                        {(agentFormMode === 'callControls') &&
                            <CallControlsEditForm formikProps={agentFormDialogueProps} />
                        }
                        {agentFormMode === 'AgentSkill' &&
                            <Grid container={true} sx={{
                                paddingLeft: '20px',
                                marginTop: '15px',
                                width: '458px',
                            }}>
                                <Grid item>
                                    < AgentSkillList mode={'AgentSkill'} autoCompleteMode={"Edit"} autoCompletePlaceHolder={"Search Skill"} formikValues={agentFormDialogueProps} skillData={parentAgentFormProps.skillData} />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    <ScrollToFieldError />
                </form>
            )
        }

        return (
            <div className='ozAgentAddForm'>
                <Formik
                    initialValues={initialAgentFormData}
                    validationSchema={validationSchema}
                    onSubmit={(agentFromValues) => {
                        let cloneOfAgentFromValues = structuredClone(agentFromValues)
                        switch (cloneOfAgentFromValues.autoAnswer) {
                            case "None":
                                cloneOfAgentFromValues.autoAnswer = 0
                                break;
                            case "All":
                                cloneOfAgentFromValues.autoAnswer = 1
                                break;
                            case "Inbound":
                                cloneOfAgentFromValues.autoAnswer = 2
                                break;
                            case "Outbound":
                                cloneOfAgentFromValues.autoAnswer = 3
                                break;
                            default: showErrorNotification(`Auto answer isn't selected`);
                        }
                        cloneOfAgentFromValues.callControls = { ...cloneOfAgentFromValues.callControls, ...Object.fromEntries(Object.entries(cloneOfAgentFromValues.callControls.transfer).flatMap(([key, value]) => Object.entries(value).map(([type, val]) => [`transfer_${key}_${type}`, val]))) };
                        delete cloneOfAgentFromValues.callControls.transfer;
                        cloneOfAgentFromValues.callControls.conference_agent = cloneOfAgentFromValues.callControls.conference.agent;
                        cloneOfAgentFromValues.callControls.conference_phone = cloneOfAgentFromValues.callControls.conference.phone;
                        delete cloneOfAgentFromValues.callControls.conference;
                        cloneOfAgentFromValues.callControls.endcall = cloneOfAgentFromValues.callControls.endCall;
                        delete cloneOfAgentFromValues.callControls.endCall;
                        cloneOfAgentFromValues.callControls.callrecording = cloneOfAgentFromValues.callControls.callRecording;
                        delete cloneOfAgentFromValues.callControls.callRecording;
                        cloneOfAgentFromValues.callControls.manualdial = cloneOfAgentFromValues.callControls.manualDial;
                        delete cloneOfAgentFromValues.callControls.manualDial;
                        if (cloneOfAgentFromValues.callControls) {
                            cloneOfAgentFromValues.callControls = JSON.stringify(cloneOfAgentFromValues.callControls);
                        }
                        if (cloneOfAgentFromValues.sipLocation === "") {
                            cloneOfAgentFromValues.sipLocation = null;
                        }
                        let canAdd = true
                        let newSkillsData = cloneOfAgentFromValues.skills;
                        let newSkillsList = []
                        newSkillsData?.map((skills) => {
                            let priority = skills.priority
                            if (priority !== null && skills.skillList.length > 0) {
                                skills.skillList.map((skillValue) => {
                                    newSkillsList.push({ "priority": priority, "skillName": skillValue.skillName, "id": skillValue.id })
                                    return null
                                })
                            }
                            else {
                                if (priority === null && skills.skillList.length > 0) {
                                    showErrorNotification("Please Pass valid Priority")
                                    canAdd = false
                                }
                            }
                            return null;
                        })
                        cloneOfAgentFromValues.skills = newSkillsList
                        if (agentFormMode === ADMIN_FORM_MODES.add && canAdd) {
                            addAgent(cloneOfAgentFromValues)
                        }
                        else if (!(agentFormMode === ADMIN_FORM_MODES.add) && canAdd) {
                            cloneOfAgentFromValues.agentData = cloneOfAgentFromValues.agentData === "" ? null : cloneOfAgentFromValues.agentData;
                            let index = undefined
                            let length = "-1"
                            let dontClose = true
                            if (canAdd) { parentAgentFormProps.updateAgent(cloneOfAgentFromValues, dontClose, index, length, setAgentFormMode, agentFormMode) }
                            else {
                                showErrorNotification("Error converting Skill Data")
                            }
                        }
                    }
                    }>
                    {props => (
                        <AgentFormDialog props={props} />
                    )}
                </Formik>
            </div >
        )
    }

    return (
        <div>
            {agentFormMode === ADMIN_FORM_MODES.add ? (
                <DataForm />
            ) : (
                <div>
                    <OZDrawer
                        open={openAgentForm}
                        title={
                            <div>
                                <div className='oz-agent-form-drawer-header-conatiner'>
                                    {/* {authPage(AdminPages.AGENTS_EDIT) && <img ref={anchorRef} className='oz-agent-form-drawer-header-morevert' */}
                                    {(newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) && <img ref={anchorRef} className='oz-agent-form-drawer-header-morevert'
                                        src={`${process.env.PUBLIC_URL}/icons/more_vert.svg`}
                                        alt="edit"
                                        onClick={morevertClick}
                                    ></img>}
                                    <Tooltip title={parentAgentFormProps.data.agentName}>
                                        <Typography sx={{
                                            fontWeight: 600,
                                            fontSize: '18px',
                                            alignSelf: 'center',
                                            marginTop: '2px',
                                            maxWidth: "400px",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            // marginLeft: authPage(AdminPages.AGENTS_EDIT) ? "" : "10px"
                                            marginLeft: (newAuthPages(NewAdminPages.AGENTS_PAGE) || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Edit') || newAuthSubAdminAccess(NewAdminPages.AGENTS_PAGE, 'Full')) ? "" : "10px"
                                        }}>{parentAgentFormProps.data.agentName}</Typography>
                                    </Tooltip>
                                    <Menu
                                        anchorEl={editAgentBaiscInfoAnchorE1}
                                        open={Boolean(editAgentBaiscInfoAnchorE1)}
                                        onClose={handleMenuClose}
                                        sx={{
                                            maxHeight: "50vh",
                                            "& .MuiPaper-root": {
                                                backgroundColor: "white",
                                                width: "160px",
                                                height: "32px"
                                            },
                                            "& .MuiMenu-paper": {
                                                backgroundColor: "white",
                                                boxShadow: "0rem 0.25rem 0.75rem rgba(0, 0, 0, 0.04)",
                                                border: "0.0625rem solid #E6E5E6",
                                                borderRadius: "12px",
                                                marginLeft: "-10.5625rem",
                                                marginTop: '-1.5rem'
                                            },
                                        }}
                                    >
                                        <ListItemButton
                                            sx={styleListItemButton}
                                            onClick={() => { openBasicInfoEditDialog() }}
                                        >
                                            Edit basic info
                                        </ListItemButton>
                                        {/* <ListItemButton
                                            sx={{ ...styleListItemButton, marginTop: '8px' }}
                                            onClick={openDeleteDialog}>
                                            Remove Permanently
                                        </ListItemButton> */}
                                    </Menu>
                                    {openDelete && <DeleteDialog title={"Delete Agent"} content={`This will delete Agent: ${parentAgentFormProps.data.agentName} permanently. You cannot undo this action.`} onClose={() => { setDeleteDialog(false) }
                                    } open={openDelete} deleteFunction={onBtnDelete} />}
                                </div>
                                <div className='oz-agent-form-view-header-items'>
                                    <Typography sx={{ color: "#99A0A8", fontSize: '14px' }}>Agent Id: {parentAgentFormProps.data.agentId}</Typography>
                                    <Typography sx={{ color: "#212121", fontSize: '12px' }}>{parentAgentFormProps.data.email}</Typography>
                                </div>
                            </div>
                        }
                        onClose={() => { closeAgentForm(); }}
                        width={'464px'}
                    >
                        <AgentViewDrawer
                            hideButton={parentAgentFormProps.hideAgent}
                            updateFunction={parentAgentFormProps.updateAgent}
                            data={parentAgentFormProps.data}
                            editmode={setAgentFormMode}
                        />
                    </OZDrawer >

                    {agentFormMode === 'BasicInfo' && <OZDialog
                        open={openAgentForm}
                        title='Edit Basic Info'
                        onClose={() => { setAgentFormMode(ADMIN_FORM_MODES.view) }}
                        saveButtonProps={{
                            form: 'agentForm',
                            type: 'submit'
                        }}
                    >
                        <DataForm data={parentAgentFormProps.data} />
                    </OZDialog>}
                    {checkRoles('ROLE_CHAT_CALL') ? 
                        <>
                            {agentFormMode === 'blendedControls' && <OZDialog
                                open={openAgentForm}
                                title='Edit Blended Modes'
                                onClose={() => { setAgentFormMode(ADMIN_FORM_MODES.view) }}
                                saveButtonProps={{
                                    form: 'agentForm',
                                    type: 'submit'
                                }}
                            >
                                <DataForm data={parentAgentFormProps.data} />
                            </OZDialog>}
                        </>
                        :
                        <>
                            {agentFormMode === 'AgentMode' && <OZDialog
                                open={openAgentForm}
                                title='Edit Agent Mode'
                                onClose={() => { setAgentFormMode(ADMIN_FORM_MODES.view) }}
                                saveButtonProps={{
                                    form: 'agentForm',
                                    type: 'submit'
                                }}
                            >
                                <DataForm data={parentAgentFormProps.data} />
                            </OZDialog>}
                        </>
                    }
                    {agentFormMode === 'callControls' && <OZDialog
                        open={openAgentForm}
                        title='Edit Call Controller Settings'
                        onClose={() => { setAgentFormMode(ADMIN_FORM_MODES.view) }}
                        saveButtonProps={{
                            form: 'agentForm',
                            type: 'submit'
                        }}
                    >
                        <DataForm data={parentAgentFormProps.data} />
                    </OZDialog>}
                    {agentFormMode === 'AgentSkill' && <OZDialog
                        open={openAgentForm}
                        title='Edit Skills'
                        onClose={() => { setAgentFormMode(ADMIN_FORM_MODES.view); }}
                        saveButtonProps={{
                            form: 'agentForm',
                            type: 'submit'
                        }}
                    >
                        <DataForm data={parentAgentFormProps.data} />
                    </OZDialog>}
                </div>
            )}
        </div>
    );
};

const NewAgentMode = ({formik}) => {
    // const getTouchedStatus = () => {
    //     let touched = getIn(formik.touched, 'blendedControls');
    //     if(touched){
    //         if (touched.length === 0) {
    //             return true
    //         } else {
    //             return touched
    //         }
    //     } else {
    //         return touched
    //     }
    // }
    // const error = getIn(formik.errors, 'blendedControls');
    // const touch = getTouchedStatus()
    const formikTemp = formik.values;
    const [blendedOpen, setBlendedOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        if (formikTemp) {
            const initialSelectedOptions = [];

            mainOptions.forEach((option) => {
                // if (formikTemp[option.key] && option.key !== 'blended') {
                    if (formikTemp[option.key] && option.key !== 'blendedNormal') {
                    initialSelectedOptions.push(option);
                }
            });

            blendedSubOptions.forEach((subOption) => {
                if (formikTemp[subOption.key]) {
                    initialSelectedOptions.push(subOption);
                }
            });

            setSelectedOptions(initialSelectedOptions);
            formik.setFieldValue("blendedControls", initialSelectedOptions);
        }
    }, []);

    const mainOptions = [
      { label: "Inbound Call", key: "inbound" },
      { label: "Manual Dial", key: "manual" },
      { label: "Preview Dial", key: "preview" },
      { label: "Progressive Dial", key: "progressive" },
      { label: "Digital Call", key: "chat" },
      { label: "Blended", key: "blendedNormal", hasSubOptions: true },
    ];
  
    const blendedSubOptions = [
      { label: "Inbound", key: "blendedInbound" },
      { label: "Manual", key: "blendedManual" },
      { label: "Preview", key: "blendedPreview" },
      { label: "Progressive", key: "blendedProgressive" },
      { label: "Digital", key: "blendedChat" },
    ];

    function getLabelForChip(option){
        if(option.key === 'blendedInbound'){
            return `Blended(${option.label})`
        }else if(option.key === 'blendedManual'){
            return `Blended(${option.label})`
        }else if(option.key === 'blendedPreview'){
            return `Blended(${option.label})`
        }else if(option.key === 'blendedProgressive'){
            return `Blended(${option.label})`
        }else if(option.key === 'blendedChat'){
            return `Blended(${option.label})`
        }else {
            return option.label
        }
    }
      
    const handleSelectOption = (option) => {
        if (option.key === "blendedNormal") return;
    
        const isSelected = selectedOptions.some((o) => o.key === option.key);
    
        if (isSelected) {
            setSelectedOptions(selectedOptions.filter((o) => o.key !== option.key));
            formik.setFieldValue('blendedControls',selectedOptions.filter((o) => o.key !== option.key));
        } else {
            setSelectedOptions([...selectedOptions, option]);
            formik.setFieldValue('blendedControls',[...selectedOptions, option]);
        }
    };
    
    const handleBlendedToggle = () => {
        setBlendedOpen(!blendedOpen);
        // setBlendedOpen((prev) => !prev);
    };
    const handleChipDelete = (option) => {
        setSelectedOptions(selectedOptions.filter((o) => o.key !== option.key));
        formik.setFieldValue('blendedControls',selectedOptions.filter((o) => o.key !== option.key));
    };
    
    const handleSelectBlendedSubOption = (subOption) => {
        const isSelected = selectedOptions.some((o) => o.key === subOption.key);
    
        if (isSelected) {
            setSelectedOptions(selectedOptions.filter((o) => o.key !== subOption.key));
            formik.setFieldValue('blendedControls', selectedOptions.filter((o) => o.key !== subOption.key));
        } else {
            setSelectedOptions([...selectedOptions, subOption]);
            formik.setFieldValue('blendedControls', [...selectedOptions, subOption]);
        }
    };
    
    return (
        <>
            <Grid container xs={12}>
                <Grid item sx={{width: '30vw'}} xs={12}>
                    <>
                    <Typography fontSize='12px' fontWeight={400} lineHeight='14px' marginBottom={'6px'} marginLeft='1px' color='#212121'>
                        Please Select Agent Modes *
                    </Typography>
                    <Autocomplete
                        id='blendedControls'
                        multiple
                        sx={{
                            '& 	.MuiAutocomplete-listbox': {
                                scrollbarColor: 'rgb(190, 190, 190) rgb(240, 240, 240)',
                                scrollbarWidth: 'thin',
                            },
                            "& .MuiAutocomplete - root": {
                                "& .MuiAutocomplete-noOptions": {
                                    color: "red"
                                }
                            },
                            "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                                maxWidth: null,
                                borderRadius: "8px 0px 0px 8px !important",
                                minHeight: "28px !important",
                            },
                            "& .MuiAutocomplete-popupIndicatorOpen": { transform: "none", marginRight: "0px", padding: "0px", },
                        }}
                        disableCloseOnSelect
                        options={mainOptions}
                        value={selectedOptions}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, value, reason) => {
                            if (reason === "clear") {
                                setSelectedOptions([]);
                                formik.setFieldValue('blendedControls', []);
                            } else if (reason === "removeOption") {
                                setSelectedOptions(value);
                                formik.setFieldValue('blendedControls', value);
                            }
                            formik.setFieldTouched("blendedControls", true, true);
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                            <Chip
                                key={option.key}
                                label={getLabelForChip(option)}
                                {...getTagProps({ index })}
                                onDelete={() => handleChipDelete(option)}
                            />
                            ))
                        }
                        renderOption={(props, option) => (
                            <React.Fragment key={option.key}>
                                <ListItem
                                {...props}
                                button={!option.hasSubOptions}
                                // onClick={() => handleSelectOption(option)}
                                onClick={() => {
                                    if (option.key === 'blendedNormal') {
                                        handleBlendedToggle();
                                    } else {
                                        handleSelectOption(option);
                                    }
                                }}
                                >
                                {!option.hasSubOptions ? (
                                    <>
                                    <ListItemIcon>
                                        <Checkbox
                                        checked={selectedOptions.some((o) => o.key === option.key)}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option.label} />
                                    </>
                                ) : (
                                    <>
                                    <ListItemText primary={option.label} />
                                    <IconButton onClick={handleBlendedToggle}>
                                        {blendedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    </IconButton>
                                    </>
                                )}
                                </ListItem>
                                {option.key === "blendedNormal" && (
                                <Collapse in={blendedOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                    {blendedSubOptions.map((subOption) => (
                                        <ListItem
                                        key={subOption.key}
                                        sx={{ pl: 4 }}
                                        button
                                        onClick={() => handleSelectBlendedSubOption(subOption)}
                                        >
                                        <ListItemIcon>
                                            <Checkbox
                                            checked={selectedOptions.some(
                                                (o) => o.key === subOption.key
                                            )}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={subOption.label} />
                                        </ListItem>
                                    ))}
                                    </List>
                                </Collapse>
                                )}
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <FormControl component="fieldset"
                                // error={touch & Boolean(error) ? true : false}
                                error={Boolean(getIn(formik.touched, "blendedControls") && getIn(formik.errors, "blendedControls"))}
                                fullWidth
                            >
                                <TextField
                                    {...params}
                                    name="blendedControls"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: params.InputProps.endAdornment,
                                    }}
                                    sx={{
										'& .MuiOutlinedInput-root': {
											minHeight: "20px !important",
											fontSize: "14px",
											borderRadius: '8px',
										},
									}}
                                />
                                {/* <FormHelperText>{touch & Boolean(error) ? error : null}</FormHelperText> */}
                                <FormHelperText>
                                    {getIn(formik.touched, "blendedControls") && getIn(formik.errors, "blendedControls")
                                        ? getIn(formik.errors, "blendedControls")
                                        : null}
                                </FormHelperText>
                            </FormControl>
                        )}
                    />
                    </>
                </Grid>
            </Grid>
        </>
    );
}

function CallControlsEditForm({ formikProps }) {

    const callControls = formikProps.values.callControls;
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const open = Boolean(anchorEl);
    const openSubMenu = Boolean(subMenuAnchorEl);

    const handleClickTextField = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickSubMenu = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseSubMenu = () => {
        setSubMenuAnchorEl(null);
    };

    let selectedCallControls = useMemo(() => {
        const selectedCallControls = getSelectedCallControls(callControls);
        return selectedCallControls.filter(control => control !== 'transfer' && control !== 'conference');
    }, [callControls])

    let selectedConferenceValue = useMemo(() => {
        return callControls.conference ? Object.keys(callControls.conference).filter(key => callControls.conference[key]) : [];
    }, [callControls])

    let selectedTransferValue = useMemo(() => {
        return callControls.transfer ? Object.keys(callControls.transfer).map(key => Object.values(callControls.transfer[key]).includes(true)) : [];
    }, [callControls])

    let selectedTransferTextFieldValue = useMemo(() => {
        let value = [];
        for (const key in callControls.transfer) {
            for (const subKey in callControls.transfer[key]) {
                if (callControls.transfer[key][subKey]) {
                    value.push(`${key[0].toUpperCase() + key.slice(1)}/${subKey[0].toUpperCase() + subKey.slice(1)}`); // Skill/Warm
                }
            }
        }
        return value.join(', ');
    }, [callControls])

    const handleChange = (event, value) => {
        if (value) {
            let newControls = value.reduce((acc, control) => {
                acc[control] = true;
                return acc;
            }, {});
            newControls.transfer = callControls.transfer;
            newControls.conference = callControls.conference;
            formikProps.setFieldValue('callControls', newControls);
        }
    }

    const handleChangeConference = (event, value) => {
        if (value) {
            formikProps.setFieldValue('callControls.conference', ['agent', 'phone'].reduce((acc, control) => ({ ...acc, [control]: value.includes(control) }), {}));
        }
    }

    const commonAutocompleteProps = {
        popupIcon: <DropDownIcon />,
        placeholder: "Select",
        size: 'small',
        fullWidth: true,
        isOptionEqualToValue: (option, value) => option === value,
        multiple: true,
        clearIcon: null,
        disableCloseOnSelect: true,
        componentsProps: { paper: { sx: { border: "1px solid #D6D6D7", marginTop: "10px", borderRadius: "8px" } } },
        renderOption: (props, option, { selected, inputValue }) => <Box {...props} className="oz-admin-agent-update-content-body-controls-item-select-option">
            <CustomizedCheckbox checked={selected} />
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"} textTransform={"capitalize"}>
                {option === 'callRecording' ? 'Call Recording' : option === 'endCall' ? 'End Call' : option === 'manualDial' ? 'Manual Dial' : option}
            </Typography>
        </Box>,
        renderTags: (value, getTagProps) => {
            return value.map((option, index) => {
                return <Typography key={index} sx={{
                    fontSize: "14px",
                    color: "#47586E",
                    textTransform: "capitalize"
                }}
                    {...getTagProps({ index })}>
                    {(option === 'callRecording' ? 'Call Recording' : option === 'endCall' ? 'End Call' : option === 'manualDial' ? 'Manual Dial' : option) + ((index === value.length - 1) ? '' : ', ')}
                </Typography>
            })
        },
        renderInput: (params) => <TextField placeholder="Select"
            sx={{ '& .MuiOutlinedInput-root': { fontSize: "14px", borderRadius: '8px' } }}
            {...params}
        />
    }

    return <Grid container={true} sx={{
        paddingLeft: '20px',
        marginTop: '4px',
        width: '458px',
    }} spacing={1} rowGap={2}>
        <Grid item xs={12}>
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
                Choose Value
            </Typography>
            <Autocomplete
                options={['mute', 'hold', 'answer', 'decline', 'endCall', 'callRecording', 'manualDial']}
                onChange={handleChange}
                value={selectedCallControls}
                {...commonAutocompleteProps}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
                Choose Conference  Value
            </Typography>
            <Autocomplete
                options={['agent', 'phone']}
                onChange={handleChangeConference}
                value={selectedConferenceValue}
                {...commonAutocompleteProps}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography fontSize={"12px"} fontWeight={400} color={"#212121"}>
                Choose Transfer Value
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Select"
                onClick={handleClickTextField}
                value={selectedTransferTextFieldValue}
                onChange={(e) => { }}
                sx={{ '& .MuiOutlinedInput-root': { fontSize: "14px", borderRadius: '8px', height: "40px" } }}
                InputProps={{
                    endAdornment: <IconButton sx={{ height: "22px", width: "22px" }}> <DropDownIcon /></IconButton>,
                }}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{
                '& .MuiPaper-root': {
                    borderRadius: '8px',
                    marginTop: '10px',
                    width: '430px',
                    border: "1px solid #D6D9DC",
                    boxShadow: "none"
                }
            }}>
                {['agent', 'skill', 'phone', 'ivr'].map((option, index) => (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: "2px",
                        '&:hover': {
                            backgroundColor: '#F5F6F8',
                            borderRadius: '8px'
                        }
                    }}
                        key={index}
                    >
                        <CustomizedCheckbox onClick={(() => {
                            formikProps.setFieldValue(`callControls.transfer.${option}`, { warm: !selectedTransferValue[index], blind: !selectedTransferValue[index] });
                        })}
                            checked={selectedTransferValue[index]} />
                        <Typography flexGrow={1} fontSize={"12px"} fontWeight={400} color={"#47586E"} textTransform={"capitalize"}>
                            {option}
                        </Typography>
                        <Box sx={{
                            padding: "6px 10px 6px 10px",
                            background: "#E6F4FF",
                            borderRadius: "32px",
                            mr: "4px"
                        }}>
                            <Typography fontSize={'10px'} color="#000000">
                                {callControls.transfer[option].warm + callControls.transfer[option].blind}
                            </Typography>
                        </Box>
                        <IconButton onClick={(e) => { handleClickSubMenu(e); setSelectedMenuItem(option) }}>
                            <NextIcon />
                        </IconButton>
                        {selectedMenuItem === option && <Menu
                            anchorEl={subMenuAnchorEl}
                            open={openSubMenu}
                            onClose={handleCloseSubMenu}
                            sx={{
                                '& .MuiPaper-root': {
                                    borderRadius: '8px',
                                    marginTop: "-24px",
                                    width: '174px',
                                    height: "68px",
                                    border: "1px solid #D6D9DC",
                                    marginLeft: "36px",
                                    boxShadow: "none"
                                }
                            }}
                        >
                            {['warm', 'blind'].map((option, index) => (
                                <Box sx={{
                                    display: 'flex',
                                    margin: "2px",
                                    alignItems: 'center',
                                    height: "22px",
                                    cursor: "pointer",
                                    '&:hover': {
                                        backgroundColor: '#F5F6F8',
                                        borderRadius: '8px'
                                    }
                                }} key={index}
                                    onClick={() => {
                                        let newTransfer = { ...formikProps.values.callControls.transfer };
                                        newTransfer[selectedMenuItem][option] = !newTransfer[selectedMenuItem][option];
                                        formikProps.setFieldValue('callControls.transfer', newTransfer);
                                        handleCloseSubMenu();
                                    }}
                                >
                                    <CustomizedCheckbox checked={callControls.transfer[selectedMenuItem][option]} />
                                    <Typography flexGrow={1} fontSize={"12px"} fontWeight={400} color={"#47586E"} textTransform={"capitalize"}>
                                        {option}
                                    </Typography>
                                </Box>
                            ))}
                        </Menu>}
                    </Box>
                ))}
            </Menu>
        </Grid>
    </Grid >
}

export default AgentForm;