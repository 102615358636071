import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Card,
  CardContent,
  Typography,
  FormHelperText,
  CircularProgress,
  Checkbox,
  Alert,
  SelectChangeEvent,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Menu,
  InputAdornment
} from '@mui/material';
import { 
  MoreVertical,
  Copy,
  Trash2,
  X as CloseIcon,
  AlertCircle
} from 'lucide-react';
import { 
  Campaign,
  CampaignType,
  TemplateType,
  CategoryType,
  Template,
  Sender,
  ValidationErrors,
  Campaign_Request,
  CampaignFormData,
  FormEventType,
} from './interfaces';
import { WhatsAppTemplate, WhatsAppNumber } from './whatsappApi';
import { campaignApi } from './campaignApi';
import { convertToApiType } from './interfaces';
import { validateCampaignEdit } from './campaignEditValidation';
import { getUserId } from '../../../services/auth-service';
import { whatsappApi } from './whatsappApi';
import { templateApi } from './templateApi'; 
import WhatsAppPreview from './WhatsAppPreview';
import WhatsAppTemplateVariables from './WhatsAppTemplateVariables';
import { validateWhatsAppVariables } from './interfaces';
import { Search } from 'lucide-react';

// Props Interface
interface CampaignEditProps {
  campaign: Campaign & {
    availableSenders: Sender[];
    availableTemplates: Template[];
  };
  onClose: () => void;
  onSave: (updatedCampaign: Campaign) => void;
  onDelete: (campaignId: string) => void;
}

// Utility Functions
const formatDateTime = (dateString: string): string => {
  try {
    return new Date(dateString).toLocaleString();
  } catch {
    return dateString;
  }
};

const formatPosition = (position: string | null | undefined): string => {
  if (!position) return 'Stopped';
  return position.charAt(0).toUpperCase() + position.slice(1);
};
const initialFormData: CampaignFormData = {
  campaignName: '',
  messageType: 'Simple',
  senderId: '',
  templateId: '',
  category: 'simple',
  templateType: 'promotional',
  schedule: true,
  startDate: new Date().toISOString().split('T')[0],
  endDate: new Date().toISOString().split('T')[0],
  runTimeStart: '09:00',
  runTimeEnd: '20:59',
  fileUpload: null,
  whatsAppFile:null,
  allowDuplicate: false,
  templateText: '',
  templateName: '',
  isWhatsappFlow: false,
  numbers: '',
  extraData: {}
};

const convertCampaignToFormData = (campaign: Campaign): CampaignFormData => ({
  ...initialFormData,
  campaignName: campaign.name || '',
  // Remove messageType since it shouldn't be editable
  category: campaign.category,
  senderId: campaign.type === 'WhatsApp' ? campaign.sender : campaign.sender || '',
  templateId: campaign.type === 'WhatsApp' ? campaign.templateName : campaign.templateId || '',
  templateType: campaign.templateType,
  templateLanguage:campaign.templateLanguage,
  templateText: campaign.templateText || '',
  startDate: campaign.schedule.startDate.split('T')[0],
  endDate: campaign.schedule.endDate.split('T')[0],
  runTimeStart: campaign.schedule.start.slice(0, 5),
  runTimeEnd: campaign.schedule.end.slice(0, 5),
  schedule: true,
  allowDuplicate: campaign.allowDuplicate,
  // Remove fileUpload since it's not needed in edit
  isWhatsappFlow: campaign.type === 'WhatsApp',
  // Remove numbers field since it shouldn't be editable
  extraData: campaign.extraData || {}
});

const CampaignEdit: React.FC<CampaignEditProps> = ({
  campaign,
  onClose,
  onSave,
  onDelete
}) => {
  // State management
  const [editedCampaign, setEditedCampaign] = useState<Campaign>(campaign);
  const [formData, setFormData] = useState<CampaignFormData>(convertCampaignToFormData(campaign));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fieldErrors, setFieldErrors] = useState<ValidationErrors>({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  
  // WhatsApp specific states
  const [whatsappNumbers, setWhatsappNumbers] = useState<WhatsAppNumber[]>([]);
  const [whatsappTemplates, setWhatsappTemplates] = useState<WhatsAppTemplate[]>([]);
  const [loadingWhatsappNumbers, setLoadingWhatsappNumbers] = useState(false);
  const [loadingWhatsappTemplates, setLoadingWhatsappTemplates] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>(campaign.availableTemplates);

// New states for search and template management
const [searchQuery, setSearchQuery] = useState('');
const [filteredWhatsappNumbers, setFilteredWhatsappNumbers] = useState<WhatsAppNumber[]>([]);
const [templateErrors, setTemplateErrors] = useState<Record<string, string>>({});

  

  // Add new states at the top with other state declarations
const [templates, setTemplates] = useState<Template[]>([]);
const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);

const userId = getUserId();
const isEditable = !['running', 'completed'].includes(campaign.position || '');

const handleVariableChange = (name: string, value: string | File) => {
  setEditedCampaign(prev => ({
    ...prev,
    extraData: {
      ...prev.extraData,
      [name]: value
    }
  }));

  // Clear field error if it exists
  if (fieldErrors[name]) {
    setFieldErrors(prev => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  }

  if (value) {
    const templateTextForValidation = typeof editedCampaign.templateText === 'object' 
      ? JSON.stringify(editedCampaign.templateText) 
      : editedCampaign.templateText;
      
    const errors = validateWhatsAppVariables(templateTextForValidation, {
      ...editedCampaign.extraData,
      [name]: value
    });
    setTemplateErrors(errors);
  }
};

// Effect for loading initial data
useEffect(() => {
  const currentDate = new Date().toISOString();
  setEditedCampaign({
    ...campaign,
    schedule: {
      ...campaign.schedule,
      scheduled: Boolean(campaign.schedule.scheduled),
      startDate: campaign.schedule.startDate || currentDate,
      endDate: campaign.schedule.endDate || currentDate,
      start: campaign.schedule.start || '09:00:00',
      end: campaign.schedule.end || '20:59:00'
    }
  });
  setFormData(convertCampaignToFormData(campaign));
  
  if (campaign.type === 'WhatsApp') {
    fetchWhatsAppNumbers();
  }
}, [campaign]);

// Add this useEffect for number filtering
useEffect(() => {
  setFilteredWhatsappNumbers(
    whatsappNumbers.filter(num =>
      num.number.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );
}, [searchQuery, whatsappNumbers]);

useEffect(() => {
  console.log('Campaign prop:', campaign);
  console.log('Edited campaign state:', editedCampaign);
  console.log('Form data:', formData);
}, [campaign, editedCampaign, formData]);
// In CampaignEdit.tsx

// 2. Update useEffect to handle initial data
useEffect(() => {
  if (editedCampaign.type === 'SMS' && editedCampaign.senderId) {
    loadSMSTemplates(editedCampaign.senderId);
  } else if (editedCampaign.type === 'WhatsApp' && editedCampaign.senderId) {
    loadWhatsAppTemplates();
  }

  // Set initial form data
  setFormData(convertCampaignToFormData(campaign));
  
  // If there's a template, select it
  if (campaign.templateId) {
    handleTemplateChange(campaign.templateId);
  }
}, [campaign, editedCampaign.type, editedCampaign.senderId]);

// Add this at the start of useEffect where we initialize data
useEffect(() => {
  const currentDate = new Date().toISOString();
  setEditedCampaign({
    ...campaign,
    schedule: {
      ...campaign.schedule,
      scheduled: Boolean(campaign.schedule.scheduled),
      startDate: campaign.schedule.startDate || currentDate,
      endDate: campaign.schedule.endDate || currentDate,
      start: campaign.schedule.start || '09:00:00',
      end: campaign.schedule.end || '20:59:00'
    }
  });
  setFormData(convertCampaignToFormData(campaign));
  
  // Initialize template based on campaign type
  if (campaign.type === 'SMS' && campaign.senderId) {
    loadSMSTemplates(campaign.senderId).then(() => {
      if (campaign.templateId) {
        handleTemplateChange(campaign.templateId);
      }
    });
  } else if (campaign.type === 'WhatsApp' && campaign.senderId) {
    loadWhatsAppTemplates().then(() => {
      if (campaign.templateId) {
        handleTemplateChange(campaign.templateId);
      }
    });
  }
}, [campaign]);

  const fetchWhatsAppNumbers = async () => {
    try {
      if (!userId) {
        setError('User ID is required');
        return;
      }
      setLoadingWhatsappNumbers(true);
      const response = await whatsappApi.listWhatsAppNumbers(userId.toString());
      if (response.data?.content) {
        setWhatsappNumbers(response.data.content);
      }
    } catch (error) {
      setError('Failed to fetch WhatsApp numbers');
    } finally {
      setLoadingWhatsappNumbers(false);
    }
  };

  const loadSMSTemplates = async (senderId: string) => {
    console.log('loadSMSTemplates called with senderId:', senderId);
    try {
      if (!userId) {
        console.error('No userId available');
        setError('User ID is required');
        return;
      }
  
      setLoadingTemplates(true);
      
      // Find the selected sender
      const selectedSender = campaign.availableSenders.find(
        s => s.id.toString() === senderId
      );
  
      if (!selectedSender) {
        console.error('Invalid sender selected');
        setError('Invalid sender selected');
        return;
      }
  
      console.log('Selected sender:', selectedSender);
  
      const response = await templateApi.listSMSTemplates(userId, 0, 100);
      
      if (response.data?.content) {
        // Filter templates for the selected sender
        const filtered = response.data.content
          .filter((template: Template) => {
            const templateSender = template.sender?.trim();
            const selectedSenderName = selectedSender.name.trim();
            
            console.log('Comparing senders:', {
              templateSender,
              selectedSenderName,
              matches: templateSender === selectedSenderName
            });
            
            return templateSender === selectedSenderName;
          })
          .map((template: Template) => ({
            ...template,
            text: template.text || '',
            id: template.id.toString()
          }));
  
        console.log('Filtered templates:', filtered);
        setFilteredTemplates(filtered);
      }
    } catch (error) {
      console.error('Error loading SMS templates:', error);
      setError('Failed to fetch templates');
    } finally {
      setLoadingTemplates(false);
    }
  };

  // Effect for loading WhatsApp templates
  const loadWhatsAppTemplates = async () => {
    try {
      setLoadingWhatsappTemplates(true);
      if (!userId) {
        setError('User ID is required');
        return;
      }
      const response = await whatsappApi.getWhatsAppTemplates(userId.toString());
      if (response.data?.content) {
        setWhatsappTemplates(response.data.content);
  
        // If there's a current template, select it
        if (editedCampaign.templateId) {
          const currentTemplate = response.data.content.find(
            t => t.name === editedCampaign.templateId
          );
          if (currentTemplate) {
            handleTemplateChange(currentTemplate.name);
          }
        }
      }
    } catch (error) {
      setError('Failed to fetch WhatsApp templates');
    } finally {
      setLoadingWhatsappTemplates(false);
    }
  };
  
  
 // In CampaignEdit.tsx, update handleFieldChange for sender selection

 const handleFieldChange = (field: keyof Campaign) => (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
) => {
  if (!isEditable) return;

  const value = event.target.value;
  console.log(`Field ${field} changed to:`, value);
  
  if (field === 'senderId') {
    // Find the selected sender
    const selectedSender = campaign.availableSenders.find(
      s => s.id.toString() === value
    );

    console.log('Selected sender:', selectedSender);
    
    // Update all related fields
    setEditedCampaign(prev => ({
      ...prev,
      senderId: value,
      sender: selectedSender?.name || value, // Use name for SMS, value for WhatsApp
      templateId: '', // Reset template when sender changes
      templateName: '',
      templateText: '',
    }));

    setFormData(prev => ({
      ...prev,
      senderId: value,
      sender: selectedSender?.name || value,
      templateId: '',
      templateName: '',
      templateText: '',
    }));

    // Load appropriate templates
    if (editedCampaign.type === 'WhatsApp') {
      loadWhatsAppTemplates();
    } else {
      loadSMSTemplates(value);
    }
  } else {
    // Handle other field changes normally
    setEditedCampaign(prev => ({
      ...prev,
      [field]: value
    }));

    setFormData(prev => ({
      ...prev,
      [field === 'name' ? 'campaignName' : field]: value
    }));
  }

  // Clear any existing field errors
  if (fieldErrors[field]) {
    setFieldErrors(prev => {
      const { [field]: _, ...rest } = prev;
      return rest;
    });
  }
};

const handleTemplateChange = async (templateId: string) => {
  try {
    if (editedCampaign.type === 'WhatsApp') {
      // WhatsApp template handling
      const whatsappTemplate = whatsappTemplates.find(t => t.name === templateId);
      if (whatsappTemplate) {
        setEditedCampaign(prev => ({
          ...prev,
          templateId: whatsappTemplate.name,
          templateName: whatsappTemplate.name,
          templateText: whatsappTemplate.text || '',
          templateLanguage: whatsappTemplate.language || 'en',
          templateType: whatsappTemplate.type as TemplateType
        }));

        setFormData(prev => ({
          ...prev,
          templateId: whatsappTemplate.name,
          templateName: whatsappTemplate.name,
          templateText: whatsappTemplate.text || '',
          templateType: whatsappTemplate.type as TemplateType
        }));
      }
    } else {
      // SMS template handling
      const template = filteredTemplates.find(t => t.id.toString() === templateId);
      
      if (template) {
        // Find current sender
        const sender = campaign.availableSenders.find(
          s => s.id.toString() === editedCampaign.senderId
        );

        // Update campaign data
        setEditedCampaign(prev => ({
          ...prev,
          templateId: templateId,
          templateName: template.name,
          templateText: template.text || '',
          templateType: template.type as TemplateType,
          category: template.type === 'service_implicit' || template.type === 'service_explicit' 
            ? 'dynamic' as CategoryType 
            : 'simple' as CategoryType,
          sender: sender?.name || editedCampaign.sender,
          senderId: editedCampaign.senderId || '' // Ensure string type
        }));

        // Update form data - ensuring all required fields are strings
        setFormData(prev => ({
          ...prev,
          templateId: templateId,
          templateName: template.name,
          templateText: template.text || '',
          templateType: template.type as TemplateType,
          category: template.type === 'service_implicit' || template.type === 'service_explicit' 
            ? 'dynamic' as CategoryType 
            : 'simple' as CategoryType,
          sender: sender?.name || editedCampaign.sender || '',
          senderId: editedCampaign.senderId || '', // Ensure string type
          startDate: prev.startDate,
          endDate: prev.endDate,
          runTimeStart: prev.runTimeStart,
          runTimeEnd: prev.runTimeEnd,
          campaignName: prev.campaignName,
          messageType: prev.messageType,
          schedule: prev.schedule,
          fileUpload: prev.fileUpload,
          allowDuplicate: prev.allowDuplicate,
          isWhatsappFlow: prev.isWhatsappFlow,
          numbers: prev.numbers,
          extraData: prev.extraData || {}
        }));

        setSelectedTemplate(template);
      }
    }
  } catch (error) {
    console.error('Error in handleTemplateChange:', error);
    setError('Failed to update template');
  }
};

  // Helper function to update template data
  const updateTemplateData = (templateData: {
    templateId: string;
    templateName: string;
    templateText: string;
    templateType: TemplateType;
  }) => {
    setEditedCampaign(prev => ({
      ...prev,
      ...templateData
    }));

    setFormData(prev => ({
      ...prev,
      ...templateData
    }));
  };
  const handleScheduleStateChange = (checked: boolean) => {
    const currentDate = new Date().toISOString();
    setEditedCampaign(prev => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        scheduled: checked,
        startDate: checked ? prev.schedule.startDate : currentDate,
        endDate: checked ? prev.schedule.endDate : currentDate
      }
    }));
  };
  // Handle schedule changes
  const handleScheduleChange = (field: 'startDate' | 'endDate' | 'start' | 'end') => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isEditable) return;

    const value = event.target.value;
    
    setEditedCampaign(prev => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        [field]: field.includes('Date') ? value : `${value}:00`
      }
    }));

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Menu handlers
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDeleteClick = () => {
    handleMenuClose();
    setShowDeleteDialog(true);
  };
  // Update the handleSave function in CampaignEdit component
const handleSave = async () => {
  if (!isEditable || loading) return;

  try {
    setLoading(true);
    
    const validationResult = validateCampaignEdit(
      formData,
      editedCampaign.type as CampaignType,
      campaign.availableSenders
    );

    if (!validationResult.isValid) {
      setFieldErrors(validationResult.errors);
      setError('Please fix validation errors before saving');
      return;
    }

    const apiType = convertToApiType(editedCampaign.type as CampaignType);

    // Remove numbers and correctly format schedule times
    const { numbers, ...campaignUpdateData } = editedCampaign;

    // Format the schedule times with seconds
    const formattedStartTime = `${campaignUpdateData.schedule.start.includes(':00') 
      ? campaignUpdateData.schedule.start 
      : `${campaignUpdateData.schedule.start}:00`}`;
    
    const formattedEndTime = `${campaignUpdateData.schedule.end.includes(':00')
      ? campaignUpdateData.schedule.end
      : `${campaignUpdateData.schedule.end}:00`}`;

    // Format the campaign request
    const campaignRequest: Campaign_Request = {
      name: campaignUpdateData.name,
      type: apiType,
      category: campaignUpdateData.category,
      templateType: campaignUpdateData.templateType || 'text', // Default to text if not specified
      schedule: {
        scheduled: Boolean(campaignUpdateData.schedule.scheduled),
        startDate: campaignUpdateData.schedule.startDate,
        endDate: campaignUpdateData.schedule.endDate,
        start: formattedStartTime,
        end: formattedEndTime
      },
      sender: campaignUpdateData.sender,
      templateName: campaignUpdateData.templateName,
      templateText: campaignUpdateData.templateText,
      allowDuplicate: campaignUpdateData.allowDuplicate,
      extraData: campaignUpdateData.extraData || {},
      // Add WhatsApp specific fields
      ...(editedCampaign.type === 'WhatsApp' && {
        templateLanguage: campaignUpdateData.templateLanguage || 'en',
        templateType: editedCampaign.templateType || 'text',
        bodyText: campaignUpdateData.bodyText || campaignUpdateData.templateText,
        headerText: campaignUpdateData.headerText || '',
        footerText: campaignUpdateData.footerText || '',
        variables: campaignUpdateData.variables || {},
      })
    };

    const response = await campaignApi.updateCampaign(userId, editedCampaign.id, campaignRequest);
    
    if (response.status === 'success') {
      onSave(editedCampaign);
      setError(null);
    } else {
      throw new Error(response.message || 'Failed to update campaign');
    }
  } catch (error: any) {
    console.error('Error updating campaign:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update campaign';
    setError(errorMessage);
    if (error.response?.data?.errors) {
      setFieldErrors(error.response.data.errors);
    }
  } finally {
    setLoading(false);
  }
};

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3 
      }}>
        <Typography variant="h6">
          Edit {editedCampaign.type} Campaign
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
  <IconButton 
    onClick={() => setShowDeleteDialog(true)}
    disabled={loading || editedCampaign.position === 'running'}
    title={editedCampaign.position === 'running' ? 'Cannot delete running campaign' : 'Delete Campaign'}
  >
    <Trash2 size={20} />
  </IconButton>
  <IconButton onClick={onClose}>
    <CloseIcon size={20} />
  </IconButton>
</Box>
      </Box>

   {/* Error Display */}
   {(Object.keys(fieldErrors).length > 0 || error) && (
        <Alert 
          severity="error" 
          onClose={() => {
            setError(null);
            setFieldErrors({});
          }}
          sx={{
            mb: 3,
            backgroundColor: '#FEE2E2',
            color: '#DC2626',
            '& .MuiAlert-icon': {
              color: '#DC2626'
            }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {fieldErrors.form || error || 'Please fix the validation errors below'}
          </Typography>
          {Object.entries(fieldErrors)
            .filter(([key]) => key !== 'form')
            .map(([field, error]) => (
              <Typography key={field} variant="caption" display="block" sx={{ mt: 0.5 }}>
                • {error}
              </Typography>
            ))
          }
        </Alert>
      )}

      {/* Read-only Warning */}
      {!isEditable && (
        <Alert 
          severity="info" 
          sx={{ 
            mb: 3,
            backgroundColor: '#EFF6FF',
            color: '#1D4ED8',
            '& .MuiAlert-icon': {
              color: '#1D4ED8'
            }
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            This campaign is {formatPosition(editedCampaign.position)} and cannot be edited
          </Typography>
        </Alert>
      )}

      {/* Main Form */}
      <Grid container spacing={3}>
        {/* Left Column - Form Fields */}
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
            <TextField
              label="Campaign Name"
              value={editedCampaign.name || ''}
              onChange={handleFieldChange('name')}
              error={!!fieldErrors.campaignName}
              helperText={fieldErrors.campaignName}
              disabled={!isEditable || loading}
              fullWidth
              required
            />

           {/* Sender/WhatsApp Number Selection */}
<FormControl fullWidth disabled={!isEditable || loading} required>
  <InputLabel>{editedCampaign.type === 'WhatsApp' ? 'WhatsApp Number' : 'Sender ID'}</InputLabel>
  {editedCampaign.type === 'WhatsApp' ? (
    <Select
      value={editedCampaign.senderId || ''}
      onChange={(e) => handleFieldChange('senderId')(e)}
      error={!!fieldErrors.senderId}
      label="WhatsApp Number"
      MenuProps={{
        PaperProps: {
          style: { maxHeight: 300 }
        }
      }}
    >
      {/* Search TextField */}
      <MenuItem 
        dense
        disableRipple 
        style={{ 
          position: 'sticky', 
          top: 0, 
          backgroundColor: '#fff',
          zIndex: 1 
        }}
      >
        <TextField
          size="small"
          fullWidth
          placeholder="Search numbers..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClick={(e) => e.stopPropagation()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search size={18} />
              </InputAdornment>
            )
          }}
        />
      </MenuItem>

      {/* Divider */}
      <MenuItem 
        disabled 
        dense
        style={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          padding: 0,
          margin: '4px 0'
        }}
      />

      {/* Numbers List */}
      {loadingWhatsappNumbers ? (
        <MenuItem disabled>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          Loading numbers...
        </MenuItem>
      ) : filteredWhatsappNumbers.length === 0 ? (
        <MenuItem disabled>
          <Typography color="text.secondary">
            No numbers found
          </Typography>
        </MenuItem>
      ) : (
        filteredWhatsappNumbers.map((num) => (
          <MenuItem key={num.id} value={num.number}>
            {num.number}
          </MenuItem>
        ))
      )}
    </Select>
  ) : (
    <Select
      value={editedCampaign.senderId || ''}
      onChange={(e) => handleFieldChange('senderId')(e)}
      error={!!fieldErrors.senderId}
      label="Sender ID"
    >
      {campaign.availableSenders
        .filter(sender => !sender.deleted && sender.approved)
        .map((sender) => (
          <MenuItem key={sender.id} value={sender.id.toString()}>
            {sender.name}
          </MenuItem>
        ))}
    </Select>
  )}
  {fieldErrors.senderId && (
    <FormHelperText error>{fieldErrors.senderId}</FormHelperText>
  )}
</FormControl>
           

            {/* Replace the existing Template FormControl with this updated version */}
<FormControl 
  fullWidth 
  disabled={!isEditable || loading} 
  required
  error={!!fieldErrors.templateId}
>
  <InputLabel id="template-select-label">Template</InputLabel>
  <Select
  labelId="template-select-label"
  value={editedCampaign.type === 'WhatsApp' ? 
    (editedCampaign.templateName || '') : 
    (editedCampaign.templateId || '')}
  onChange={(e) => {
    console.log('Template selection changed:', e.target.value);
    handleTemplateChange(e.target.value);
  }}
  label="Template"
  disabled={loadingTemplates || !editedCampaign.senderId || !isEditable}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 300,
      },
    },
  }}
>
    {editedCampaign.type === 'WhatsApp' ? (
      whatsappTemplates.map((template) => (
        <MenuItem 
          key={template.id} 
          value={template.name}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Typography variant="subtitle2">{template.name}</Typography>
          <Typography variant="caption" color="textSecondary">
            ({template.type})
          </Typography>
        </MenuItem>
      ))
    ) : (
      loadingTemplates ? (
        <MenuItem disabled>
          <CircularProgress size={20} sx={{ mr: 1 }} />
          Loading templates...
        </MenuItem>
      ) : filteredTemplates.length === 0 ? (
        <MenuItem disabled>
          <Typography color="text.secondary">
            No templates available for this sender
          </Typography>
        </MenuItem>
      ) : (
        filteredTemplates.map((template) => (
          <MenuItem 
            key={template.id} 
            value={template.id.toString()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
          >
            <Typography variant="subtitle2">{template.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              {template.text?.substring(0, 50)}...
            </Typography>
          </MenuItem>
        ))
      )
    )}
  </Select>
  {fieldErrors.templateId && (
    <FormHelperText error>{fieldErrors.templateId}</FormHelperText>
  )}
</FormControl>

{/* Update the Template Text field */}
{editedCampaign.type === 'WhatsApp' ? (
  <>
    {editedCampaign.templateId && editedCampaign.templateText && (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" gutterBottom>Message Preview</Typography>
        {Object.keys(templateErrors).length > 0 && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 2,
              backgroundColor: '#FEE2E2',
              color: '#DC2626'
            }}
          >
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Please fix the following errors:
            </Typography>
            {Object.entries(templateErrors).map(([field, error]) => (
              <Typography key={field} variant="caption" display="block" sx={{ mt: 0.5 }}>
                • {error}
              </Typography>
            ))}
          </Alert>
        )}
        <WhatsAppPreview 
          templateData={editedCampaign.templateText}
          variables={editedCampaign.extraData}
          errors={templateErrors}
        />
      </Box>
    )}

    {whatsappTemplates.find(t => t.name === editedCampaign.templateId)?.variables && (
      <WhatsAppTemplateVariables
        variables={whatsappTemplates.find(t => t.name === editedCampaign.templateId)!.variables}
        onVariableChange={handleVariableChange}
        extraData={editedCampaign.extraData}
        onFileChange={(file) => {
          if (file && (file.type.startsWith('image/') || file.type === 'application/pdf' || file.type === 'video/mp4')) {
            // Handle file upload
            console.log('File selected:', file);
          } else {
            setTemplateErrors({
              ...templateErrors,
              header: 'Please upload a valid image, PDF, or video file'
            });
          }
        }}
        getFieldError={(field: string) => fieldErrors[field]}
        isSubmitting={loading}
      />
    )}
  </>
) : (
  <TextField
    label="Template Text"
    multiline
    rows={4}
    value={editedCampaign.templateText || ''}
    onChange={handleFieldChange('templateText')}
    error={!!fieldErrors.templateText}
    helperText={
      fieldErrors.templateText || 
      (editedCampaign.category === 'dynamic' ? 'Template text cannot be edited for dynamic templates' : '')
    }
    disabled={
      !isEditable || 
      loading || 
      editedCampaign.category === 'dynamic' || 
      !editedCampaign.templateId
    }
    fullWidth
    required
    InputProps={{
      readOnly: editedCampaign.category === 'dynamic'
    }}
  />
)}
{/* Add SMS character counter */}
{editedCampaign.type === 'SMS' && editedCampaign.templateText && (
  <Box sx={{ 
    mt: 2, 
    p: 2, 
    bgcolor: '#f0f7ff', 
    borderRadius: 1,
    border: '1px solid #e0e7ff'
  }}>
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      color: '#4B87FF',
      fontSize: '0.875rem'
    }}>
      <Typography component="span">Total characters are</Typography>
      <Box component="span" sx={{ px: 1, py: 0.5, bgcolor: '#e0e7ff', borderRadius: 1 }}>
        {editedCampaign.templateText.length}
      </Box>
      <Typography component="span">will be divided into</Typography>
      <Box component="span" sx={{ px: 1, py: 0.5, bgcolor: '#e0e7ff', borderRadius: 1 }}>
        {Math.ceil(editedCampaign.templateText.length / 160)}
      </Box>
      <Typography component="span">parts</Typography>
    </Box>
  </Box>
)}

{/* Schedule settings box */}
<Box sx={{ 
  p: 2, 
  bgcolor: '#F9FAFB', 
  borderRadius: 1,
  border: '1px solid #E5E7EB'
}}>
  <Typography variant="subtitle2" sx={{ mb: 2 }}>Schedule Settings</Typography>
  <Stack spacing={2}>
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(editedCampaign.schedule.scheduled)}
          onChange={(e) => handleScheduleStateChange(e.target.checked)}
          disabled={!isEditable || loading}
        />
      }
      label="Enable Date Scheduling"
    />

    {editedCampaign.schedule.scheduled && (
      <Stack direction="row" spacing={2}>
        <TextField
          label="Start Date"
          type="date"
          value={editedCampaign.schedule.startDate.split('T')[0]}
          onChange={handleScheduleChange('startDate')}
          disabled={!isEditable || loading}
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          label="End Date"
          type="date"
          value={editedCampaign.schedule.endDate.split('T')[0]}
          onChange={handleScheduleChange('endDate')}
          disabled={!isEditable || loading}
          fullWidth
          InputLabelProps={{ shrink: true }}
          required
        />
      </Stack>
    )}

<Stack direction="row" spacing={2}>
  <TextField
    label="Start Time *"
    type="time"
    value={editedCampaign.schedule.start.slice(0, 5)}
    onChange={handleScheduleChange('start')}
    InputLabelProps={{ shrink: true }}
    fullWidth
    error={!!fieldErrors.runTimeStart}
    helperText={
      fieldErrors.runTimeStart || 
      (editedCampaign.type === 'SMS' && editedCampaign.templateType === 'promotional' ? 
        'Campaign hours are restricted to 9 AM - 8:59 PM for promotional SMS' : 
        undefined)
    }
    inputProps={{
      step: 300,
      ...(editedCampaign.type === 'SMS' && editedCampaign.templateType === 'promotional' ? {
        min: '09:00',
        max: '20:59'
      } : {})
    }}
    disabled={!isEditable || loading}
    required
  />
  <TextField
    label="End Time *"
    type="time"
    value={editedCampaign.schedule.end.slice(0, 5)}
    onChange={handleScheduleChange('end')}
    InputLabelProps={{ shrink: true }}
    fullWidth
    error={!!fieldErrors.runTimeEnd}
    helperText={fieldErrors.runTimeEnd}
    inputProps={{
      step: 300,
      ...(editedCampaign.type === 'SMS' && editedCampaign.templateType === 'promotional' ? {
        min: '09:00',
        max: '20:59'
      } : {})
    }}
    disabled={!isEditable || loading}
    required
  />
</Stack>
    {typeof fieldErrors.schedule === 'string' && (
      <FormHelperText error>{fieldErrors.schedule}</FormHelperText>
    )}
  </Stack>
</Box>

{/* Continue with WhatsApp specific settings and rest of the form */}
<FormControlLabel
  control={
    <Checkbox
      checked={editedCampaign.allowDuplicate}
      onChange={(e) => handleFieldChange('allowDuplicate')({
        target: { value: e.target.checked.toString() }
      } as any)}
      disabled={!isEditable || loading}
    />
  }
  label="Allow Duplicate"
/>
</Stack>
</Grid>

{/* Right Column - Status Cards */}
<Grid item xs={12} md={4}>
  <Card>
    <CardContent>
      <Typography variant="h6" gutterBottom>
        Campaign Status
      </Typography>
      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Status</Typography>
          <Typography variant="body2" sx={{ 
            color: editedCampaign.position === 'running' ? 'success.main' : 'text.secondary',
            fontWeight: 500
          }}>
            {formatPosition(editedCampaign.position)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Created On</Typography>
          <Typography variant="body2">
            {formatDateTime(editedCampaign.createdOn)}
          </Typography>
        </Box>
        {editedCampaign.updatedOn && (
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">Last Modified</Typography>
            <Typography variant="body2">
              {formatDateTime(editedCampaign.updatedOn)}
            </Typography>
          </Box>
        )}
        <Divider />
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Campaign Type</Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {editedCampaign.type}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2">Category</Typography>
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {editedCampaign.category.charAt(0).toUpperCase() + editedCampaign.category.slice(1)}
          </Typography>
        </Box>
      </Stack>
    </CardContent>
  </Card>

  {/* WhatsApp Specific Card */}
  {editedCampaign.type === 'WhatsApp' && (
    <Card sx={{ mt: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          WhatsApp Settings
        </Typography>
        <TextField
          label="Callback URL"
          value={editedCampaign.callbackUrl || ''} 
          onChange={handleFieldChange('callbackUrl')}
          disabled={!isEditable || loading}
          error={!!fieldErrors.callbackUrl}
          helperText={fieldErrors.callbackUrl}
          size="small"
          fullWidth
          sx={{ mt: 2 }}
        />
      </CardContent>
    </Card>
  )}
</Grid>
</Grid>

{/* Action Buttons */}
<Box sx={{ 
  mt: 4, 
  pt: 3,
  display: 'flex', 
  justifyContent: 'flex-end', 
  gap: 2,
  borderTop: '1px solid #E5E7EB'
}}>
  <Button
    onClick={onClose}
    variant="outlined"
    disabled={loading}
    sx={{
      minWidth: 100,
      textTransform: 'none',
      borderColor: '#E5E7EB',
      color: '#666666',
      '&:hover': {
        borderColor: '#D1D5DB',
        backgroundColor: '#F9FAFB'
      }
    }}
  >
    Cancel
  </Button>
  <Button
    onClick={handleSave}
    variant="contained"
    disabled={!isEditable || loading}
    sx={{
      minWidth: 100,
      textTransform: 'none',
      bgcolor: '#4B87FF',
      '&:hover': {
        bgcolor: '#3D78E6'
      },
      '&.Mui-disabled': {
        bgcolor: '#E5E7EB',
        color: '#9CA3AF'
      }
    }}
  >
    {loading ? (
      <>
        <CircularProgress size={20} sx={{ mr: 1 }} />
        Saving...
      </>
    ) : 'Save Changes'}
  </Button>
</Box>

{/* Delete Confirmation Dialog */}
<Dialog
  open={showDeleteDialog}
  onClose={() => setShowDeleteDialog(false)}
  PaperProps={{
    sx: {
      width: '100%',
      maxWidth: '400px',
      borderRadius: '12px'
    }
  }}
>
  <DialogTitle sx={{ 
    p: 3,
    display: 'flex',
    alignItems: 'center',
    gap: 1
  }}>
    <AlertCircle size={24} color="#DC2626" />
    Delete Campaign
  </DialogTitle>
  <DialogContent sx={{ px: 3, pb: 3 }}>
    <Typography color="text.secondary">
      Are you sure you want to delete this campaign? This action cannot be undone.
    </Typography>
  </DialogContent>
  <Box sx={{ 
    p: 3, 
    display: 'flex', 
    justifyContent: 'flex-end', 
    gap: 1,
    borderTop: '1px solid #E5E7EB'
  }}>
    <Button
      onClick={() => setShowDeleteDialog(false)}
      variant="outlined"
      sx={{
        textTransform: 'none',
        borderColor: '#E5E7EB',
        color: '#666666',
        '&:hover': {
          borderColor: '#D1D5DB',
          backgroundColor: '#F9FAFB'
        }
      }}
    >
      Cancel
    </Button>
    <Button
      onClick={() => {
        setShowDeleteDialog(false);
        onDelete(editedCampaign.id.toString());
      }}
      variant="contained"
      color="error"
      sx={{
        textTransform: 'none',
        bgcolor: '#DC2626',
        '&:hover': {
          bgcolor: '#B91C1C'
        }
      }}
    >
      Delete Campaign
    </Button>
  </Box>
</Dialog>

{/* Menu for additional options */}
<Menu
  anchorEl={menuAnchorEl}
  open={Boolean(menuAnchorEl)}
  onClose={handleMenuClose}
  PaperProps={{
    sx: {
      minWidth: 180,
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
    }
  }}
>
  <MenuItem onClick={handleDeleteClick} disabled={editedCampaign.position === 'running'}>
    <Trash2 size={18} style={{ marginRight: 8 }} />
    Delete Campaign
  </MenuItem>
</Menu>
</Box>
);
};

export default CampaignEdit;