// templateApi.js
import axios from 'axios';
import { getAuthToken } from '../../../services/auth-service';

const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';

export const templateApi = {
  // SMS Templates API calls
  listSMSTemplates: async (userId, page = 0, size = 10) => {
    try {
      const response = await axios.get(`${BASE_URL}/smsTemplate/active`, {
        params: { page, size },
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch SMS templates:', error);
      throw error;
    }
  },

  createSMSTemplate: async (userId, templateData) => {
    try {
      const response = await axios.post(`${BASE_URL}/smsTemplate`, templateData, {
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to create SMS template:', error);
      throw error;
    }
  },

  updateSMSTemplate: async (userId, templateId, templateData) => {
    try {
      const response = await axios.put(`${BASE_URL}/smsTemplate/${templateId}`, templateData, {
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to update SMS template:', error);
      throw error;
    }
  },

  deleteSMSTemplate: async (userId, templateId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/smsTemplate/${templateId}`, {
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to delete SMS template:', error);
      throw error;
    }
  },

  // Sender API calls
  listSenders: async (userId, page = 0, size = 100) => {
    try {
      const response = await axios.get(`${BASE_URL}/sender/active`, {
        params: { page, size },
        headers: {
          userid: userId,
          Authorization: `Bearer ${getAuthToken()}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch senders:', error);
      throw error;
    }
  }
};