import React, { useState } from 'react';
import { Card } from '@mui/material';
import { Check, Clock, Image, AlertCircle, Camera } from 'lucide-react';

interface PreviewProps {
  templateData: string;
  variables: Record<string, any>;
  errors?: Record<string, string>;
}

const WhatsAppPreview = ({ templateData, variables, errors }: PreviewProps) => {
  const [imagePreview, setImagePreview] = useState<string | null>(null);

  // Parse template and handle potential JSON errors
  const getParsedTemplate = () => {
    try {
      return JSON.parse(templateData);
    } catch (e) {
      return [];
    }
  };

  const renderHeader = (header: any) => {
    if (!header) return null;

    // Handle image preview
    if (header.format === 'IMAGE') {
      const imageFile = variables['header_image'];
      if (imageFile instanceof File) {
        // Create URL for image preview
        if (!imagePreview) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string);
          };
          reader.readAsDataURL(imageFile);
        }
      }

      return (
        <div className="relative">
          {imagePreview ? (
            <div className="w-full h-48 relative rounded-t-lg overflow-hidden">
              <img 
                src={imagePreview} 
                alt="Header" 
                className="w-full h-full object-cover"
              />
            </div>
          ) : (
            <div className="w-full h-48 bg-gray-100 flex items-center justify-center rounded-t-lg">
              <Camera className="w-12 h-12 text-gray-400" />
            </div>
          )}
          {errors?.header && (
            <div className="absolute bottom-0 left-0 right-0 bg-red-500/80 text-white p-2 text-sm">
              <AlertCircle className="inline-block w-4 h-4 mr-1" />
              {errors.header}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const replaceVariables = (text: string, exampleVars: any) => {
    let processedText = text;
    if (exampleVars?.body_text?.[0]) {
      exampleVars.body_text[0].forEach((value: string, index: number) => {
        const varName = `body_${index + 1}`;
        const variableValue = variables[varName] || value || `{{${index + 1}}}`;
        processedText = processedText.replace(`{{${index + 1}}}`, variableValue);
      });
    }
    return processedText;
  };

  const template = getParsedTemplate();
  const headerSection = template.find((section: any) => section.type === 'HEADER');
  const bodySection = template.find((section: any) => section.type === 'BODY');
  const buttonSection = template.find((section: any) => section.type === 'BUTTONS');

  return (
    <div className="max-w-sm mx-auto">
      {/* WhatsApp UI Container */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-lg">
        {/* WhatsApp Header */}
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-green-500 rounded-full"></div>
            <span className="text-sm font-medium text-gray-700">WhatsApp Business</span>
          </div>
          <Clock className="w-4 h-4 text-gray-500" />
        </div>

        {/* Message Container */}
        <div className="bg-white rounded-lg overflow-hidden shadow-sm">
          {/* Header Media */}
          {renderHeader(headerSection)}

          {/* Message Content */}
          <div className="p-4 space-y-3">
            {bodySection && (
              <div className="relative">
                <p className="text-sm text-gray-800 whitespace-pre-wrap">
                  {replaceVariables(bodySection.text, bodySection.example)}
                </p>
                {errors?.body && (
                  <div className="mt-2 text-red-500 text-sm flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.body}
                  </div>
                )}
              </div>
            )}

            {/* Buttons */}
            {buttonSection?.buttons && (
              <div className="space-y-2 mt-4 border-t pt-3">
                {buttonSection.buttons.map((button: any, index: number) => (
                  <button
                    key={index}
                    className="w-full py-2 text-center bg-gray-50 hover:bg-gray-100
                             text-blue-600 rounded text-sm font-medium
                             border border-gray-200 transition-colors"
                  >
                    {button.text}
                  </button>
                ))}
              </div>
            )}

            {/* Message Status */}
            <div className="flex justify-end items-center space-x-1 text-xs text-gray-400 mt-2">
              <span>12:00</span>
              <Check className="w-4 h-4" />
            </div>
          </div>
        </div>
      </div>

      {/* General Template Error */}
      {errors?.template && (
        <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg">
          <div className="flex items-center text-red-600">
            <AlertCircle className="w-5 h-5 mr-2" />
            <span className="text-sm font-medium">{errors.template}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppPreview;