// constants.ts

export const CAMPAIGN_TYPES = ['SMS', 'WhatsApp'] as const;
export const MESSAGE_TYPES = ['Simple', 'Bulk'] as const;
export const WHATSAPP_TYPES = ['Transactional', 'Promotional'] as const;
export const SCHEDULE_TYPES = ['IMMEDIATE', 'SCHEDULED'] as const;
export const CAMPAIGN_STATUSES = ['DRAFT', 'RUNNING', 'PAUSED', 'COMPLETED', 'FAILED'] as const;

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export const FILE_UPLOAD_CONFIG = {
  MAX_SIZE: 5 * 1024 * 1024, // 5MB
  MIN_SIZE: 1024, // 1KB
  ALLOWED_TYPES: ['.csv', '.xlsx', '.xls'],
  MAX_SIMPLE_NUMBERS: 100,
  MAX_BULK_NUMBERS: 1000
};

export const CAMPAIGN_CONFIG = {
  MIN_DURATION: 30 * 60 * 1000, // 30 minutes in milliseconds
  MAX_DURATION: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
  NAME_MIN_LENGTH: 3,
  NAME_MAX_LENGTH: 50,
  NAME_PATTERN: /^[a-zA-Z][a-zA-Z0-9_]{2,49}$/
};

export const SENDER_ID_PATTERN = /^[A-Z]{6}$/;
export const PHONE_NUMBER_PATTERN = /^\+?[0-9]{8,12}$/;