// CampaignForm.tsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Card,
  CardContent,
  Typography,
  FormHelperText,
  CircularProgress,
  Checkbox,
  Alert,
  SelectChangeEvent,
  InputAdornment
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import { Download ,Search} from 'lucide-react';
import axios from 'axios';
import {  
  CampaignFormProps,  
  Template,  
  CampaignFormData,  
  Sender,  
  ValidationErrors,
  FormEventType,
  CategoryType,
  TemplateType,
  SMSTemplateType,
  validateWhatsAppVariables,
  SMSVendor,
  ApiResponse
} from './interfaces';
import { templateApi } from './templateApi';
import { senderApi } from './senderApi';
// Add to existing imports at the top of CampaignForm.tsx
import { whatsappApi, WhatsAppNumber, WhatsAppTemplate } from './whatsappApi';
import WhatsAppTemplateVariables from './WhatsAppTemplateVariables';
// Add this with your existing imports at the top
import WhatsAppPreview  from './WhatsAppPreview';  // Create this component in a separate file
import { CampaignType } from './type';
import { getAuthToken, getUserId } from '../../../services/auth-service';

// Constants
const SMS_TEMPLATE_TYPES: SMSTemplateType[] = [
  'promotional',
  'transactional',
  'service_implicit',
  'service_explicit'
];

// Component Interfaces
interface FormState {
  isSubmitting: boolean;
  hasSubmitted: boolean;
  submitError: string | null;
}

interface SMSCharacterCounterProps {
  text: string;
}

interface FileUploadSectionProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: CampaignFormData;
  getFieldError: (field: string) => string | undefined;
  isSubmitting: boolean;
}

interface ScheduleFieldsProps {
  formData: CampaignFormData;
  campaignType: CampaignType ;  
  onFormChange: (field: keyof CampaignFormData) => (event: FormEventType) => void;
  getFieldError: (field: string) => string | undefined;
  isSubmitting: boolean;
  isSchedulingEnabled: boolean;
  onScheduleStateChange: (checked: boolean) => void;
}



// Utility function for sample file download
const onDownload = () => {
  const link = document.createElement("a");
  link.download = `data_uploadSMS_sample.xlsx`;
  link.href = `${process.env.PUBLIC_URL}/sample_files/data_uploadSMS_sample.xlsx`;
  link.click();
};
// Helper Components
const SMSCharacterCounter: React.FC<SMSCharacterCounterProps> = ({ text }) => {
  const SMS_PART_LENGTH = 160;
  const characterCount = text?.length || 0;
  const parts = Math.ceil(characterCount / SMS_PART_LENGTH);

  return (
    <Box sx={{ 
      mt: 2, 
      p: 2, 
      bgcolor: '#f0f7ff', 
      borderRadius: 1,
      border: '1px solid #e0e7ff'
    }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        color: '#4B87FF',
        fontSize: '0.875rem'
      }}>
        <Typography component="span">Total characters are</Typography>
        <Box component="span" sx={{ px: 1, py: 0.5, bgcolor: '#e0e7ff', borderRadius: 1 }}>
          {characterCount}
        </Box>
        <Typography component="span">will be divided into</Typography>
        <Box component="span" sx={{ px: 1, py: 0.5, bgcolor: '#e0e7ff', borderRadius: 1 }}>
          {parts}
        </Box>
        <Typography component="span">parts</Typography>
      </Box>
    </Box>
  );
};

const createFormEvent = (value: any): React.ChangeEvent<HTMLInputElement> => ({
  target: {
    value,
    name: '',
    id: '',
    checked: false,
    files: null,
    type: 'text',
    defaultValue: '',
    defaultChecked: false,
    nodeType: 1,
    textContent: null
  },
  currentTarget: document.createElement('input'),
  nativeEvent: new Event('change'),
  bubbles: true,
  cancelable: true,
  defaultPrevented: false,
  eventPhase: Event.AT_TARGET,
  isTrusted: true,
  preventDefault: () => {},
  isDefaultPrevented: () => false,
  stopPropagation: () => {},
  isPropagationStopped: () => false,
  persist: () => {},
  timeStamp: Date.now(),
  type: 'change'
} as unknown as React.ChangeEvent<HTMLInputElement>);

const FileUploadSection: React.FC<FileUploadSectionProps> = ({ 
  onFileChange, 
  formData, 
  getFieldError, 
  isSubmitting 
}) => (
  <>
    <Box sx={{ mb: 2 }}>
      <Button
        onClick={onDownload}
        startIcon={<Download size={18} />}
        variant="outlined"
        size="small"
        sx={{
          textTransform: 'none',
          color: '#4B87FF',
          borderColor: '#4B87FF',
          '&:hover': {
            borderColor: '#3D78E6',
            backgroundColor: 'rgba(75, 135, 255, 0.04)'
          }
        }}
      >
        Download Sample Template
      </Button>
    </Box>
    <FormControl error={!!getFieldError('fileUpload')} fullWidth>
      <TextField
        label="Upload File *"
        value={formData.fileUpload?.name || ''}
        InputProps={{
          endAdornment: (
            <Button
              component="label"
              startIcon={<CloudUpload />}
              disabled={isSubmitting}
            >
              Upload
              <input
                type="file"
                hidden
                onChange={onFileChange}
                accept=".csv,.xlsx,.xls"
                disabled={isSubmitting}
              />
            </Button>
          ),
          readOnly: true
        }}
      />
      {getFieldError('fileUpload') && (
        <FormHelperText>{getFieldError('fileUpload')}</FormHelperText>
      )}
      <FormHelperText>
        Supported formats: CSV, Excel (.xlsx, .xls) - Max size: 5MB
      </FormHelperText>
    </FormControl>
  </>
);


const ScheduleFields: React.FC<ScheduleFieldsProps> = ({ 
  formData, 
  campaignType,  // Add this parameter
  onFormChange, 
  getFieldError,
  isSubmitting,
  isSchedulingEnabled,
  onScheduleStateChange
}) => (
  <Box sx={{ 
    p: 2, 
    bgcolor: '#F9FAFB', 
    borderRadius: 1,
    border: '1px solid #E5E7EB'
  }}>
    <Typography variant="subtitle2" sx={{ mb: 2 }}>Schedule Settings</Typography>
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSchedulingEnabled}
            onChange={(e) => onScheduleStateChange(e.target.checked)}
            disabled={isSubmitting}
          />
        }
        label="Enable Date Scheduling"
      />

      {isSchedulingEnabled && (
        <Stack direction="row" spacing={2}>
          <TextField
            label="Start Date *"
            type="date"
            value={formData.startDate || new Date().toISOString().split('T')[0]}
            onChange={onFormChange('startDate')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            error={!!getFieldError('startDate')}
            helperText={getFieldError('startDate')}
            disabled={!isSchedulingEnabled || isSubmitting}
            required
          />
          <TextField
            label="End Date *"
            type="date"
            value={formData.endDate || new Date().toISOString().split('T')[0]}
            onChange={onFormChange('endDate')}
            InputLabelProps={{ shrink: true }}
            fullWidth
            error={!!getFieldError('endDate')}
            helperText={getFieldError('endDate')}
            disabled={!isSchedulingEnabled || isSubmitting}
            required
          />
        </Stack>
      )}

      <Stack direction="row" spacing={2}>
        <TextField
          label="Start Time *"
          type="time"
          value={formData.runTimeStart}
          onChange={onFormChange('runTimeStart')}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={!!getFieldError('runTimeStart')}
          helperText={
            getFieldError('runTimeStart') || 
            (campaignType === 'SMS' && formData.templateType === 'promotional' ? 
              'Campaign hours are restricted to 9 AM - 8:59 PM for promotional SMS' : 
              undefined)
          }
          inputProps={{
            step: 300,
            ...(campaignType === 'SMS' && formData.templateType === 'promotional' ? {
              min: '09:00',
              max: '20:59'
            } : {})
          }}
          disabled={isSubmitting}
          required
        />
        <TextField
          label="End Time *"
          type="time"
          value={formData.runTimeEnd}
          onChange={onFormChange('runTimeEnd')}
          InputLabelProps={{ shrink: true }}
          fullWidth
          error={!!getFieldError('runTimeEnd')}
          helperText={getFieldError('runTimeEnd')}
          inputProps={{
            step: 300,
            ...(campaignType === 'SMS' && formData.templateType === 'promotional' ? {
              min: '09:00',
              max: '20:59'
            } : {})
          }}
          disabled={isSubmitting}
          required
        />
      </Stack>
    </Stack>
  </Box>
);

// Main CampaignForm Component
const CampaignForm: React.FC<CampaignFormProps> = ({
  type,
  formData,
  fieldErrors,
  setFieldErrors,
  onFormChange,
  onFileChange,
  onSubmit,
  onCancel,
  isSubmitting = false,
  fileUpload = null,
  whatsAppFile = null
}) => {
  // State management
  const defaultFormData: CampaignFormData = {
    campaignName: '',
    messageType: 'Simple',
    senderId: '',
    templateId: '',
    category: 'simple',
    templateType: 'promotional',
    schedule: true,
    startDate: new Date().toISOString().split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
    runTimeStart: '09:00',
    runTimeEnd: '20:59',
    fileUpload: null,
    whatsAppFile: null,
    allowDuplicate: false,
    templateText: '',
    templateName: '',
    isWhatsappFlow: false,
    numbers: '',
    extraData: {},
  };

  const [templates, setTemplates] = useState<Template[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<Template[]>([]);
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(false);
  const [templateError, setTemplateError] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [senders, setSenders] = useState<Sender[]>([]);
  const [loadingSenders, setLoadingSenders] = useState<boolean>(false);
  const [senderError, setSenderError] = useState<string | null>(null);

  const [whatsappNumbers, setWhatsappNumbers] = useState<WhatsAppNumber[]>([]);
  const [whatsappTemplates, setWhatsappTemplates] = useState<WhatsAppTemplate[]>([]);
  const [loadingWhatsappNumbers, setLoadingWhatsappNumbers] = useState(false);
  const [loadingWhatsappTemplates, setLoadingWhatsappTemplates] = useState(false);

  const [isSchedulingEnabled, setIsSchedulingEnabled] = useState(false);
  const [templateLanguage, setTemplateLanguage] = useState('');
  const [templateErrors, setTemplateErrors] = useState<Record<string, string>>({});

  const [smsVendors, setSmsVendors] = useState<SMSVendor[]>([]);
  const [loadingVendors, setLoadingVendors] = useState(false);

  const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';

  // Add state for scheduling

  const userId = getUserId() || '';

  // Usage with SMSVendor
type SMSVendorResponse = ApiResponse<SMSVendor[]>;

const fetchSMSVendors = async () => {
  try {
    setLoadingVendors(true);
    const response = await axios.get<ApiResponse<SMSVendor[]>>(
      `${BASE_URL}/smsVendor`,
      {
        headers: {
          userid: getUserId(),
          Authorization: `Bearer ${getAuthToken()}`
        }
      }
    );
    
    if (response.data.status === 'success' && response.data.data) {
      setSmsVendors(response.data.data);
    }
  } catch (error) {
    console.error('Failed to fetch SMS vendors:', error);
  } finally {
    setLoadingVendors(false);
  }
};

  // Handle schedule state change
  const handleScheduleStateChange = (checked: boolean) => {
    setIsSchedulingEnabled(checked);
    const currentDate = new Date().toISOString().split('T')[0];
    
    if (!checked) {
      // Reset dates when scheduling is disabled
      onFormChange('startDate')({
        target: { value: currentDate }
      } as React.ChangeEvent<HTMLInputElement>);
      
      onFormChange('endDate')({
        target: { value: currentDate }
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  const convertSenderResponse = (data: any): Sender => ({
    id: data.id,
    name: data.name,
    entityId: data.entityId || '',
    approved: Boolean(data.approved),
    approvedBy: data.approvedBy || 0,
    approvedOn: data.approvedOn || new Date().toISOString(),
    createdBy: data.createdBy || 0,
    createdOn: data.createdOn || new Date().toISOString(),
    updatedBy: data.updatedBy || 0,
    updatedOn: data.updatedOn || new Date().toISOString(),
    deleted: Boolean(data.deleted),
    deletedBy: data.deletedBy || 0,
    deletedOn: data.deletedOn || new Date().toISOString(),
    userId: data.userId || 0,
    username: data.username || ''
  });

  // Add useEffect to fetch vendors when form opens for SMS type
useEffect(() => {
  if (type === 'SMS') {
    fetchSMSVendors();
  }
}, [type]);
  
   // Add WhatsApp numbers fetching effect
   useEffect(() => {
    const fetchWhatsAppNumbers = async () => {
      if (type === 'WhatsApp') {
        try {
          setLoadingWhatsappNumbers(true);
          const response = await whatsappApi.listWhatsAppNumbers(userId);
          if (response.data?.content) {
            setWhatsappNumbers(response.data.content);
          }
        } catch (error) {
          console.error('Failed to fetch WhatsApp numbers:', error);
        } finally {
          setLoadingWhatsappNumbers(false);
        }
      }
    };

    fetchWhatsAppNumbers();
  }, [type, userId]);


  // Add WhatsApp number change handler
  const handleWhatsAppNumberChange = async (event: SelectChangeEvent<string>) => {
    const numberId = event.target.value;
    // Update senderId
    onFormChange('senderId')({
      target: { value: numberId },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);
  
    try {
      setLoadingWhatsappTemplates(true);
      if (userId) {
        const response = await whatsappApi.getWhatsAppTemplates(userId);
        if (response.data?.content) {
          // Store all templates without filtering
          setWhatsappTemplates(response.data.content);
        }
      }
    } catch (error) {
      console.error('Failed to fetch WhatsApp templates:', error);
    } finally {
      setLoadingWhatsappTemplates(false);
    }
  };
  

  // Effect for loading senders
  useEffect(() => {
    const fetchSenders = async () => {
      if (type === 'SMS') {
        try {
          setLoadingSenders(true);
          const response = await senderApi.listSenders(userId, 0, 100);
          if (response.data?.content) {
            const convertedSenders = response.data.content.map(convertSenderResponse);
            setSenders(convertedSenders);
          }
        } catch (error) {
          setSenderError('Failed to fetch senders');
          console.error(error);
        } finally {
          setLoadingSenders(false);
        }
      }
    };
  
    fetchSenders();
  }, [type, userId]);

  // Effect for loading templates
  useEffect(() => {
    const fetchTemplates = async () => {
      if (formData.senderId && type === 'SMS') {
        try {
          setLoadingTemplates(true);
          const response = await templateApi.listSMSTemplates(userId, 0, 100);
          
          // Find the selected sender to get its name
          const selectedSender = senders.find(s => s.id.toString() === formData.senderId);
          const senderName = selectedSender?.name;
  
          if (response.data?.content) {
            // Filter templates based on sender name
            const filtered = response.data.content.filter(
              (template: Template) => 
                template.sender === senderName && 
                template.approved && 
                !template.deleted
            );
            setFilteredTemplates(filtered);
            setTemplates(filtered);
          }
        } catch (error) {
          setTemplateError('Failed to fetch templates');
          console.error(error);
        } finally {
          setLoadingTemplates(false);
        }
      }
    };
  
    fetchTemplates();
  }, [formData.senderId, userId, type, senders]);

  // Helper functions
  const getFieldError = (fieldName: keyof ValidationErrors): string | undefined => {
    return fieldErrors[fieldName];
  };

  const handleSenderChange = (event: SelectChangeEvent<string>) => {
    const senderId = event.target.value;
    onFormChange('senderId')({
      target: { value: senderId },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);
    
    // Clear template selection when sender changes
    onFormChange('templateId')({
      target: { value: '' },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);
    setSelectedTemplate(null);
  };

  // Update the template selection handler
const handleTemplateChange = (event: SelectChangeEvent<string>) => {
  const templateId = event.target.value;
  const template = templates.find(t => t.id.toString() === templateId);
  
  if (template) {
    setSelectedTemplate(template);
    // Update template ID
    onFormChange('templateId')({
      target: { value: templateId },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);
    
    // Update template text
    onFormChange('templateText')({
      target: { value: template.text },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);

    // Update template type
    onFormChange('templateType')({
      target: { value: template.type },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);

    // Update template name
    onFormChange('templateName')({
      target: { value: template.name },
      currentTarget: event.currentTarget,
    } as React.ChangeEvent<HTMLInputElement>);
  }
};
  // Form rendering methods
  const renderSMSForm = () => (
    <Stack spacing={3}>
      <TextField
        label="Campaign Name *"
        value={formData.campaignName}
        onChange={onFormChange('campaignName')}
        error={!!getFieldError('campaignName')}
        helperText={getFieldError('campaignName')}
        fullWidth
        disabled={isSubmitting}
      />

      <FormControl fullWidth>
        <Typography component="legend" sx={{ mb: 1 }}>Message Type *</Typography>
        <RadioGroup
          row
          value={formData.messageType}
          onChange={onFormChange('messageType')}
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            gap: 2
          }}
        >
          <FormControlLabel value="Simple" control={<Radio />} label="Simple" />
          <FormControlLabel value="Bulk" control={<Radio />} label="Bulk" />
        </RadioGroup>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Category *</InputLabel>
        <Select
          value={formData.category}
          onChange={onFormChange('category')}
          label="Category"
        >
          <MenuItem value="simple">Simple</MenuItem>
          <MenuItem value="dynamic">Dynamic</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth error={Boolean(getFieldError('senderId'))}>
        <InputLabel>Select Sender ID *</InputLabel>
        <Select
          value={formData.senderId || ''}
          onChange={handleSenderChange}
          label="Select Sender ID *"
          disabled={loadingSenders || isSubmitting}
        >
          {loadingSenders ? (
            <MenuItem disabled>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Loading senders...
            </MenuItem>
          ) : (
            senders.map((sender) => (
              <MenuItem key={sender.id} value={sender.id.toString()}>
                {sender.name}
              </MenuItem>
            ))
          )}
        </Select>
        {getFieldError('senderId') && (
          <FormHelperText>{getFieldError('senderId')}</FormHelperText>
        )}
      </FormControl>
      <FormControl fullWidth error={Boolean(getFieldError('smsVendor'))}>
  <InputLabel>Select SMS Vendor *</InputLabel>
  <Select
    value={formData.smsVendor || ''}
    onChange={onFormChange('smsVendor')}
    label="Select SMS Vendor *"
    disabled={loadingVendors || isSubmitting}
  >
    {loadingVendors ? (
      <MenuItem disabled>
        <CircularProgress size={20} sx={{ mr: 1 }} />
        Loading vendors...
      </MenuItem>
    ) : (
      smsVendors.map((vendor) => (
        <MenuItem key={vendor.sms_provider} value={vendor.sms_provider}>
          {vendor.sms_provider}
        </MenuItem>
      ))
    )}
  </Select>
  {getFieldError('smsVendor') && (
    <FormHelperText>{getFieldError('smsVendor')}</FormHelperText>
  )}
</FormControl>

      <FormControl fullWidth error={Boolean(getFieldError('templateId'))}>
  <InputLabel>Select Template *</InputLabel>
  <Select
    value={formData.templateId || ''}
    onChange={handleTemplateChange}
    label="Select Template *"
    disabled={loadingTemplates || !formData.senderId || isSubmitting}
  >
    {loadingTemplates ? (
      <MenuItem disabled>
        <CircularProgress size={20} sx={{ mr: 1 }} />
        Loading templates...
      </MenuItem>
    ) : filteredTemplates.length === 0 ? (
      <MenuItem disabled>No templates available for this sender</MenuItem>
    ) : (
      filteredTemplates.map((template) => (
        <MenuItem key={template.id} value={template.id.toString()}>
          <Box>
            <Typography>{template.name}</Typography>
            <Typography variant="caption" color="textSecondary">
              ({template.type})
            </Typography>
          </Box>
        </MenuItem>
      ))
    )}
  </Select>
  {getFieldError('templateId') && (
    <FormHelperText>{getFieldError('templateId')}</FormHelperText>
  )}
</FormControl>

      {selectedTemplate && (
        <>
          <TextField
            label="Template Text *"
            value={formData.templateText || ''}
            onChange={onFormChange('templateText')}
            multiline
            rows={4}
            fullWidth
            disabled={formData.category === 'dynamic'}
            error={!!getFieldError('templateText')}
            helperText={
              getFieldError('templateText') ||
              (formData.category === 'dynamic' 
                ? "Dynamic templates cannot be edited" 
                : "You can modify this template text")
            }
          />
          <SMSCharacterCounter text={formData.templateText || ''} />
        </>
      )}


<ScheduleFields
  formData={formData}
  campaignType="SMS"  // Add this line
  onFormChange={onFormChange}
  getFieldError={getFieldError}
  isSubmitting={isSubmitting}
  isSchedulingEnabled={isSchedulingEnabled}
  onScheduleStateChange={handleScheduleStateChange}
/>

      {formData.messageType === 'Simple' ? (
        <TextField
          label="Phone Numbers (comma-separated) *"
          multiline
          rows={4}
          value={formData.numbers || ''}
          onChange={onFormChange('numbers')}
          error={!!getFieldError('numbers')}
          helperText={getFieldError('numbers') || 'Format: +919704665032,+919876543210'}
          fullWidth
          placeholder="+919704665032,+919876543210"
        />
      ) : (
        <FileUploadSection
          onFileChange={onFileChange}
          formData={formData}
          getFieldError={getFieldError}
          isSubmitting={isSubmitting}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={formData.allowDuplicate}
            onChange={(e) => 
              onFormChange('allowDuplicate')({
                target: { value: e.target.checked },
                currentTarget: e.currentTarget,
              } as any)
            }
            disabled={isSubmitting}
          />
        }
        label="Allow Duplicate"
      />
    </Stack>
  );

// Update the template selection handler for WhatsApp

const handleWhatsAppTemplateChange = (templateName: string) => {
  const template = whatsappTemplates.find(t => t.name === templateName);
  if (template) {
    // Update template info without validation
    onFormChange('templateId')({ target: { value: template.name } } as React.ChangeEvent<HTMLInputElement>);
    onFormChange('templateName')({ target: { value: template.name } } as React.ChangeEvent<HTMLInputElement>);
    onFormChange('templateType')({ target: { value: template.type } } as React.ChangeEvent<HTMLInputElement>);
    onFormChange('templateText')({ target: { value: template.text } } as React.ChangeEvent<HTMLInputElement>);
    onFormChange('templateLanguage')({ target: { value: template.language } } as React.ChangeEvent<HTMLInputElement>);
    
    // Clear any existing template errors when selecting a new template
    setTemplateErrors({});
  }
};

const handleVariableChange = (name: string, value: string | File) => {
  // Update the form data
  onFormChange('extraData')(createFormEvent({
    ...formData.extraData,
    [name]: value
  }));

  // Clear specific field error if it exists
  if (fieldErrors[name]) {
    setFieldErrors((prev: ValidationErrors) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  }

  // Only validate if the user has started filling in variables
  if (value) {
    const templateTextForValidation = typeof formData.templateText === 'object' 
      ? JSON.stringify(formData.templateText) 
      : formData.templateText;
      
    const errors = validateWhatsAppVariables(templateTextForValidation, {
      ...formData.extraData,
      [name]: value
    });
    setTemplateErrors(errors);
  }
};
  // In CampaignForm.tsx, update the renderWhatsAppForm function

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredWhatsappNumbers, setFilteredWhatsappNumbers] = useState(whatsappNumbers);

   // Update filtered numbers when search query changes
useEffect(() => {
  const filtered = whatsappNumbers.filter(num =>
    num.number.toLowerCase().includes(searchQuery.toLowerCase())
  );
  setFilteredWhatsappNumbers(filtered);
}, [searchQuery, whatsappNumbers]);

  const renderWhatsAppForm = () => {


    return (
      <Stack spacing={3}>
        <TextField
          label="Campaign Name *"
          value={formData.campaignName}
          onChange={onFormChange('campaignName')}
          error={!!getFieldError('campaignName')}
          helperText={getFieldError('campaignName')}
          fullWidth
          disabled={isSubmitting}
        />
  
        <FormControl fullWidth>
          <Typography component="legend" sx={{ mb: 1 }}>Message Type *</Typography>
          <RadioGroup
            row
            value={formData.messageType}
            onChange={onFormChange('messageType')}
            sx={{ 
              display: 'flex',
              flexDirection: 'row',
              gap: 2
            }}
          >
            <FormControlLabel value="Simple" control={<Radio />} label="Simple" />
            <FormControlLabel value="Bulk" control={<Radio />} label="Bulk" />
          </RadioGroup>
        </FormControl>
  
        <FormControl fullWidth>
          <InputLabel>Category *</InputLabel>
          <Select
            value={formData.category}
            onChange={onFormChange('category')}
            label="Category"
          >
            <MenuItem value="simple">Simple</MenuItem>
            <MenuItem value="dynamic">Dynamic</MenuItem>
          </Select>
        </FormControl>
  
    <FormControl fullWidth error={!!getFieldError('senderId')}>
        <InputLabel>WhatsApp Number *</InputLabel>
        <Select
          value={formData.senderId || ''}
          onChange={handleWhatsAppNumberChange}
          label="WhatsApp Number *"
          disabled={loadingWhatsappNumbers || isSubmitting}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 300
              }
            }
          }}
        >
          {/* Search TextField */}
          <MenuItem 
            dense
            disableRipple 
            style={{ 
              position: 'sticky', 
              top: 0, 
              backgroundColor: '#fff',
              zIndex: 1
            }}
          >
            <TextField
              size="small"
              fullWidth
              placeholder="Search numbers..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search size={18} />
                  </InputAdornment>
                )
              }}
            />
          </MenuItem>

          {/* Divider */}
          <MenuItem 
            disabled 
            dense
            style={{
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              padding: 0,
              margin: '4px 0'
            }}
          />

          {/* Numbers List */}
          {loadingWhatsappNumbers ? (
            <MenuItem disabled>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Loading numbers...
            </MenuItem>
          ) : filteredWhatsappNumbers.length === 0 ? (
            <MenuItem disabled>
              <Typography color="text.secondary">
                No numbers found
              </Typography>
            </MenuItem>
          ) : (
            filteredWhatsappNumbers.map((num) => (
              <MenuItem key={num.id} value={num.number}>
                {num.number}
              </MenuItem>
            ))
          )}
        </Select>
        {getFieldError('senderId') && (
          <FormHelperText>{getFieldError('senderId')}</FormHelperText>
        )}
      </FormControl>

<FormControl fullWidth error={!!getFieldError('templateId')}>
  <InputLabel>Template *</InputLabel>
  <Select
    value={formData.templateId || ''}
    onChange={(e) => handleWhatsAppTemplateChange(e.target.value)}
    label="Template *"
    disabled={loadingWhatsappTemplates || !formData.senderId}
  >
    {whatsappTemplates.map((template) => (
      <MenuItem 
        key={template.id} 
        value={template.name}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <Typography variant="subtitle2">{template.name}</Typography>
        <Typography variant="caption" color="textSecondary">
          Language: {template.language}, Type: {template.type}
        </Typography>
      </MenuItem>
    ))}
  </Select>
  {getFieldError('templateId') && (
    <FormHelperText error>{getFieldError('templateId')}</FormHelperText>
  )}
</FormControl>


{formData.templateId  && formData.category === 'simple' && (
  <>
    <TextField
      label="Template Language"
      value={formData.templateLanguage || ''}
      disabled
      fullWidth
    />

    {/* <TextField
      label="Template Text"
      value={formData.templateText || ''}
      multiline
      rows={4}
      fullWidth
      disabled
    /> */}
    {formData.templateId && formData.templateText && (
  <Box sx={{ mb: 3 }}>
  <Typography variant="h6" gutterBottom>Message Preview</Typography>
  {/* Use MUI's existing error styling */}
  {Object.keys(templateErrors).length > 0 && (
    <Alert 
      severity="error" 
      sx={{ 
        mb: 2,
        backgroundColor: '#FEE2E2',
        color: '#DC2626'
      }}
    >
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        Please fix the following errors:
      </Typography>
      {Object.entries(templateErrors).map(([field, error]) => (
        <Typography key={field} variant="caption" display="block" sx={{ mt: 0.5 }}>
          • {error}
        </Typography>
      ))}
    </Alert>
  )}
  <WhatsAppPreview 
    templateData={formData.templateText}
    variables={formData.extraData}
    errors={templateErrors}
  />
</Box>
)}

{whatsappTemplates.find(t => t.name === formData.templateId)?.variables && (
  <WhatsAppTemplateVariables
    variables={whatsappTemplates.find(t => t.name === formData.templateId)!.variables}
    onVariableChange={handleVariableChange}
    onFileChange={(file) => {
      // Here's where we handle the file upload for WhatsApp template variables
      if (file && (file.type.startsWith('image/') || file.type === 'application/pdf' || file.type === 'video/mp4')) {
        const event = createFormEvent(undefined);
        (event.target as any).files = [file];
        onFileChange(event);
      } else {
        setTemplateErrors({
          ...templateErrors,
          header: 'Please upload a valid image, PDF, or video file'
        });
      }
    }}
    extraData={formData.extraData}
    getFieldError={getFieldError}
    isSubmitting={isSubmitting}
  />
)}
  </>
)}
  
        {formData.messageType === 'Simple' ? (
          <TextField
            label="Phone Numbers (comma-separated) *"
            multiline
            rows={4}
            value={formData.numbers || ''}
            onChange={onFormChange('numbers')}
            error={!!getFieldError('numbers')}
            helperText={getFieldError('numbers') || 'Format: +919704665032,+919876543210'}
            fullWidth
            placeholder="+919704665032,+919876543210"
          />
        ) : (
          <FileUploadSection
            onFileChange={onFileChange}
            formData={formData}
            getFieldError={getFieldError}
            isSubmitting={isSubmitting}
          />
        )}
  
        <TextField
          label="Callback URL"
          value={formData.callbackUrl || ''}
          onChange={onFormChange('callbackUrl')}
          error={!!getFieldError('callbackUrl')}
          helperText={getFieldError('callbackUrl')}
          fullWidth
        />  

<ScheduleFields
  formData={formData}
  campaignType="WhatsApp"  // Add this line
  onFormChange={onFormChange}
  getFieldError={getFieldError}
  isSubmitting={isSubmitting}
  isSchedulingEnabled={isSchedulingEnabled}
  onScheduleStateChange={handleScheduleStateChange}
/>
        {isSchedulingEnabled && (
          <Box sx={{ 
            p: 2, 
            bgcolor: '#F9FAFB', 
            borderRadius: 1,
            border: '1px solid #E5E7EB'
          }}>
          </Box>
        )}
  
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.allowDuplicate}
              onChange={(e) => {
                onFormChange('allowDuplicate')({
                  target: { value: e.target.checked },
                  currentTarget: e.currentTarget
                } as unknown as React.ChangeEvent<HTMLInputElement>);
              }}
              disabled={isSubmitting}
            />
          }
          label="Allow Duplicate"
        />
      </Stack>
    );
  };

  return (
<Box 
  component="form" 
  onSubmit={(e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted'); // Add this for debugging

 // Add WhatsApp template validation
 if (type === 'WhatsApp' && formData.templateText) {
  let templateTextForValidation = formData.templateText;
  if (typeof formData.templateText === 'object') {
    templateTextForValidation = JSON.stringify(formData.templateText);
  }
  
  const errors = validateWhatsAppVariables(templateTextForValidation, formData.extraData);
  setTemplateErrors(errors);
  if (Object.keys(errors).length > 0) {
    return;
  }
}

    onSubmit(); // Make sure this is called
  }} 
  noValidate
  sx={{ p: 3 }}
>
      <Grid container spacing={3}>
        {/* Error Display */}
        <Grid item xs={12}>
    {(Object.keys(fieldErrors).length > 0 || senderError || templateError) && (
      <Alert 
        severity="error" 
        onClose={() => {
          setSenderError(null);
          setTemplateError(null);
          setFieldErrors({});
        }}
        sx={{
          backgroundColor: '#FEE2E2',
          color: '#DC2626',
          '& .MuiAlert-icon': {
            color: '#DC2626'
          }
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {fieldErrors.form || senderError || templateError || 'Please fix the validation errors below'}
        </Typography>
        {Object.entries(fieldErrors)
          .filter(([key]) => key !== 'form')
          .map(([field, error]) => (
            <Typography key={field} variant="caption" display="block" sx={{ mt: 0.5 }}>
              • {error}
            </Typography>
          ))
        }
      </Alert>
    )}
  </Grid>

        {/* Form Content */}
        <Grid item xs={12} md={8}>
        {type === 'SMS' ? renderSMSForm() : renderWhatsAppForm()}
        </Grid>

        {/* Status Card */}
        <Grid item xs={12} md={4}>
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Campaign Status
        </Typography>
        <Stack spacing={2}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">
              Total Numbers
            </Typography>
            <Typography variant="body2">
              {formData.messageType === 'Simple' 
                ? (formData.numbers?.split(',').length || 0)
                : (formData.fileUpload ? '...' : 0)}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  </Grid>
      </Grid>

      {/* Form Actions */}
      <Box sx={{ 
      mt: 4, 
      display: 'flex', 
      justifyContent: 'flex-end', 
      gap: 2 
    }}>
      <Button 
        onClick={onCancel}
        variant="outlined"
        disabled={isSubmitting}
        sx={{ 
          minWidth: 100,
          textTransform: 'none',
          '&.Mui-disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)'
          }
        }}
      >
        Cancel
      </Button>
      <Button 
        type="submit"
        variant="contained"
        disabled={isSubmitting}
        sx={{ 
          minWidth: 100,
          textTransform: 'none',
          backgroundColor: '#4B87FF',
          '&:hover': {
            backgroundColor: '#3D78E6'
          }
        }}
      >
        {isSubmitting ? (
          <>
            <CircularProgress size={20} sx={{ mr: 1 }} />
            Creating...
          </>
        ) : 'Create Campaign'}
      </Button>
    </Box>
    </Box>
  );
};

// Add PropTypes validation if needed
CampaignForm.defaultProps = {
  isSubmitting: false,
  templateType: undefined
};

export default CampaignForm;