import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  Switch,
  Alert,
  Snackbar,
  CircularProgress,
  Pagination,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import { Search, Edit, Trash2, Plus, X } from 'lucide-react';
import { styles } from './styles';
import { senderApi } from './senderApi';
import { Sender, FormDataType, Sender_Request } from './type';
import { getUserId } from '../../../services/auth-service';

const SenderManagement: React.FC = () => {
  // State Management
  const [senders, setSenders] = useState<Sender[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [editingSender, setEditingSender] = useState<Sender | null>(null);
  const [formData, setFormData] = useState<FormDataType>({
    name: '',
    entityId: ''
  });
    
  // Pagination state
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  // Add this to your state declarations
const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

const validateSenderForm = (data: FormDataType): { isValid: boolean; errors: Record<string, string> } => {
  const errors: Record<string, string> = {};
  
  if (!data.name) {
    errors.name = 'Sender ID is required';
  } else if (!/^[A-Z]{6}$/.test(data.name)) {
    errors.name = 'Sender ID must be 6 uppercase characters';
  }
  
  if (!data.entityId) {
    errors.entityId = 'Entity ID is required';
  }
  
  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
};


  //const userId = getUserId(); // Replace with actual user ID

  useEffect(() => {
    const userId = getUserId();
    if (userId === null) {
      setError('User ID not found');
      return;
    }
    fetchSenders(parseInt(userId));
  }, [page, rowsPerPage]);
  
  const fetchSenders = async (userId: number): Promise<void> => {
    try {
      setLoading(true);
      const response = await senderApi.listSenders(userId, page, rowsPerPage);
      if (response.data) {
        setSenders(response.data.content);
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
      }
      setError(null);
    } catch (err) {
      setError('Failed to fetch senders');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateClick = (): void => {
    setEditingSender(null);
    setFormData({
      name: '',
      entityId: ''
    });
    setDialogOpen(true);
  };

  const handleEditClick = (sender: Sender): void => {
    setEditingSender(sender);
    setFormData({
      name: sender.name,
      entityId: sender.entityId
    });
    setDialogOpen(true);
  };

  const handleCloseDialog = (): void => {
    setDialogOpen(false);
    setEditingSender(null);
    setFormData({
      name: '',
      entityId: ''
    });
    setError(null);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
  e.preventDefault();
  const userId = getUserId();
  if (userId === null) {
    setError('User ID not found');
    return;
  }

  try {
    setLoading(true);
    const senderRequest: Sender_Request = {
      name: formData.name,
      entityId: formData.entityId,
      approved: true
    };

    const parsedUserId = parseInt(userId);
    if (editingSender) {
      await senderApi.updateSender(parsedUserId, editingSender.id, senderRequest);
    } else {
      await senderApi.createSender(parsedUserId, senderRequest);
    }
    
    await fetchSenders(parsedUserId);
    handleCloseDialog();
    setError(null);
  } catch (err) {
    setError(editingSender ? 'Failed to update sender' : 'Failed to create sender');
    console.error(err);
  } finally {
    setLoading(false);
  }
};

const handleDelete = async (id: number): Promise<void> => {
  const userId = getUserId();
  if (userId === null) {
    setError('User ID not found');
    return;
  }

  if (window.confirm('Are you sure you want to delete this sender?')) {
    try {
      setLoading(true);
      const parsedUserId = parseInt(userId);
      await senderApi.deleteSender(parsedUserId, id);
      await fetchSenders(parsedUserId);
      setError(null);
    } catch (err) {
      setError('Failed to delete sender');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }
};

const handleStatusChange = async (id: number, currentStatus: boolean): Promise<void> => {
  const userId = getUserId();
  if (userId === null) {
    setError('User ID not found');
    return;
  }

  try {
    setLoading(true);
    const parsedUserId = parseInt(userId);
    
    // Call reject API when disabling, activate API when enabling
    if (currentStatus) {
      await senderApi.rejectSender(parsedUserId, id);
    } else {
      await senderApi.approveSender(parsedUserId, id);
    }
    
    await fetchSenders(parsedUserId);
    setError(null);
  } catch (err) {
    setError('Failed to toggle sender status');
    console.error(err);
  } finally {
    setLoading(false);
  }
};
// Update handleFormChange to clear errors
const handleFormChange = (field: keyof FormDataType) => 
  (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (field === 'name' && !/^[A-Z]{0,6}$/.test(value)) {
      return;
    }
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
    // Clear error when field changes
    if (fieldErrors[field]) {
      setFieldErrors(prev => {
        const updated = { ...prev };
        delete updated[field];
        return updated;
      });
    }
};

  const handlePageChange = (event: unknown, newPage: number): void => {
    setPage(newPage - 1); // MUI Pagination is 1-based, but our API is 0-based
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent<number>): void => {
    const newSize = parseInt(event.target.value.toString(), 10);
    setRowsPerPage(newSize);
    setPage(0); // Reset to first page when changing page size
  };

  const filteredSenders = senders.filter(sender =>
    sender.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    sender.entityId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Box sx={styles.container}>
      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          Senders
        </Typography>
        <Button
          variant="contained"
          startIcon={<Plus size={16} />}
          onClick={handleCreateClick}
          sx={styles.createButton}
          disabled={loading}
        >
          Create Sender
        </Button>
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Box sx={styles.searchContainer}>
          <Box sx={styles.listHeader}>
            <Typography sx={styles.listTitle}>
              List of Senders
              <span className="total">Total {totalElements}</span>
            </Typography>
          </Box>
          <Box sx={styles.searchControls}>
            <Box sx={{ position: 'relative' }}>
              <Search size={20} style={{ 
                position: 'absolute', 
                left: '12px', 
                top: '50%', 
                transform: 'translateY(-50%)',
                color: '#666666' 
              }} />
              <TextField
                size="small"
                placeholder="Search senders..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={styles.searchInput}
              />
            </Box>
          </Box>
        </Box>

        {loading ? (
          <Box sx={styles.loadingOverlay}>
            <CircularProgress />
          </Box>
        ) : senders.length === 0 ? (
          <Box sx={styles.emptyState}>
            <Typography variant="body1" sx={styles.emptyStateText}>
              No senders found
            </Typography>
          </Box>
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeaderCell}>Enable / Disable</TableCell>
                  <TableCell sx={styles.tableHeaderCell}>Sender ID</TableCell>
                  <TableCell sx={styles.tableHeaderCell}>Entity ID</TableCell>
                  <TableCell sx={styles.tableHeaderCell} align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSenders.map((sender) => (
                  <TableRow key={sender.id} hover>
                    <TableCell>
                      <Switch
                        checked={sender.approved}
                        onChange={() => handleStatusChange(sender.id, sender.approved)}
                        sx={styles.switch}
                        disabled={loading}
                      />
                    </TableCell>
                    <TableCell>{sender.name}</TableCell>
                    <TableCell>{sender.entityId}</TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() => handleEditClick(sender)}
                        sx={styles.actionButton}
                        disabled={loading}
                      >
                        <Edit size={16} />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDelete(sender.id)}
                        sx={styles.actionButton}
                        disabled={loading}
                      >
                        <Trash2 size={16} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box sx={styles.paginationContainer}>
              <Box sx={styles.rowsPerPage}>
                <Typography>Rows per page:</Typography>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  size="small"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                <Typography>
                  {page * rowsPerPage + 1}-
                  {Math.min((page + 1) * rowsPerPage, totalElements)} of {totalElements}
                </Typography>
              </Box>
              <Box sx={styles.pagination}>
                <Pagination
                  count={totalPages}
                  page={page + 1}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                />
              </Box>
            </Box>
          </>
        )}
      </TableContainer>

      <Dialog 
        open={dialogOpen} 
        onClose={handleCloseDialog}
        sx={styles.dialog}
      >
        <Box sx={styles.dialogHeader}>
          <Typography sx={styles.dialogTitle}>
            {editingSender ? 'Edit Sender' : 'Create Sender'}
          </Typography>
          <IconButton 
            onClick={handleCloseDialog}
            sx={styles.dialogCloseButton}
          >
            <X size={16} />
          </IconButton>
        </Box>

        <DialogContent sx={styles.dialogContent}>
          <form onSubmit={handleSubmit}>
            <Box sx={styles.dialogFormContent}>
              <FormControl fullWidth sx={styles.formControl}>
                <Typography>Sender ID</Typography>
                <TextField
  size="small"
  value={formData.name}
  onChange={handleFormChange('name')}
  required
  fullWidth
  inputProps={{
    maxLength: 6,
    pattern: '^[A-Z]{6}$',
    style: { textTransform: 'uppercase' }
  }}
  placeholder="e.g., ABCDEF"
  error={!!fieldErrors.name}
  helperText={fieldErrors.name || 'Sender ID must be 6 uppercase characters'}
  sx={styles.textField}
/>
              </FormControl>

              <FormControl fullWidth sx={styles.formControl}>
                <Typography>Entity ID</Typography>
                <TextField
                  size="small"
                  value={formData.entityId}
                  onChange={handleFormChange('entityId')}
                  required
                  fullWidth
                  placeholder="Enter Entity ID"
                  sx={styles.textField}
                />
              </FormControl>
            </Box>

            <Box sx={styles.dialogFooter}>
              <Button
                onClick={handleCloseDialog}
                variant="outlined"
                sx={styles.actionButton}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={styles.continueButton}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : editingSender ? (
                  'Update'
                ) : (
                  'Create'
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SenderManagement;