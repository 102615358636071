// senderApi.ts
import axios from 'axios';
import { getAuthToken } from '../../../services/auth-service';
import { ApiResponse, Page, Sender, Sender_Request } from './type';

const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';

export const senderApi = {
  // List all senders with pagination
  listSenders: async (userId: number | any, page: number = 0, size: number = 10) => {
    try {
      const response = await axios.get<ApiResponse<Page<Sender>>>(`${BASE_URL}/sender/active`, {
        params: { page, size },
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch senders');
    }
  },

  // Create new sender
  createSender: async (userId: number, senderData: Sender_Request) => {
    try {
      const response = await axios.post<ApiResponse<Sender>>(`${BASE_URL}/sender`, senderData, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to create sender');
    }
  },

  // Update sender
  updateSender: async (userId: number, senderId: number, senderData: Sender_Request) => {
    try {
      const response = await axios.put<ApiResponse<Sender>>(`${BASE_URL}/sender/${senderId}`, senderData, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to update sender');
    }
  },

  // Delete sender
  deleteSender: async (userId: number, senderId: number) => {
    try {
      const response = await axios.delete<ApiResponse<any>>(`${BASE_URL}/sender/${senderId}`, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to delete sender');
    }
  },
  // Approve sender
  approveSender: async (userId: number, senderId: number) => {
    try {
      const response = await axios.post<ApiResponse<any>>(`${BASE_URL}/sender/approve/${senderId}`, null, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to approve sender');
    }
  },

  // Reject sender
  rejectSender: async (userId: number, senderId: number) => {
    try {
      const response = await axios.post<ApiResponse<any>>(`${BASE_URL}/sender/reject/${senderId}`, null, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to reject sender');
    }
  },

  // Activate sender
  activateSender: async (userId: number, senderId: number) => {
    try {
      const response = await axios.post<ApiResponse<any>>(`${BASE_URL}/sender/activate/${senderId}`, null, {
        headers: { 
          userid: userId, 
          Authorization: `Bearer ${getAuthToken()}` 
        }
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to activate sender');
    }
  }
};

export default senderApi;