// CXIReachContent.tsx
import React, { useState, useEffect,ChangeEvent,useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Chip,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  SelectChangeEvent,
  Dialog,
  DialogContent,
  Stack,
  FormControlLabel,
  Menu,
  FormGroup,
  Popover,
  DialogTitle,
  DialogActions,
  Breadcrumbs,
  Link,
  CircularProgress,
  Alert,
  Snackbar,
  InputLabel  // Add this import
} from '@mui/material';
import {
  Search,
  Download,
  Table as TableIcon,
  Filter,
  Edit,
  Copy,
  Trash2,
  MoreVertical,
  AlertCircle
} from 'lucide-react';
import {
  Message as MessageIcon,
  WhatsApp as WhatsAppIcon,
  Close as CloseIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';

import CampaignForm from './CampaignForm';
import CampaignEdit from './CampaignEdit';
import WhatsAppTemplatesContainer from './WhatsAppTemplatesContainer'
import { campaignApi } from './campaignApi';
import { styles } from './styles';
import {
  Campaign,
  CampaignType,
  convertApiType,
  convertToApiType,
  CampaignFormData,
  Campaign_Request,
  ValidationErrors,
  ColumnDef,
  FilterState,
  TabType,
  DownloadOption,
  Sender,
  PaginatedData , // Make sure this is exported from interfaces.ts
  Template,
  convertSenderData,
  AdvancedFilters,
  CategoryType,
  ApiCampaignType,
  TemplateType,  // Add this import
} from './interfaces';
import { convertCampaignType, convertSchedule, convertTemplateType } from './interfaces';
import { validateCampaign } from './validations';
import { CAMPAIGN_TYPES, DEFAULT_PAGE_SIZE, DEFAULT_ROWS_PER_PAGE_OPTIONS } from './constants';
import { senderApi } from './senderApi';
import { templateApi } from './templateApi';
import { whatsappApi } from './whatsappApi';
import { getUserId } from '../../../services/auth-service';

const initialFormData: CampaignFormData = {
  campaignName: '',
  messageType: 'Simple',
  senderId: '',
  templateId: '',
  category: 'simple',
  templateType: 'promotional',
  schedule: true,
  startDate: new Date().toISOString().split('T')[0], // Add this line
  endDate: new Date().toISOString().split('T')[0],   // Add this line
  runTimeStart: '09:00',
  runTimeEnd: '20:59',
  fileUpload: null,
  whatsAppFile:null,
  allowDuplicate: false,
  templateText: '',
  templateName: '',
  isWhatsappFlow: false,
  numbers: '',
  extraData: {},
};

const userId = getUserId();

type TemplateWithCategory = Omit<Template, 'category'> & {
  category: 'promotional' | 'transactional' | 'service_implicit' | 'service_explicit';
};



const getCategoryDisplay = (category: CategoryType): string => {
  return category.charAt(0).toUpperCase() + category.slice(1);
};
// Add these utility functions at the top
const createDefaultSchedule = () => ({
  scheduled: false,
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  start: '09:00:00',
  end: '20:59:00'
});

// Add this helper function at the beginning of CXIReachContent.tsx
const mapCampaignData = (data: any, template?: Template): Campaign => ({
  id: Number(data.id),
  name: data.name,
  campaignName: data.name,
  type: data.type?.toLowerCase() === 'whatsapp' ? 'WhatsApp' : 'SMS',
  category: data.category || 'simple',
  position: data.position || null,
  schedule: {
    scheduled: data.schedule?.scheduled ?? false,
    startDate: data.schedule?.startDate || new Date().toISOString(),
    endDate: data.schedule?.endDate || new Date().toISOString(),
    start: data.schedule?.start || '09:00:00',
    end: data.schedule?.end || '20:59:00'
  },
  sender: data.sender || '',
  senderId: data.senderId?.toString(),
  templateId: data.templateId?.toString(),
  templateType: data.templateType || template?.type || 'promotional', // Use data.templateType first
  templateName: data.templateName || template?.name || '',
  templateText: data.templateText || template?.text || '',
  bodyText: template?.text || data.templateText || '',
  headerText: data.headerText || '',
  footerText: data.footerText || '',
  did: data.did || '',
  approved: Boolean(data.approved),
  createdBy: Number(data.createdBy),
  createdOn: data.createdOn,
  updatedBy: data.updatedBy ? Number(data.updatedBy) : null,
  updatedOn: data.updatedOn,
  deleted: Boolean(data.deleted),
  deletedBy: data.deletedBy ? Number(data.deletedBy) : null,
  deletedOn: data.deletedOn || null,
  userId: Number(data.userId),
  userName: data.userName || '',
  username: data.username,
  allowDuplicate: Boolean(data.allowDuplicate),
  numbers: data.numbers,
  callbackUrl: data.callbackUrl || null,
  quartzJobId: data.quartzJobId || null,
  extraData: data.extraData || {},
  variables: template?.variables || data.variables || {},
  modifiedOn: data.updatedOn, // Set both updatedOn and modifiedOn
});

// Add these new components after your existing imports
interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  type?: 'primary' | 'danger';
  confirmText?: string;
  cancelText?: string;
}

interface UpdatedCampaignState {
  campaigns: Campaign[];
  loading: boolean;
  error: string | null;
  totalCount: number;
  paginationData: {
    totalElements: number;
    totalPages: number;
    first: boolean;
    last: boolean;
    size: number;
    number: number;
  };
}
interface CampaignEditableProps extends Campaign {
  availableSenders: Sender[];
  availableTemplates: Template[];
}
const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  onConfirm,
  onCancel,
  loading = false,
  type = 'primary',
  confirmText = 'Confirm',
  cancelText = 'Cancel'
}) => (
  <Dialog 
    open={open} 
    onClose={onCancel}
    PaperProps={{
      sx: {
        width: '100%',
        maxWidth: '400px',
        borderRadius: '12px',
        p: 1
      }
    }}
  >
    <DialogTitle sx={{ 
      p: 2,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: 600,
        color: type === 'danger' ? '#DC2626' : '#1A1A1A'
      }
    }}>
      {type === 'danger' && (
        <AlertCircle size={24} color="#DC2626" />
      )}
      {title}
    </DialogTitle>

    <DialogContent sx={{ p: 2 }}>
      <Typography sx={{ 
        color: '#666666',
        fontSize: '14px',
        lineHeight: '20px'
      }}>
        {message}
      </Typography>
    </DialogContent>

    <DialogActions sx={{ 
      p: 2,
      gap: 1
    }}>
      <Button
        onClick={onCancel}
        variant="outlined"
        disabled={loading}
        sx={{
          textTransform: 'none',
          minWidth: '80px',
          fontSize: '14px',
          fontWeight: 500,
          color: '#666666',
          borderColor: '#E6E6E6',
          '&:hover': {
            borderColor: '#D1D5DB',
            backgroundColor: '#F9FAFB'
          }
        }}
      >
        {cancelText}
      </Button>
      
      <Button
        onClick={onConfirm}
        variant="contained"
        disabled={loading}
        sx={{
          textTransform: 'none',
          minWidth: '80px',
          fontSize: '14px',
          fontWeight: 500,
          ...(type === 'primary' ? {
            bgcolor: '#4B87FF',
            '&:hover': {
              bgcolor: '#3D78E6'
            }
          } : {
            bgcolor: '#DC2626',
            '&:hover': {
              bgcolor: '#B91C1C'
            }
          })
        }}
      >
        {loading ? (
          <>
            <CircularProgress size={16} sx={{ mr: 1 }} />
            {type === 'danger' ? 'Deleting...' : 'Processing...'}
          </>
        ) : (
          confirmText
        )}
      </Button>
    </DialogActions>
  </Dialog>
);

const StartStopConfirmation = ({ 
  open, 
  onClose, 
  onConfirm, 
  loading 
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}) => (
  <ConfirmationDialog
    open={open}
    title="Start/Stop Campaign Confirmation"
    message="Are you sure you want to change the Start/Stop State?"
    onConfirm={onConfirm}
    onCancel={onClose}
    loading={loading}
    type="primary"
  />
);

const DeleteCampaignConfirmation = ({ 
  open, 
  onClose, 
  onConfirm, 
  loading 
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}) => (
  <ConfirmationDialog
    open={open}
    title="Delete Campaign"
    message="Are you sure you want to delete this campaign? This action cannot be undone."
    onConfirm={onConfirm}
    onCancel={onClose}
    loading={loading}
    type="danger"
    confirmText="Delete"
  />
);

// Empty State Component
const EmptyState: React.FC = () => (
  <Box sx={styles.emptyState}>
    <Typography sx={styles.emptyText}>
      {/* No data to display */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ height: '160px', width: '160px', backgroundColor: '#EAECF0', borderRadius: '50%' }}>
                    <svg width="160" height="160" viewBox="0 0 160 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M80 160C124.183 160 160 124.183 160 80C160 35.8172 124.183 0 80 0C35.8172 0 0 35.8172 0 80C0 124.183 35.8172 160 80 160Z" fill="#EAECF0" />
                        <path d="M128 160H32V56.5335C36.5248 56.5284 40.8628 54.7287 44.0623 51.5292C47.2619 48.3296 49.0616 43.9916 49.0667 39.4668H110.933C110.929 41.7083 111.368 43.9284 112.227 45.9988C113.086 48.0692 114.347 49.9488 115.937 51.5286C117.517 53.1192 119.397 54.3805 121.467 55.2396C123.538 56.0987 125.758 56.5384 128 56.5335V160Z" fill="white" />
                        <path d="M80.0004 108.8C94.1389 108.8 105.6 97.3381 105.6 83.1996C105.6 69.0611 94.1389 57.5996 80.0004 57.5996C65.8619 57.5996 54.4004 69.0611 54.4004 83.1996C54.4004 97.3381 65.8619 108.8 80.0004 108.8Z" fill="#98A2B3" />
                        <path d="M92.0677 74.1492L83.0168 83.2002L92.0677 92.2512L89.0507 95.2682L79.9998 86.2172L70.9488 95.2682L67.9318 92.2512L76.9828 83.2002L67.9318 74.1492L70.9488 71.1322L79.9998 80.1832L89.0507 71.1322L92.0677 74.1492Z" fill="white" />
                        <path d="M93.8669 115.2H66.1336C64.3663 115.2 62.9336 116.633 62.9336 118.4C62.9336 120.168 64.3663 121.6 66.1336 121.6H93.8669C95.6342 121.6 97.0669 120.168 97.0669 118.4C97.0669 116.633 95.6342 115.2 93.8669 115.2Z" fill="#EAECF0" />
                        <path d="M103.467 128H56.534C54.7667 128 53.334 129.433 53.334 131.2C53.334 132.967 54.7667 134.4 56.534 134.4H103.467C105.235 134.4 106.667 132.967 106.667 131.2C106.667 129.433 105.235 128 103.467 128Z" fill="#EAECF0" />
                    </svg>

                </div>
                <Typography fontWeight={600} fontSize='24px' color='#212121' mt={'16px'} >No data to display</Typography>
            </div>
    </Typography>
  </Box>
);
// Add this utility function at the top level of CXIReachContent.tsx
const processScheduleTime = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  
  // Ensure hours are between 9 and 21
  let processedHours = Math.min(Math.max(hours, 9), 21);
  
  // If hours is 21, minutes must be 0
  let processedMinutes = processedHours === 21 ? 0 : minutes;
  
  return `${String(processedHours).padStart(2, '0')}:${String(processedMinutes).padStart(2, '0')}:00`;
};
const CXIReachContent: React.FC = () => {
  const [allCampaigns, setAllCampaigns] = useState<Campaign[]>([]);
  // Basic state management
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState<TabType['id']>('all');
  const [fieldErrors, setFieldErrors] = useState<ValidationErrors>({});
  // Update editCampaign state type
  const [editCampaign, setEditCampaign] = useState<CampaignEditableProps | null>(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [senders, setSenders] = useState<Sender[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  // API integration state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [totalCount, setTotalCount] = useState(0);
// Update these state variables in CXIReachContent
const [paginationData, setPaginationData] = useState({
  totalElements: 0,
  totalPages: 0,
  first: true,
  last: true,
  size: DEFAULT_PAGE_SIZE,
  number: 0
});

  const userId = getUserId();

  // Menu anchors
  const [columnMenuAnchor, setColumnMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState<null | HTMLElement>(null);

  // Campaign creation state
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [showCampaignForm, setShowCampaignForm] = useState(false);
  const [selectedCampaignType, setSelectedCampaignType] = useState<CampaignType | null>(null);
  const [formData, setFormData] = useState<CampaignFormData>({
    ...initialFormData,
    extraData: {}
  });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState<string | null>(null); 

  const [templateMap, setTemplateMap] = useState<Map<number, Template>>(new Map());

// Update the initial state
const [advancedFilters, setAdvancedFilters] = useState<AdvancedFilters>({
  category: '',
  position: ''
});

  const [startStopDialogOpen, setStartStopDialogOpen] = useState(false);
  const [campaignToToggle, setCampaignToToggle] = useState<string | null>(null);

  // Column definition state
  const [columns, setColumns] = useState<ColumnDef[]>([
    { id: 'stopStart', label: 'Stop / Start', visible: true },
    { id: 'campaignName', label: 'Campaign Name', visible: true },
    { id: 'type', label: 'Type', visible: true },
    { id: 'senderId', label: 'Sender ID/WA Number', visible: true },
    { id: 'template', label: 'Template/WA Type', visible: true },
    { id: 'fromDate', label: 'From Date', visible: true },
    { id: 'runTimeStart', label: 'RunTime Start', visible: true },
    { id: 'runTimeEnd', label: 'RunTime End', visible: true },
    { id: 'createdOn', label: 'Created On', visible: true },
    { id: 'modifiedOn', label: 'Modified On', visible: true },
    { id: 'actions', label: 'Actions', visible: true }, // Add this line
  ]);

  // Configuration
  const tabs: TabType[] = [
    { id: 'SMS', label: 'SMS' },
    { id: 'WhatsApp', label: 'WhatsApp' },
    { id: 'all', label: 'All Campaigns' }
  ];

  const downloadOptions: DownloadOption[] = [
    { id: 'excel', label: 'Excel' },
    { id: 'csv', label: 'CSV' },
    { id: 'pdf', label: 'PDF' }
  ];

  // Filter state
  const [filters, setFilters] = useState<FilterState>({
    inBound: false,
    chat: false
  });

  // Add this at the start of the component
const fetchSenders = async () => {
  try {
    const response = await senderApi.listSenders(userId, 0, 100);
    if (response.data?.content) {
      const convertedSenders = response.data.content.map(convertSenderData);
      setSenders(convertedSenders);
    }
  } catch (err) {
    console.error('Failed to fetch senders:', err);
  }
};

  // API Integration - Data Fetching
  const fetchCampaigns = async () => {
    try {
      setLoading(true);
      
      const pageSize = rowsPerPage;
      const pageNumber = page;
      
      const response = await campaignApi.listCampaigns(userId, pageNumber, pageSize);
  
      if (response.status === 'success' && response.data) {
        const templatesResponse = await templateApi.listSMSTemplates(userId, 0, 100);
        const newTemplateMap = new Map();
        
        if (templatesResponse.data?.content) {
          templatesResponse.data.content.forEach((template: Template) => {
            newTemplateMap.set(template.id, template);
          });
          setTemplateMap(newTemplateMap);
        }
  
        // Map all campaigns first
        let allMappedCampaigns = response.data.content
          .filter(campaign => !campaign.deleted)
          .map(campaign => mapCampaignData(campaign, newTemplateMap.get(Number(campaign.templateId))));
  
        // Store all campaigns for reference
        setAllCampaigns(allMappedCampaigns);
  
        // Apply filters
        let filteredCampaigns = [...allMappedCampaigns];
  
        // Apply tab filter
        if (activeTab !== 'all') {
          filteredCampaigns = filteredCampaigns.filter(campaign => 
            campaign.type.toLowerCase() === activeTab.toLowerCase()
          );
        }
  
        // Apply category filter
        if (advancedFilters.category) {
          filteredCampaigns = filteredCampaigns.filter(campaign => 
            campaign.category?.toLowerCase() === advancedFilters.category.toLowerCase()
          );
        }
  
        // Apply position filter
        if (advancedFilters.position) {
          filteredCampaigns = filteredCampaigns.filter(campaign => {
            const campaignPosition = (campaign.position || 'stopped').toLowerCase();
            return campaignPosition === advancedFilters.position.toLowerCase();
          });
        }
  
        // Apply search filter
        if (searchQuery) {
          filteredCampaigns = filteredCampaigns.filter(campaign => {
            const searchableText = [
              campaign.campaignName,
              campaign.type,
              campaign.category,
              campaign.sender,
              campaign.templateName,
              campaign.position || 'stopped'
            ].map(field => String(field || '').toLowerCase()).join(' ');
            
            return searchableText.includes(searchQuery.toLowerCase());
          });
        }
  
        // Calculate pagination for filtered results
        const totalElements = filteredCampaigns.length;
        const totalPages = Math.ceil(totalElements / pageSize);
        const startIndex = pageNumber * pageSize;
        const endIndex = startIndex + pageSize;
        
        // Get current page of filtered results
        const paginatedCampaigns = filteredCampaigns.slice(startIndex, endIndex);
  
        // Update state with filtered and paginated results
        setCampaigns(paginatedCampaigns);
        setPaginationData({
          totalElements: totalElements, // This will now show the count of filtered results
          totalPages: totalPages,
          first: pageNumber === 0,
          last: pageNumber >= totalPages - 1,
          size: pageSize,
          number: pageNumber
        });
  
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      const errorMsg = error instanceof Error ? error.message : 'Failed to fetch campaigns';
      setError(errorMsg);
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  };


const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
  const query = event.target.value.toLowerCase();
  setSearchQuery(query);
  setPage(0); // Reset to first page when searching
  // The useEffect will trigger fetchCampaigns with new search params
};

const handleColumnToggle = (columnId: string) => {
  setColumns(prevColumns =>
    prevColumns.map(col =>
      col.id === columnId ? { ...col, visible: !col.visible } : col
    )
  );
};
useEffect(() => {
  setAdvancedFilters({
    category: '',
    position: ''
  });
  setSearchQuery('');
}, [activeTab]); 

// Add this function inside your main component
const getDisplayCount = () => {
  const totalSMS = allCampaigns.filter(c => c.type.toLowerCase() === 'sms').length;
  const totalWhatsApp = allCampaigns.filter(c => c.type.toLowerCase() === 'whatsapp').length;
  const filteredCount = paginationData.totalElements;

  switch (activeTab) {
    case 'SMS':
      return `Total ${totalSMS} `;
    case 'WhatsApp':
      return `Total ${totalWhatsApp} `;
    default:
      return `Total ${filteredCount} Campaigns`;
  }
};

const applyFilters = useCallback(() => {
  let filteredResults = [...allCampaigns];

  // First apply tab filter
  if (activeTab !== 'all') {
    filteredResults = filteredResults.filter(campaign => 
      campaign.type.toLowerCase() === activeTab.toLowerCase()
    );
  }

  // Then apply category filter if present
  if (advancedFilters.category) {
    filteredResults = filteredResults.filter(campaign => 
      campaign.category.toLowerCase() === advancedFilters.category.toLowerCase()
    );
  }

  // Then apply position filter if present
  if (advancedFilters.position) {
    filteredResults = filteredResults.filter(campaign => {
      const campaignPosition = (campaign.position || 'stopped').toLowerCase();
      return campaignPosition === advancedFilters.position.toLowerCase();
    });
  }

  // Finally apply search filter if present
  if (searchQuery) {
    filteredResults = filteredResults.filter(campaign => {
      const searchableText = [
        campaign.campaignName,
        campaign.type,
        campaign.category,
        campaign.sender,
        campaign.templateName,
        campaign.position || 'stopped'
      ].map(field => String(field || '').toLowerCase()).join(' ');
      
      return searchableText.includes(searchQuery.toLowerCase());
    });
  }

  return filteredResults;
}, [allCampaigns, activeTab, advancedFilters, searchQuery]);



const handleAdvancedFilterChange = (filterType: keyof AdvancedFilters, value: string) => {
  const newFilters = {
    ...advancedFilters,
    [filterType]: value
  };

  // If clearing category, also clear position
  if (filterType === 'category' && !value) {
    newFilters.position = '';
  }

  setAdvancedFilters(newFilters);
  setPage(0); // Reset to first page when filters change
};

// Helper function to get available positions for selected category
const getAvailablePositions = useCallback((category: string) => {
  // First filter campaigns by current active tab
  let availableCampaigns = allCampaigns.filter(campaign => {
    if (activeTab !== 'all') {
      return campaign.type.toLowerCase() === activeTab.toLowerCase();
    }
    return true;
  });

  // Then filter by category if selected
  if (category) {
    availableCampaigns = availableCampaigns.filter(campaign => 
      campaign.category.toLowerCase() === category.toLowerCase()
    );
  }

  // Get unique positions
  const uniquePositions = Array.from(new Set(
    availableCampaigns.map(campaign => campaign.position || 'stopped')
  ));

  return uniquePositions.sort();
}, [allCampaigns, activeTab]);

useEffect(() => {
  const fetchData = async () => {
    await fetchSenders(); // Add this back to fetch senders

    const fetchAndFilterCampaigns = async () => {
    try {
      setLoading(true);
      
      // Calculate pagination parameters
      const pageSize = rowsPerPage;
      const pageNumber = page;
      
      // Make API call with pagination and filter parameters
      const response = await campaignApi.listCampaigns(userId, pageNumber, pageSize);

      if (response.status === 'success' && response.data) {
        const templatesResponse = await templateApi.listSMSTemplates(userId, 0, 100);
        const newTemplateMap = new Map();
        
        if (templatesResponse.data?.content) {
          templatesResponse.data.content.forEach((template: Template) => {
            newTemplateMap.set(template.id, template);
          });
          setTemplateMap(newTemplateMap);
        }

        // Map campaigns from API response
        const mappedCampaigns = response.data.content
          .filter(campaign => !campaign.deleted)
          .map(campaign => mapCampaignData(campaign, newTemplateMap.get(Number(campaign.templateId))));

        // Set all campaigns for reference
        setAllCampaigns(mappedCampaigns);

        // Apply filters to current page data
        const filteredCampaigns = mappedCampaigns.filter(campaign => {
          // Tab filter
          if (activeTab !== 'all' && campaign.type.toLowerCase() !== activeTab.toLowerCase()) {
            return false;
          }

          // Category filter
          if (advancedFilters.category && 
              campaign.category.toLowerCase() !== advancedFilters.category.toLowerCase()) {
            return false;
          }

          // Position filter
          if (advancedFilters.position) {
            const campaignPosition = (campaign.position || 'stopped').toLowerCase();
            if (campaignPosition !== advancedFilters.position.toLowerCase()) {
              return false;
            }
          }

          // Search filter
          if (searchQuery) {
            const searchableText = [
              campaign.campaignName,
              campaign.type,
              campaign.category,
              campaign.sender,
              campaign.templateName,
              campaign.position || 'stopped'
            ].map(field => String(field || '').toLowerCase()).join(' ');
            
            return searchableText.includes(searchQuery.toLowerCase());
          }

          return true;
        });

        // Update campaigns state with filtered data
        setCampaigns(filteredCampaigns);
        
        // Update pagination data from API response
        setPaginationData({
          totalElements: response.data.totalElements,
          totalPages: response.data.totalPages,
          first: response.data.first,
          last: response.data.last,
          size: response.data.size,
          number: response.data.number
        });

        setError(null);
      }
    } catch (error) {
      console.error('Error fetching campaigns:', error);
      const errorMsg = error instanceof Error ? error.message : 'Failed to fetch campaigns';
      setError(errorMsg);
      setCampaigns([]);
    } finally {
      setLoading(false);
    }
  };

  await fetchAndFilterCampaigns();
  };

  fetchData();
}, [page, rowsPerPage, activeTab, searchQuery, advancedFilters.category, advancedFilters.position]);

useEffect(() => {
  const fetchSenders = async () => {
    try {
      const response = await senderApi.listSenders(userId, 0, 100);
      if (response.data?.content) {
        const convertedSenders = response.data.content.map(sender => ({
          id: sender.id,
          name: sender.name,
          entityId: sender.entityId || '',
          approved: Boolean(sender.approved),
          approvedBy: sender.approvedBy || 0,
          approvedOn: sender.approvedOn || new Date().toISOString(),
          createdBy: sender.createdBy || 0,
          createdOn: sender.createdOn || new Date().toISOString(),
          updatedBy: sender.updatedBy || 0,
          updatedOn: sender.updatedOn || new Date().toISOString(),
          deleted: Boolean(sender.deleted),
          deletedBy: sender.deletedBy || 0,
          deletedOn: sender.deletedOn || new Date().toISOString(),
          userId: sender.userId || 0,
          username: sender.username || ''
        }));
        setSenders(convertedSenders);
      }
    } catch (err) {
      console.error('Failed to fetch senders:', err);
    }
  };

  fetchSenders();
}, [userId]);

  // Add this function to find sender name
  const getSenderName = (senderId: string) => {
    const sender = senders.find(s => s.id.toString() === senderId);
    return sender ? sender.name : senderId;
  };
  
  const FilterMenu = () => {
    const currentType = activeTab as CampaignType;
    const availablePositions = getAvailablePositions(advancedFilters.category);
    
    return (
      <Popover
        open={filterMenuOpen}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: 320,
            p: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
          }
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 2 }}>Filter Campaigns</Typography>
        <Stack spacing={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Category</InputLabel>
            <Select
              value={advancedFilters.category}
              onChange={(e) => handleAdvancedFilterChange('category', e.target.value)}
              label="Category"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="simple">Simple</MenuItem>
              <MenuItem value="dynamic">Dynamic</MenuItem>
            </Select>
          </FormControl>
  
          <FormControl 
            fullWidth 
            size="small"
            disabled={!advancedFilters.category} // Disable if no category selected
          >
            <InputLabel>Position</InputLabel>
            <Select
              value={advancedFilters.position}
              onChange={(e) => handleAdvancedFilterChange('position', e.target.value)}
              label="Position"
            >
              <MenuItem value="">All</MenuItem>
              {availablePositions.map((position) => (
                <MenuItem 
                  key={position} 
                  value={position}
                >
                  {position.charAt(0).toUpperCase() + position.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
  
        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            setAdvancedFilters({ 
              category: '', 
              position: ''
            });
            setFilterAnchorEl(null);
          }}
          sx={{ mt: 2 }}
        >
          Clear Filters
        </Button>
      </Popover>
    );
  };
  
  const ColumnManagementDialog: React.FC<{
    open: boolean;
    onClose: () => void;
    columns: ColumnDef[];
    onColumnToggle: (id: string) => void;
  }> = ({ open, onClose, columns, onColumnToggle }) => (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #E5E7EB',
        p: 2
      }}>
        <Typography variant="h6">Manage Columns</Typography>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
  
      <DialogContent sx={{ p: 3 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>Available Columns</Typography>
        <Stack spacing={1}>
          {columns.map((column) => (
            <Box
              key={column.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                p: 1,
                borderBottom: '1px solid #E5E7EB'
              }}
            >
              <DragIndicatorIcon sx={{ color: 'grey.500', mr: 1 }} />
              <Checkbox
                checked={column.visible}
                onChange={() => onColumnToggle(column.id)}
              />
              <Typography>{column.label}</Typography>
            </Box>
          ))}
        </Stack>
        <FormControlLabel
          control={<Checkbox />}
          label="Save as Preset"
          sx={{ mt: 2 }}
        />
      </DialogContent>
  
      {/* <DialogActions sx={{ p: 2, borderTop: '1px solid #E5E7EB' }}>
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onClose} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions> */}
    </Dialog>
  );
  



  useEffect(() => {
    if (selectedCampaignType && convertToApiType(selectedCampaignType) === 'sms') {
      fetchSenders();
    }
  }, [selectedCampaignType]);

  const handleCampaignClick = async (campaign: Campaign) => {
    try {
      setLoading(true);
      
      const campaignResponse = await campaignApi.getCampaign(userId, campaign.id);
      
      if (campaignResponse.status !== 'success' || !campaignResponse.data) {
        throw new Error('Failed to fetch campaign details');
      }
  
      const campaignData = campaignResponse.data;
      const isWhatsApp = campaignData.type?.toLowerCase() === 'whatsapp';
      
      // Preserve original sender ID and sender name
      const originalSenderId = campaign.senderId;
      const originalSender = campaign.sender;
  
      if (!userId) {
        throw new Error('User ID is required');
      }
  
      if (isWhatsApp) {
        // For WhatsApp campaigns
        const [numbersResponse, templatesResponse] = await Promise.all([
          whatsappApi.listWhatsAppNumbers(userId),
          whatsappApi.getWhatsAppTemplates(userId)
        ]);
      
        const whatsappNumbers = numbersResponse.data?.content || [];
        const whatsappTemplates = templatesResponse.data?.content || [];
      
        // For WhatsApp, senderId and sender should be the same (the phone number)
        const senderNumber = originalSenderId || originalSender;
        
        // Find matching WhatsApp number from the list
        const matchingNumber = whatsappNumbers.find(n => n.number === senderNumber);
        
        // Match template by name and type
        const matchingTemplate = whatsappTemplates.find(t => 
          t.name === (campaignData.templateName || campaignData.templateId)
        );
      
        console.log('WhatsApp data matching:', {
          senderNumber,
          matchingNumber,
          template: {
            current: campaignData.templateId,
            matched: matchingTemplate?.name
          }
        });
      
        const editableCampaign: CampaignEditableProps = {
          ...mapCampaignData(campaignData),
          availableSenders: whatsappNumbers.map(num => ({
            id: Number(num.id),
            name: num.number,
            entityId: num.number,
            approved: true,
            approvedBy: 0,
            approvedOn: new Date().toISOString(),
            createdBy: 0,
            createdOn: new Date().toISOString(),
            updatedBy: 0,
            updatedOn: new Date().toISOString(),
            deleted: false,
            deletedBy: 0,
            deletedOn: new Date().toISOString(),
            userId: Number(userId),
            username: ''
          })),
          availableTemplates: whatsappTemplates.map(t => ({
            id: Number(t.id),
            name: t.name,
            text: t.text,
            type: t.type as TemplateType,
            sender: t.whatsAppNumber,
            templateId: t.name,
            category: 'simple',
            variables: t.variables || {},
            approved: t.approved,
            approvedBy: t.approvedBy,
            approvedOn: t.approvedOn,
            createdBy: t.createdBy,
            createdOn: t.createdOn,
            updatedBy: t.updatedBy || 0,
            updatedOn: t.updatedOn || new Date().toISOString(),
            deleted: t.deleted,
            deletedBy: t.deletedBy || 0,
            deletedOn: t.deletedOn || null,
            userId: t.userId,
            userName: t.userName
          })),
          templateId: matchingTemplate?.name || campaignData.templateId || '',
          templateName: matchingTemplate?.name || campaignData.templateName || '',
          templateText: matchingTemplate?.text || campaignData.templateText || '',
          templateType: (matchingTemplate?.type || campaignData.templateType || 'promotional') as TemplateType,
          sender: senderNumber || '',
          senderId: senderNumber || '',
          numbers: campaignData.numbers || '',
          category: campaignData.category || 'simple',
          position: campaignData.position || null,
          schedule: {
            scheduled: campaignData.schedule?.scheduled ?? true,
            startDate: campaignData.schedule?.startDate || new Date().toISOString(),
            endDate: campaignData.schedule?.endDate || new Date().toISOString(),
            start: campaignData.schedule?.start || '09:00:00',
            end: campaignData.schedule?.end || '20:59:00'
          },
          allowDuplicate: Boolean(campaignData.allowDuplicate),
          extraData: campaignData.extraData || {}
        };
  
        console.log('Setting WhatsApp campaign data:', editableCampaign);
        setEditCampaign(editableCampaign);
  
      } else {
        // For SMS campaigns
        const [sendersResponse, templatesResponse] = await Promise.all([
          senderApi.listSenders(userId, 0, 100),
          templateApi.listSMSTemplates(userId, 0, 100)
        ]);
  
        const sendersList = sendersResponse.data?.content
          ?.filter(sender => sender.approved && !sender.deleted)
          .map(convertSenderData) || [];
  
        const templatesList = templatesResponse.data?.content
          ?.filter((template: Template) => !template.deleted)
          .map((template: Template) => ({
            ...template,
            id: Number(template.id),
            text: template.text || '',
            type: template.type || 'promotional',
            name: template.name || `Template ${template.id}`,
            userId: Number(userId),
            userName: ''
          })) || [];
  
        // Find matching sender using both ID and name
        const matchingSender = sendersList.find(s => 
          s.id.toString() === originalSenderId || 
          s.name === originalSender
        );
  
        // Match template by ID
        const matchingTemplate = templatesList.find((template: Template) => 
          Number(template.id) === Number(campaignData.templateId)
        );
  
        console.log('SMS data matching:', {
          sender: {
            original: originalSender,
            matched: matchingSender?.name
          },
          template: {
            current: campaignData.templateId,
            matched: matchingTemplate?.id
          }
        });
  
        const editableCampaign: CampaignEditableProps = {
          ...mapCampaignData(campaignData, matchingTemplate),
          availableSenders: sendersList,
          availableTemplates: templatesList.filter((t: Template) => t.sender === matchingSender?.name),
          templateId: campaignData.templateId?.toString() || '',
          templateName: campaignData.templateName || matchingTemplate?.name || '',
          templateText: campaignData.templateText || matchingTemplate?.text || '',
          templateType: (campaignData.templateType || matchingTemplate?.type || 'promotional') as TemplateType,
          sender: matchingSender?.name || originalSender || '',
          senderId: matchingSender?.id.toString() || originalSenderId || '',
          category: campaignData.category || 'simple',
          position: campaignData.position || null,
          schedule: {
            scheduled: campaignData.schedule?.scheduled ?? true,
            startDate: campaignData.schedule?.startDate || new Date().toISOString(),
            endDate: campaignData.schedule?.endDate || new Date().toISOString(),
            start: campaignData.schedule?.start || '09:00:00',
            end: campaignData.schedule?.end || '20:59:00'
          },
          allowDuplicate: Boolean(campaignData.allowDuplicate),
          extraData: campaignData.extraData || {}
        };
  
        console.log('Setting SMS campaign data:', editableCampaign);
        setEditCampaign(editableCampaign);
      }
  
      setShowEditDialog(true);
  
    } catch (error) {
      console.error('Error in handleCampaignClick:', error);
      setError('Failed to fetch campaign details');
    } finally {
      setLoading(false);
    }
  };

  const handleEditClose = () => {
    setEditCampaign(null);
    setShowEditDialog(false);
  };

// Updated handleCampaignSave function for CXIReachContent.tsx

const handleCampaignSave = async (updatedCampaign: Campaign) => {
  try {
    setLoading(true);
    const campaignRequest: Campaign_Request = {
      name: updatedCampaign.name,
      type: convertCampaignType(updatedCampaign.type),
      category: updatedCampaign.category,
      templateType: updatedCampaign.templateType,
      schedule: {
        scheduled: updatedCampaign.schedule.scheduled,
        startDate: updatedCampaign.schedule.startDate,
        endDate: updatedCampaign.schedule.endDate,
        start: updatedCampaign.schedule.start,
        end: updatedCampaign.schedule.end
      },
      sender: updatedCampaign.sender,
      vendor: selectedCampaignType === 'SMS' ? formData.smsVendor : undefined,
      templateName: updatedCampaign.templateName,
      templateText: updatedCampaign.templateText,
      templateLanguage: updatedCampaign.templateLanguage,
      allowDuplicate: updatedCampaign.allowDuplicate,
      extraData: updatedCampaign.extraData || {}
    };

    const response = await campaignApi.updateCampaign(
      userId, 
      updatedCampaign.id, 
      campaignRequest
    );

    if (response.status === 'success') {
      // Fetch campaigns with current pagination and filter settings
      const pageSize = rowsPerPage;
      const pageNumber = page;
      const campaignResponse = await campaignApi.listCampaigns(userId, pageNumber, pageSize);

      if (campaignResponse.status === 'success' && campaignResponse.data) {
        const templatesResponse = await templateApi.listSMSTemplates(userId, 0, 100);
        const newTemplateMap = new Map();
        
        if (templatesResponse.data?.content) {
          templatesResponse.data.content.forEach((template: Template) => {
            newTemplateMap.set(template.id, template);
          });
          setTemplateMap(newTemplateMap);
        }

        // Map and filter campaigns
        let mappedCampaigns = campaignResponse.data.content
          .filter(campaign => !campaign.deleted)
          .map(campaign => mapCampaignData(campaign, newTemplateMap.get(Number(campaign.templateId))));

        // Apply current filters
        if (activeTab !== 'all') {
          mappedCampaigns = mappedCampaigns.filter(c => 
            c.type.toLowerCase() === activeTab.toLowerCase()
          );
        }

        if (advancedFilters.category) {
          mappedCampaigns = mappedCampaigns.filter(c => 
            c.category.toLowerCase() === advancedFilters.category.toLowerCase()
          );
        }

        if (advancedFilters.position) {
          mappedCampaigns = mappedCampaigns.filter(c => {
            const position = (c.position || 'stopped').toLowerCase();
            return position === advancedFilters.position.toLowerCase();
          });
        }

        if (searchQuery) {
          mappedCampaigns = mappedCampaigns.filter(c => {
            const searchableText = [
              c.campaignName,
              c.type,
              c.category,
              c.sender,
              c.templateName,
              c.position || 'stopped'
            ].map(field => String(field || '').toLowerCase()).join(' ');
            
            return searchableText.includes(searchQuery.toLowerCase());
          });
        }

        // Update both all campaigns and filtered campaigns
        setAllCampaigns(campaignResponse.data.content.map(c => 
          mapCampaignData(c, newTemplateMap.get(Number(c.templateId)))
        ));
        setCampaigns(mappedCampaigns);

        // Update pagination data
        setPaginationData({
          totalElements: mappedCampaigns.length,
          totalPages: Math.ceil(mappedCampaigns.length / pageSize),
          first: pageNumber === 0,
          last: pageNumber >= Math.ceil(mappedCampaigns.length / pageSize) - 1,
          size: pageSize,
          number: pageNumber
        });

        handleEditClose();
        setError(null);
        setSuccessMessage('Campaign updated successfully');
      }
    } else {
      setError(response.message || 'Failed to update campaign');
    }
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message || 'Failed to update campaign';
    setError(errorMessage);
    console.error('Update campaign error:', error);
  } finally {
    setLoading(false);
  }
};

  const handleCampaignCopy = async (campaign: Campaign) => {
  
  };

  const handleCampaignDelete = async (campaignId: string) => {
    try {
      setLoading(true);
      await campaignApi.deleteCampaign(userId, parseInt(campaignId));
      await fetchCampaigns();
      handleEditClose();
      setError(null);
    } catch (err) {
      setError('Failed to delete campaign');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Form Handlers
  const handleCreateDialogClose = () => {
    setCreateDialogOpen(false);
    setSelectedCampaignType(null);
    setShowCampaignForm(false);
    setFormData(initialFormData);
    setFieldErrors({});
  };

  const handleFormCancel = () => {
    setShowCampaignForm(false);
    setSelectedCampaignType(null);
    setFormData(initialFormData);
    setFieldErrors({});
  };

  const handleCampaignTypeSelect = (type: ApiCampaignType) => {
    const campaignType = type === 'whatsapp' ? 'WhatsApp' : 'SMS';
    setSelectedCampaignType(campaignType as CampaignType);
    setFormData(prev => ({
      ...prev,
      isWhatsappFlow: type === 'whatsapp'
    }));
  };

  const handleContinueToForm = () => {
    if (selectedCampaignType) {
      setCreateDialogOpen(false);
      setShowCampaignForm(true);
    }
  };

  const handleDeleteConfirmation = (campaignId: number) => {
    const campaign = campaigns.find(c => c.id === campaignId);
    if (campaign?.position === 'running') {
      setError('Cannot delete a running campaign. Please stop the campaign first.');
      return;
    }
    setCampaignToDelete(String(campaignId));
    setDeleteDialogOpen(true);
  };

  // Add this error handling utility
const handleApiError = (error: any) => {
  console.error('API Error:', error);
  const apiResponse = error.response?.data;
  
  if (apiResponse?.errors) {
    const fieldErrors: ValidationErrors = {};
    Object.entries(apiResponse.errors).forEach(([key, value]) => {
      const errorMessage = Array.isArray(value) ? value[0] : value;
      fieldErrors[key] = errorMessage;
    });
    setFieldErrors(fieldErrors);
    setError(apiResponse.message || 'Validation failed');
  } else {
    setError(error.message || 'Operation failed');
  }
};
  
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setCampaignToDelete(null);
  };
  
  const handleDeleteConfirm = async () => {
    if (!campaignToDelete) return;
    
    try {
      setLoading(true);
      await handleCampaignDelete(campaignToDelete);
      setDeleteDialogOpen(false);
      setCampaignToDelete(null);
      // Show success message
      // You can add a success snackbar here if desired
    } catch (err) {
      console.error('Failed to delete campaign:', err);
      setError('Failed to delete campaign');
    } finally {
      setLoading(false);
    }
  }; 

// Update the form change handler
const handleFormChange = (field: keyof CampaignFormData) => (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
) => {
  let value: string | boolean;
  
  // Handle different event types
  if ('name' in event.target) {
    value = event.target.value;
  } else {
    const target = event.target as HTMLInputElement;
    value = target.type === 'checkbox' ? target.checked : target.value;
  }
    
  setFormData(prev => ({ ...prev, [field]: value }));
  
  // Clear field-specific error when value changes
  if (fieldErrors[field]) {
    setFieldErrors(prev => {
      const { [field]: _, ...rest } = prev;
      return rest;
    });
  }
};

 // Add this to handleFileChange in CampaignForm.tsx
 const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  const file = event.target.files?.[0] || null;
  if (file) {
    const fileType = file.name.split('.').pop()?.toLowerCase();
    if (!['csv', 'xlsx', 'xls'].includes(fileType || '')) {
      setFieldErrors(prev => ({
        ...prev,
        fileUpload: 'Invalid file type. Allowed types: xlsx, xls, csv'
      }));
      return;
    }
    if (file.size > 5 * 1024 * 1024) { // 5MB limit
      setFieldErrors(prev => ({
        ...prev,
        fileUpload: 'File size exceeds 5MB limit'
      }));
      return;
    }
    setFormData(prev => ({ ...prev, fileUpload: file }));
    // Clear file upload error if it exists
    if (fieldErrors.fileUpload) {
      setFieldErrors(prev => {
        const { fileUpload, ...rest } = prev;
        return rest;
      });
    }
  }
};


// In CXIReachContent.tsx, update the handleCampaignSubmit function
const handleCampaignSubmit = async () => {
  if (loading) return;

  try {
    if (!selectedCampaignType) {
      setError('Please select a campaign type');
      return;
    }
    // Validate the form data before submission
    const validationResult = validateCampaign(formData, selectedCampaignType, senders);
    if (!validationResult.isValid) {
      setFieldErrors(validationResult.errors);
      // Find the first error message to display
      const firstError = Object.values(validationResult.errors)[0];
      setError(firstError || 'Please fix the validation errors');
      return;
    }

    setLoading(true);

    // Create FormData instance
    const formDataToSend = new FormData();
    const extraData: Record<string, any> = {};

    // Handle bulk data file upload for both SMS and WhatsApp
    if (formData.messageType === 'Bulk' && formData.fileUpload) {
      formDataToSend.append('file', formData.fileUpload);
    }

           // Handle WhatsApp template variables and file if category is simple
           if (selectedCampaignType === 'WhatsApp' && formData.category === 'simple') {
            // Process header variables - only one will be present at a time
            const headerTypes = ['header_image', 'header_video', 'header_document'];
            for (const type of headerTypes) {
              if (formData.extraData[type]) {
                if (formData.extraData[type] instanceof File) {
                  const file = formData.extraData[type] as File;
                  formDataToSend.append('whatsAppFile',file, file.name);
                }
                extraData[type] = ''; // Set empty string for header variable
                break; // Exit loop since only one header will be present
              }
            }
      
            // Process body variables
            Object.entries(formData.extraData).forEach(([key, value]) => {
              if (key.startsWith('body_')) {
                extraData[key] = value;
              }
            });
          }

    // Determine the sender based on campaign type
    let senderValue = '';
    if (selectedCampaignType === 'SMS') {
      const selectedSender = senders.find(s => s.id.toString() === formData.senderId);
      senderValue = selectedSender?.name || '';
    } else {
      senderValue = formData.senderId;
    }

    const campaignRequest: Campaign_Request = {
      name: formData.campaignName,
      type: convertToApiType(selectedCampaignType),
      category: formData.category,
      templateType: formData.templateType,
      schedule: {
        scheduled: true,
        startDate: formData.startDate,
        endDate: formData.endDate,
        start: `${formData.runTimeStart}:00`,
        end: `${formData.runTimeEnd}:00`
      },
      sender: senderValue,
      vendor: selectedCampaignType === 'SMS' ? formData.smsVendor : undefined,
      templateName: formData.templateName,
      templateText: formData.templateText,
      templateLanguage: formData.templateLanguage,
      allowDuplicate: formData.allowDuplicate,
      numbers: formData.messageType === 'Simple' ? formData.numbers : undefined,
      extraData: extraData  // Now includes both header files and body text
    };

    // Add campaign data as JSON
    formDataToSend.append('campaign', new Blob([JSON.stringify(campaignRequest)], {
      type: 'application/json'
    }));

    // Log the final request data for debugging
    console.log('Campaign Request:', campaignRequest);
    console.log('FormData entries:', [...formDataToSend.entries()]);

    // Send request with FormData containing both files if present
    const response = await campaignApi.createCampaign(userId, campaignRequest, formDataToSend);

    if (response?.status === 'success') {
      setSuccessMessage('Campaign created successfully');
      await fetchCampaigns();
      handleFormCancel();
    } else {
      throw new Error(response?.message || 'Failed to create campaign');
    }
  } catch (error: any) {
    console.error('Campaign creation error:', error);
    const errorMessage = error.response?.data?.message || error.message || 'Failed to create campaign';
    setError(errorMessage);
    if (error.response?.data?.errors) {
      setFieldErrors(error.response.data.errors);
    }
  } finally {
    setLoading(false);
  }
};

  // Menu Handlers
  const handleColumnMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setColumnMenuAnchor(event.currentTarget);
  };

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterMenuOpen(true);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterMenuOpen(false);
  };
  

  const handleDownloadClick = (event: React.MouseEvent<HTMLElement>) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleDownloadClose = () => {
    setDownloadAnchorEl(null);
  };

  const handleDownloadOption = (option: string) => {
    console.log(`Downloading as ${option}`);
    handleDownloadClose();
  };

  // Filter Handlers
  const handleFilterChange = (filterName: keyof FilterState) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
  };

  const handleTabChange = (newTab: TabType['id']) => {
    setActiveTab(newTab);
    setSearchQuery('');
    setAdvancedFilters({ category: '', position: '' });
    
    // Filter existing campaigns based on new tab
    const filteredCampaigns = allCampaigns.filter(campaign => {
      if (newTab === 'all') return true;
      return campaign.type.toLowerCase() === newTab.toLowerCase();
    });
  
    setCampaigns(filteredCampaigns);
    setPaginationData(prev => ({
      ...prev,
      totalElements: filteredCampaigns.length,
      totalPages: Math.ceil(filteredCampaigns.length / rowsPerPage)
    }));
    setPage(0); // Reset to first page when changing tabs
  };

  const handleClearFilters = () => {
    setAdvancedFilters({
      category: '',
      position: '',
    });
    setSearchQuery('');
    
    // Reset to unfiltered state based on current tab
    const tabFilteredCampaigns = allCampaigns.filter(campaign => {
      if (activeTab === 'all') return true;
      return campaign.type.toLowerCase() === activeTab.toLowerCase();
    });
    
    setCampaigns(tabFilteredCampaigns);
    setPaginationData(prev => ({
      ...prev,
      totalElements: tabFilteredCampaigns.length,
      totalPages: Math.ceil(tabFilteredCampaigns.length / rowsPerPage)
    }));
    setPage(0);
    setFilterAnchorEl(null);
  };

  const resetFilters = () => {
    setFilters({
      inBound: false,
      chat: false
    });
  };

  // Pagination Handlers
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
    const newRowsPerPage = parseInt(event.target.value.toString(), 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page when changing rows per page
    // Don't call fetchCampaigns here - it will be triggered by useEffect
  };

  const handleStatusChange = (campaignId: number) => {
    const campaign = campaigns.find(c => c.id === campaignId);
    if (campaign) {
      setCampaignToToggle(String(campaignId));
      setStartStopDialogOpen(true);
    }
  };
  

  // Updated handleStatusConfirm function for CXIReachContent.tsx

const handleStatusConfirm = async () => {
  if (!campaignToToggle) return;
  
  try {
    const campaign = campaigns.find(c => c.id.toString() === campaignToToggle);
    if (!campaign) {
      setError('Campaign not found');
      return;
    }

    setLoading(true);
    
    const response = campaign.position === 'running' 
      ? await campaignApi.stopCampaign(userId, campaign.id)
      : await campaignApi.startCampaign(userId, campaign.id);
    
    if (response.status === 'success') {
      // Fetch campaigns with current pagination and filter settings
      const pageSize = rowsPerPage;
      const pageNumber = page;
      const campaignResponse = await campaignApi.listCampaigns(userId, pageNumber, pageSize);

      if (campaignResponse.status === 'success' && campaignResponse.data) {
        const templatesResponse = await templateApi.listSMSTemplates(userId, 0, 100);
        const newTemplateMap = new Map();
        
        if (templatesResponse.data?.content) {
          templatesResponse.data.content.forEach((template: Template) => {
            newTemplateMap.set(template.id, template);
          });
          setTemplateMap(newTemplateMap);
        }

        // Map and filter campaigns
        let mappedCampaigns = campaignResponse.data.content
          .filter(campaign => !campaign.deleted)
          .map(campaign => mapCampaignData(campaign, newTemplateMap.get(Number(campaign.templateId))));

        // Apply current filters
        if (activeTab !== 'all') {
          mappedCampaigns = mappedCampaigns.filter(c => 
            c.type.toLowerCase() === activeTab.toLowerCase()
          );
        }

        if (advancedFilters.category) {
          mappedCampaigns = mappedCampaigns.filter(c => 
            c.category.toLowerCase() === advancedFilters.category.toLowerCase()
          );
        }

        if (advancedFilters.position) {
          mappedCampaigns = mappedCampaigns.filter(c => {
            const position = (c.position || 'stopped').toLowerCase();
            return position === advancedFilters.position.toLowerCase();
          });
        }

        if (searchQuery) {
          mappedCampaigns = mappedCampaigns.filter(c => {
            const searchableText = [
              c.campaignName,
              c.type,
              c.category,
              c.sender,
              c.templateName,
              c.position || 'stopped'
            ].map(field => String(field || '').toLowerCase()).join(' ');
            
            return searchableText.includes(searchQuery.toLowerCase());
          });
        }

        // Update both all campaigns and filtered campaigns
        setAllCampaigns(campaignResponse.data.content.map(c => 
          mapCampaignData(c, newTemplateMap.get(Number(c.templateId)))
        ));
        setCampaigns(mappedCampaigns);

        // Update pagination data
        setPaginationData({
          totalElements: mappedCampaigns.length,
          totalPages: Math.ceil(mappedCampaigns.length / pageSize),
          first: pageNumber === 0,
          last: pageNumber >= Math.ceil(mappedCampaigns.length / pageSize) - 1,
          size: pageSize,
          number: pageNumber
        });

        setStartStopDialogOpen(false);
        setCampaignToToggle(null);
        setError(null);
        setSuccessMessage(
          `Campaign ${campaign.position === 'running' ? 'stopped' : 'started'} successfully`
        );
      }
    } else {
      throw new Error(response.message || 'Failed to update campaign status');
    }
  } catch (error) {
    const errorMsg = error instanceof Error ? error.message : 'Failed to update campaign status';
    setError(errorMsg);
  } finally {
    setLoading(false);
  }
};


  // Cell Content Renderer
  const getCellContent = (campaign: Campaign, columnId: string): React.ReactNode => {
    switch (columnId) {
      case 'stopStart':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch 
              checked={campaign.position === 'running'}
              onChange={() => handleStatusChange(campaign.id)}
              size="small"
              disabled={campaign.deleted || loading || campaign.position === 'completed'}
            />
            <Typography variant="caption" sx={{ ml: 1 }}>
              {campaign.position ? 
                campaign.position.charAt(0).toUpperCase() + campaign.position.slice(1) 
                : 'Stopped'}
            </Typography>
            {loading && <CircularProgress size={16} sx={{ ml: 1 }} />}
          </Box>
        );
      case 'campaignName':
        return (
          <Typography
            color="primary"
            variant="body2"
            sx={{ cursor: 'pointer' }}
            onClick={() => handleCampaignClick(campaign)}
          >
            {campaign.campaignName}
          </Typography>
        );
  
        case 'type':
          return (
            <Chip 
              label={campaign.type.toUpperCase()}
              size="small"
              variant="outlined"
              sx={{ 
                height: '24px',
                backgroundColor: '#F3F4F6',
                borderColor: '#E5E7EB',
                color: '#374151',
                '& .MuiChip-label': {
                  px: 2
                }
              }}
            />
          );
  
          case 'senderId':
            if (convertToApiType(campaign.type) === 'whatsapp') {
              return (
                <Typography variant="body2">
                  {campaign.sender}
                </Typography>
              );
            } else {
              const sender = senders.find(s => s.id.toString() === campaign.sender);
              return (
                <Typography variant="body2">
                  {sender?.name || campaign.sender || '-'}
                </Typography>
              );
            }

          // Update the template card rendering in getCellContent
        case 'template':
            const template = templateMap.get(Number(campaign.templateId));
            const templateType = campaign.templateType || template?.type || 'undefined';
            const templateName = campaign.templateName || template?.name || `Template ${campaign.templateId}`;
            
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {templateName}
                </Typography>
                
                <Stack direction="row" spacing={1} alignItems="center">
                  <Chip
                    label={templateType}
                    size="small"
                    variant="outlined"
                    sx={{ 
                      height: '20px',
                      fontSize: '0.75rem',
                      backgroundColor: '#F5F5F5',
                      borderColor: '#E0E0E0',
                      color: '#757575'
                    }}
                  />
                </Stack>
              </Box>
            );
  
      case 'fromDate':
        if (!campaign.createdOn) return '-';
        try {
          return new Date(campaign.createdOn).toLocaleDateString();
        } catch (error) {
          return campaign.createdOn;
        }
  
      case 'runTimeStart':
        return campaign.schedule?.start || '-';
  
      case 'runTimeEnd':
        return campaign.schedule?.end || '-';
  
      case 'createdOn':
        if (!campaign.createdOn) return '-';
        try {
          return new Date(campaign.createdOn).toLocaleString();
        } catch (error) {
          return campaign.createdOn;
        }
  
      case 'modifiedOn':
        if (!campaign.updatedOn) return '-';
        try {
          return new Date(campaign.updatedOn).toLocaleString();
        } catch (error) {
          return campaign.updatedOn;
        }
  
      case 'actions':
        return (
          <Box sx={{ display: 'flex', gap: 1 }}>
            {/* <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleCampaignCopy(campaign);
              }}
              size="small"
              sx={{ color: '#4B87FF' }}
            >
              <Copy size={18} />
            </IconButton> */}
  <IconButton
    onClick={(e) => {
      e.stopPropagation();
      handleDeleteConfirmation(campaign.id);
    }}
    size="small"
    sx={{
      color: '#4B87FF',
      '&:hover': campaign.position === 'running' ? {
        backgroundColor: 'transparent',
        cursor: 'not-allowed'
      } : undefined
    }}
    disabled={campaign.position === 'running'}
    title={campaign.position === 'running' ? 'Stop campaign before deleting' : 'Delete campaign'}
  >
    <Trash2 size={18} />
  </IconButton>
          </Box>
        );
  
      default:
        return '-';
    }
  };

  return (
    <Box sx={styles.container}>
      {/* Success Snackbar */}
<Snackbar 
  open={!!successMessage} 
  autoHideDuration={3000} 
  onClose={() => setSuccessMessage(null)}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
  <Alert 
    onClose={() => setSuccessMessage(null)} 
    severity="success"
    sx={{
      width: '100%',
      backgroundColor: '#DCFCE7',
      color: '#16A34A',
      '& .MuiAlert-icon': {
        color: '#16A34A'
      }
    }}
  >
    {successMessage}
  </Alert>
</Snackbar>
      {/* Error Snackbar */}
      <Snackbar 
  open={!!error} 
  autoHideDuration={6000} 
  onClose={() => setError(null)}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
  <Alert 
    onClose={() => setError(null)} 
    severity="error"
    sx={{
      width: '100%',
      backgroundColor: '#FEE2E2',
      color: '#DC2626',
      '& .MuiAlert-icon': {
        color: '#DC2626'
      }
    }}
  >
    {error}
  </Alert>
</Snackbar>

      {/* Header */}
      <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          Campaigns
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddBoxOutlinedIcon />}
          onClick={() => setCreateDialogOpen(true)}
          sx={styles.createButton}
        >
          Create Campaign
        </Button>
      </Box>

      {/* Tab Navigation */}
      <Box sx={styles.tabsContainer}>
  {tabs.map(tab => (
    <Button
      key={tab.id}
      disableRipple
      onClick={async () => await handleTabChange(tab.id)}
      sx={styles.tab(activeTab === tab.id)}
    >
      {tab.label}
    </Button>
  ))}
</Box>

      {/* Table Container */}
      <TableContainer component={Paper} sx={styles.tableContainer}>
        {/* Search and Controls */}
        <Box sx={styles.searchContainer}>
        <Box>
  <Typography sx={styles.listTitle}>
    List of Campaigns
    <span className="total">{getDisplayCount()}</span>
  </Typography>
</Box>
  <Box sx={styles.searchControls}>
  <TextField
  size="small"
  placeholder="Search"
  value={searchQuery}
  onChange={handleSearch}
  sx={styles.searchInput}
  InputProps={{
    startAdornment: (
      <Box component="span" sx={{ color: 'grey.500', mr: 1 }}>
        <Search size={20} />
      </Box>
    ),
  }}
/>
  
  <IconButton 
    sx={styles.actionButton}
    onClick={handleColumnMenuOpen}
  >
    <TableIcon size={20} />
  </IconButton>
  
  {/* <IconButton 
    sx={styles.actionButton}
    onClick={handleDownloadClick}
  >
    <Download size={20} />
  </IconButton> */}
  
  <IconButton 
    sx={styles.actionButton}
    onClick={handleFilterClick}
  >
    <Filter size={20} />
  </IconButton>
</Box>
  
</Box>

        {loading ? (
          <Box sx={styles.loadingOverlay}>
            <CircularProgress />
          </Box>
        ) : !campaigns.length ? (
          <EmptyState />
        ) : (
<Table>
  <TableHead>
    <TableRow>
      <TableCell padding="checkbox">
        <Checkbox />
      </TableCell>
      {columns
        .filter(col => col.visible)
        .map(column => (
          <TableCell key={column.id}>
            {column.label}
          </TableCell>
        ))}
    </TableRow>
  </TableHead>
  <TableBody>
    {campaigns.map((campaign) => (
      <TableRow 
        key={campaign.id}
        hover
      >
        <TableCell padding="checkbox">
          <Checkbox />
        </TableCell>
        {columns
          .filter(col => col.visible)
          .map(column => (
            <TableCell key={column.id}>
              {getCellContent(campaign, column.id)}
            </TableCell>
          ))}
      </TableRow>
    ))}
  </TableBody>
</Table>
        )}

       {/* Update the pagination section in the render part of CXIReachContent.tsx */}
       <Box sx={styles.paginationContainer}>
  <Box sx={styles.rowsPerPage}>
    <FormControl size="small">
      <Select
        value={rowsPerPage}
        onChange={handleChangeRowsPerPage}
        sx={{ height: '32px' }}
      >
        {DEFAULT_ROWS_PER_PAGE_OPTIONS.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Typography variant="body2" color="textSecondary">
      Rows per page
    </Typography>
  </Box>

  <Box sx={{ color: '#666666', fontSize: '12px', mx: 2 }}>
    {paginationData.totalElements > 0
      ? `${paginationData.number * paginationData.size + 1}-${
          Math.min((paginationData.number + 1) * paginationData.size, paginationData.totalElements)
        } of ${paginationData.totalElements}`
      : '0 of 0'}
  </Box>

  <Box sx={styles.pagination}>
    <IconButton
      onClick={() => handleChangePage(null, page - 1)}
      disabled={paginationData.first}
      size="small"
    >
      &lt;
    </IconButton>

    {Array.from({ length: paginationData.totalPages }, (_, i) => (
      <Button
        key={i}
        onClick={() => handleChangePage(null, i)}
        variant={paginationData.number === i ? "contained" : "outlined"}
        size="small"
        sx={styles.paginationButton(paginationData.number === i)}
      >
        {i + 1}
      </Button>
    ))}

    <IconButton
      onClick={() => handleChangePage(null, page + 1)}
      disabled={paginationData.last}
      size="small"
    >
      &gt;
    </IconButton>
  </Box>
</Box>
      </TableContainer>

{/* Filter Popover */}
<Popover
    open={filterMenuOpen}
    anchorEl={filterAnchorEl}
    onClose={handleFilterClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    sx={{
      '& .MuiPopover-paper': {
        width: 320,
        p: 2,
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    }}
  >
    <Typography variant="subtitle1" sx={{ mb: 2 }}>Filter Campaigns</Typography>
    <Stack spacing={2}>
      <FormControl fullWidth size="small">
        <InputLabel>Category</InputLabel>
        <Select
          value={advancedFilters.category}
          onChange={(e) => handleAdvancedFilterChange('category', e.target.value)}
          label="Category"
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="simple">Simple</MenuItem>
          <MenuItem value="dynamic">Dynamic</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth size="small">
  <InputLabel>Position</InputLabel>
  <Select
    value={advancedFilters.position}
    onChange={(e) => handleAdvancedFilterChange('position', e.target.value)}
    label="Position"
  >
    <MenuItem value="">All</MenuItem>
    <MenuItem value="running">Running</MenuItem>
    <MenuItem value="stopped">Stopped</MenuItem>
    <MenuItem value="completed">Completed</MenuItem>
    <MenuItem value="ready">Ready</MenuItem>
  </Select>
</FormControl>
    </Stack>

    <Button
      fullWidth
      variant="outlined"
      onClick={() => {
        setAdvancedFilters({ 
          category: '', 
          position: '',
        });
        setFilterAnchorEl(null);
      }}
      sx={{ mt: 2 }}
    >
      Clear Filters
    </Button>
  </Popover>

      {/* Download Menu */}
      {/* <Menu
        anchorEl={downloadAnchorEl}
        open={Boolean(downloadAnchorEl)}
        onClose={handleDownloadClose}
        sx={styles.downloadMenu}
      >
        {downloadOptions.map((option) => (
          <MenuItem
            key={option.id}
            onClick={() => handleDownloadOption(option.id)}
            sx={styles.downloadMenuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu> */}

   
{/* Column Management Dialog */}
<ColumnManagementDialog
  open={Boolean(columnMenuAnchor)}
  onClose={() => setColumnMenuAnchor(null)}
  columns={columns}
  onColumnToggle={handleColumnToggle}
/>

      {/* Campaign Type Selection Dialog */}
      <Dialog 
        open={createDialogOpen} 
        onClose={handleCreateDialogClose}
        sx={styles.dialog}
      >
        <Box sx={styles.dialogHeader}>
          <Typography sx={styles.dialogTitle}>
            Which Campaign to Create?
          </Typography>
          <IconButton 
            onClick={handleCreateDialogClose}
            sx={styles.dialogCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={styles.dialogContent}>
          <Typography sx={styles.campaignTypeLabel}>
            Campaign Type
          </Typography>
          
          <Button
    className={selectedCampaignType && convertToApiType(selectedCampaignType) === 'sms' ? 'selected' : ''}
  sx={styles.campaignTypeButton}
  onClick={() => handleCampaignTypeSelect('sms')}
  disabled={loading}
>
  <MessageIcon sx={{ mr: 1 }} />
  SMS
</Button>

<Button
  className={selectedCampaignType && convertToApiType(selectedCampaignType) === 'whatsapp' ? 'selected' : ''}
  sx={styles.campaignTypeButton}
  onClick={() => handleCampaignTypeSelect('whatsapp')}
  disabled={loading}
>
  <WhatsAppIcon sx={{ mr: 1 }} />
  WhatsApp
</Button>

          <Box sx={styles.dialogFooter}>
            <Button
              variant="contained"
              sx={styles.continueButton}
              disabled={!selectedCampaignType || loading}
              onClick={handleContinueToForm}
            >
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Campaign Form Dialog */}
      <Dialog
        open={showCampaignForm}
        onClose={handleFormCancel}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={styles.dialogHeader}>
          <Typography sx={styles.dialogTitle}>
            Create {selectedCampaignType} Campaign
          </Typography>
          <IconButton 
            onClick={handleFormCancel}
            sx={styles.dialogCloseButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <DialogContent>
        {selectedCampaignType && (
  <CampaignForm
    type={selectedCampaignType}
    formData={formData}
    fieldErrors={fieldErrors}
    setFieldErrors={setFieldErrors}
    onFormChange={handleFormChange}
    onFileChange={handleFileChange}
    onSubmit={handleCampaignSubmit}
    onCancel={handleFormCancel}
    fileUpload={formData.fileUpload}  // Now properly typed
    whatsAppFile={formData.whatsAppFile}
    isSubmitting={loading}
  />
)}
        </DialogContent>
      </Dialog>

      {/* Campaign Edit Dialog */}
      {showEditDialog && editCampaign && (
  <Dialog
    open={showEditDialog}
    onClose={handleEditClose}
    maxWidth="lg"
    fullWidth
  >
    <DialogContent sx={{ p: 0 }}>
      <CampaignEdit
        campaign={editCampaign}
        onClose={handleEditClose}
        onSave={handleCampaignSave}
        onDelete={handleCampaignDelete}
      />
      {/* <WhatsAppTemplatesContainer wabaId="104491568982288" /> */}
    </DialogContent>
  </Dialog>
)}
      <DeleteCampaignConfirmation 
  open={deleteDialogOpen}
  onClose={handleDeleteCancel}
  onConfirm={handleDeleteConfirm}
  loading={loading}
/>
<StartStopConfirmation
  open={startStopDialogOpen}
  onClose={() => {
    setStartStopDialogOpen(false);
    setCampaignToToggle(null);
  }}
  onConfirm={handleStatusConfirm}
  loading={loading}
/>
    </Box>
  );
};

export default CXIReachContent;