import React, { useMemo } from 'react';
import { Card, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GraphView from './GraphView';

const useStyles = makeStyles(() => ({
    cardTile: {
        border: '1px solid #D6D9DC !important',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.04) !important',
        borderRadius: '12px !important',
        minHeight: '52px !important',
        maxHeight: '120px !important'
    },
    textCard: {
        position: 'relative',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
}))

const CardContent = ({data, fullgraph, onClickCard, tabClicked}) => {

    const classes = useStyles();

    let formHeadingMain = useMemo(
        () => ({
            fontWeight: '600',
            fontSize: '14px',
            color: '#546881',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
        }),
        []
    );

    let subFormHeading = useMemo(
        () => ({
            fontWeight: '500',
            fontSize: '20px',
            color: '#1D242D',
            fontStyle: 'normal',
            fontFamily: 'Roboto',
            marginLeft: '12px'
        }),
        []
    );

    const getTickerIcon = (value) => {
        if (+value < 0) {
            return `${process.env.PUBLIC_URL}/icons/down-red-arrow.svg`;
        }
        return `${process.env.PUBLIC_URL}/icons/up-green-arrow.svg`;
    };

    const showMonitorTicker = () => {
        return (
            <>
                <Grid item className="grid-border-item">
                    <div className="wallboard-grid-item-container">
                        <img src={getTickerIcon(-1)} alt="green arrow" />
                        <p className="percent down-red-arrow">0.4%</p>
                    </div>
                </Grid>
            </>
        );
    };
    

    const DataForm = ({ props, fullgraph }) => {

        return (
            <Card className={classes.cardTile} sx={{ height: '100%' , backgroundColor: `${props?.color ? props?.color: '#ffff'}`}} onClick={(e) => {onClickCard(props, fullgraph)}}>
                <div style={{ display: 'flex', alignContent: 'center', padding: '8px' }}>
                    <Typography sx={formHeadingMain} flexGrow={1}>{props?.label}</Typography>
                    <div><img alt='icon' src={`${process.env.PUBLIC_URL}/icons/${ tabClicked === 'digital' && props?.icon === 'CallConnectionRatio' ? 'ChatConnectionRatio' : props?.icon}.svg`} ></img></div>
                </div>
                {props?.key === 'CallConnectionRatio' || props?.key === 'WrapTimeExceeded' || props?.key === 'CallAbandonedRatio' ||
                props?.key === 'AgentDisconnectsRatio' || props?.key === 'SLA' ?
                    <>
                        <div style={{marginTop: '-10px'}}>
                        <Typography sx={subFormHeading} flexGrow={1}>{`${props?.value || 0}%`}</Typography>
                        </div>
                    </>
                    :
                    <>
                        <div style={{marginTop: '-10px'}}>
                        <Typography sx={subFormHeading} flexGrow={1}>{props?.value || 0}</Typography>
                        </div>
                    </>
                }
                <div style={{ display: 'flex', alignContent: 'center'}}>
                    <GraphView props={props} fullgraph={fullgraph} />
                </div>
            </Card>
        )
    }

    return (
        <DataForm props={data} fullgraph={fullgraph}/>
    );
}

export default CardContent;