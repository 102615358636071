// BATemplates.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  Switch,
  Snackbar,
  Alert,
  CircularProgress,
  InputLabel,
  DialogTitle,
  DialogActions,
  FormHelperText
} from '@mui/material';
import { Search, Plus, Edit, Trash2, X, Upload, Download } from 'lucide-react';
import axios from 'axios';
import { styles } from './styles';
import { senderApi } from './senderApi';  // Add this import
import { getAuthToken, getUserId } from '../../../services/auth-service';
import WhatsAppTemplatesContainer from './WhatsAppTemplatesContainer';

const BASE_URL = 'https://api.cx.dev.ozonetel.com/brandagent';
const CA_ADMIN_API_URL = 'https://api.cx.dev.ozonetel.com/ca-admin-Api/CloudAgentAPI';


// WhatsApp Languages Array
const whatsappLanguages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'sq', name: 'Albanian' },
  { code: 'ar', name: 'Arabic' },
  { code: 'az', name: 'Azerbaijani' },
  { code: 'bn', name: 'Bengali' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'zh_CN', name: 'Chinese (CHN)' },
  { code: 'zh_HK', name: 'Chinese (HKG)' },
  { code: 'zh_TW', name: 'Chinese (TAI)' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'en_GB', name: 'English (UK)' },
  { code: 'en_US', name: 'English (US)' },
  { code: 'et', name: 'Estonian' },
  { code: 'fil', name: 'Filipino' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'gu', name: 'Gujarati' },
  { code: 'ha', name: 'Hausa' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'id', name: 'Indonesian' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'ko', name: 'Korean' },
  { code: 'lo', name: 'Lao' },
  { code: 'lv', name: 'Latvian' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mr', name: 'Marathi' },
  { code: 'nb', name: 'Norwegian' },
  { code: 'fa', name: 'Persian' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt_BR', name: 'Portuguese (BR)' },
  { code: 'pt_PT', name: 'Portuguese (POR)' },
  { code: 'pa', name: 'Punjabi' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'es', name: 'Spanish' },
  { code: 'es_AR', name: 'Spanish (ARG)' },
  { code: 'es_ES', name: 'Spanish (SPA)' },
  { code: 'es_MX', name: 'Spanish (MEX)' },
  { code: 'sw', name: 'Swahili' },
  { code: 'sv', name: 'Swedish' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  { code: 'th', name: 'Thai' },
  { code: 'tr', name: 'Turkish' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'ur', name: 'Urdu' },
  { code: 'uz', name: 'Uzbek' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'zu', name: 'Zulu' }
];

// Add these constants near the top of your file
const WHATSAPP_TEMPLATE_TYPES = ['text','image', 'document', 'video'];

// Template Validation Functions
const validateSMSTemplate = (template) => {
  const errors = [];
  
  if (!template.templateId?.trim()) {
    errors.push('Template ID is required');
  }
  
  if (!template.name?.match(/^[a-zA-Z][a-zA-Z0-9_]{2,49}$/)) {
    errors.push('Template name must start with a letter and can only contain letters, numbers, and underscores');
  }

  if (!template.text?.trim()) {
    errors.push('Template text is required');
  }

  if (!template.type || !['transactional', 'promotional'].includes(template.type.toLowerCase())) {
    errors.push('Type must be either transactional or promotional');
  }

  if (!template.sender) {
    errors.push('Sender ID is required');
  }

  return errors;
};

const validateWhatsAppTemplate = (template) => {
  const errors = [];
  
  // Name validation
  if (!template.name?.trim()) {
    errors.push('Template name is required');
  } else if (!template.name.match(/^[a-zA-Z0-9_ ]+$/)) {
    errors.push('Template name should be alphanumeric, space and underscore only');
  }

  // Template ID validation
  if (!template.templateId?.trim()) {
    errors.push('Template ID is required');
  } else if (!template.templateId.match(/^\d+$/)) {
    errors.push('Template ID should contain only numbers');
  }

  // Type validation - REQUIRED
  if (!template.type) {
    errors.push('Template Type is required');
  }

  // Text validation
  if (!template.text?.trim()) {
    errors.push('Template text is required');
  }

  // Language validation
  if (!template.language) {
    errors.push('Language is required');
  }

  // WhatsApp number validation
  if (!template.whatsAppNumber) {
    errors.push('WhatsApp number is required');
  } else if (!template.whatsAppNumber.match(/^\+[1-9]\d{10,14}$/)) {
    errors.push('Invalid WhatsApp number format. Must include country code (e.g., +918555028583)');
  }

  return errors;
};


// API Functions for SMS Templates
const fetchSMSTemplates = async (userId, page = 0, size = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/smsTemplate/active`, {
      params: { page, size },
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch SMS templates:', error);
    throw error;
  }
};

// Fetch Active Senders
const fetchSenders = async (userId, page = 0, size = 100) => {
  try {
    const response = await axios.get(`${BASE_URL}/sender/active`, {
      params: { page, size },
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch senders:', error);
    throw error;
  }
};

// Approve SMS Template
const approveSMSTemplate = async (userId, templateId) => {
  try {
    const response = await axios.post(`${BASE_URL}/smsTemplate/approve/${templateId}`, null, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to approve SMS template:', error);
    throw error;
  }
};

// Reject SMS Template
const rejectSMSTemplate = async (userId, templateId) => {
  try {
    const response = await axios.post(`${BASE_URL}/smsTemplate/reject/${templateId}`, null, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to reject SMS template:', error);
    throw error;
  }
};

const createSMSTemplate = async (userId, templateData) => {
  try {
    const response = await axios.post(`${BASE_URL}/smsTemplate`, templateData, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to create SMS template:', error);
    throw error;
  }
};

const updateSMSTemplate = async (userId, templateId, templateData) => {
  try {
    const response = await axios.put(`${BASE_URL}/smsTemplate/${templateId}`, templateData, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update SMS template:', error);
    throw error;
  }
};

const deleteSMSTemplate = async (userId, templateId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/smsTemplate/${templateId}`, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to delete SMS template:', error);
    throw error;
  }
};

// API Functions for WhatsApp Templates
const fetchWhatsAppTemplates = async (userId, page = 0, size = 10) => {
  try {
    const response = await axios.get(`${BASE_URL}/whatsAppTemplate/active`, {
      params: { page, size },
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetch WhatsApp templates:', error);
    throw error;
  }
};


const createWhatsAppTemplate = async (userId, templateData) => {
  try {
    const payload = {
      templateId: templateData.templateId,
      name: templateData.name,
      text: templateData.text,
      type: templateData.type,      // Make sure type is included
      language: templateData.language,
      whatsAppNumber: templateData.whatsAppNumber,
      category: templateData.category || 'UTILITY',
      approved: false
    };

    const response = await axios.post(`${BASE_URL}/whatsAppTemplate`, payload, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to create WhatsApp template:', error);
    throw error;
  }
};

const updateWhatsAppTemplate = async (userId, templateId, templateData) => {
  try {
    // Format the payload according to the API requirements
    const payload = {
      name: templateData.name,
      text: templateData.text,
      templateId: templateData.templateId,
      type: templateData.type, // Remove the default 'text' value
      language: templateData.language,
      whatsAppNumber: templateData.whatsAppNumber,
      approved: templateData.approved
    };

    const response = await axios.put(`${BASE_URL}/whatsAppTemplate/${templateId}`, payload, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update WhatsApp template:', error);
    throw error;
  }
};

// Add these API functions for WhatsApp template status
const approveWhatsAppTemplate = async (userId, templateId) => {
  try {
    const response = await axios.post(`${BASE_URL}/whatsAppTemplate/approve/${templateId}`, null, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to approve WhatsApp template:', error);
    throw error;
  }
};

const rejectWhatsAppTemplate = async (userId, templateId) => {
  try {
    const response = await axios.post(`${BASE_URL}/whatsAppTemplate/reject/${templateId}`, null, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to reject WhatsApp template:', error);
    throw error;
  }
};

const deleteWhatsAppTemplate = async (userId, templateId) => {
  try {
    const response = await axios.delete(`${BASE_URL}/whatsAppTemplate/${templateId}`, {
      headers: {
        userid: userId,
        Authorization: `Bearer ${getAuthToken()}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to delete WhatsApp template:', error);
    throw error;
  }
};

// Update the form initial state and validation
const initialWhatsAppFormState = {
  name: '',
  text: '',
  templateId: '',
  type: 'text',
  language: 'en-US',
  whatsAppNumber: '',
  approved: false
};


// Helper Components
const ErrorSnackbar = ({ open, message, onClose }) => {
  return (
    <Snackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        onClose={onClose} 
        severity="error" 
        sx={{ 
          width: '100%',
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: 500,
          backgroundColor: '#FEE2E2',
          color: '#DC2626',
          '& .MuiAlert-icon': {
            color: '#DC2626'
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const SuccessSnackbar = ({ open, message, onClose }) => {
  return (
    <Snackbar 
      open={open} 
      autoHideDuration={3000} 
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        onClose={onClose} 
        severity="success" 
        sx={{ 
          width: '100%',
          fontFamily: 'Roboto',
          fontSize: '12px',
          fontWeight: 500,
          backgroundColor: '#DCFCE7',
          color: '#16A34A',
          '& .MuiAlert-icon': {
            color: '#16A34A'
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

// Template Dialog Component
const CreateTemplateDialog = ({ 
  open,
  onClose,
  templateType,
  editData,
  senders,
  sendersLoading,
  sendersError,
  onSubmitSuccess,
  userId
}) => {
  const [formData, setFormData] = useState(
    editData ? {
      // ... other fields ...
      templateId: editData.templateId || '',
      name: editData.name || '',
      text: editData.text || '',
      type: editData.type || 'image', // Default to 'image' for WhatsApp
      sender: editData.sender || '',
      language: editData.language || 'en',
      whatsAppNumber: editData.whatsAppNumber || '',
      headerText: editData.headerText || '',
      footerText: editData.footerText || '',
      category: editData.category || 'UTILITY',
    } : {
      templateId: '',
      name: '',
      text: '',
      type: templateType === 'sms' ? 'transactional' : 'image', // Default WhatsApp type to 'image'
      sender: '',
      language: 'en',
      whatsAppNumber: '',
      headerText: '',
      footerText: '',
      category: 'UTILITY',
    }
  );

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (editData) {
      setFormData({
        templateId: editData.templateId || '',
        name: editData.name || '',
        text: editData.text || '',
        type: editData.type || 'transactional',
        sender: editData.sender || '',
        language: editData.language || 'en',
        whatsAppNumber: editData.whatsAppNumber || '',
        headerText: editData.headerText || '',
        footerText: editData.footerText || '',
        category: editData.category || 'UTILITY',
      });
    }
  }, [editData]);

  const validateForm = () => {
    const errors = templateType === 'sms' 
      ? validateSMSTemplate(formData)
      : validateWhatsAppTemplate(formData);

    if (errors.length > 0) {
      setError(errors.join('. '));
      return false;
    }
    return true;
  };

  const handleChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    try {
      const apiData = templateType === 'sms' 
        ? {
            templateId: formData.templateId,
            name: formData.name,
            text: formData.text,
            type: formData.type,
            sender: formData.sender,
            approved: false
          }
        :{
          templateId: formData.templateId,
          name: formData.name,
          text: formData.text,
          type: formData.type,      // Make sure type is included
          language: formData.language,
          whatsAppNumber: formData.whatsAppNumber,
          category: formData.category,
          approved: false
        };

        if (editData) {
          const response = templateType === 'sms'
            ? await updateSMSTemplate(userId, editData.id, apiData)
            : await updateWhatsAppTemplate(userId, editData.id, apiData);
          
          if (response.status === 'success') {
            onSubmitSuccess('Template updated successfully');
            onClose();
          }
        } else {
          const response = templateType === 'sms'
            ? await createSMSTemplate(userId, apiData)
            : await createWhatsAppTemplate(userId, apiData);
  
          if (response.status === 'success') {
            onSubmitSuccess('Template created successfully');
            onClose();
          }
        }
      } catch (error) {
        console.error('Failed to submit template:', error);
        if (error.response?.data?.errors) {
          const errorMessages = Object.entries(error.response.data.errors)
            .map(([field, messages]) => messages.join('. '))
            .join('. ');
          setError(errorMessages);
        } else {
          setError(error.response?.data?.message || 'Failed to save template. Please try again.');
        }
      } finally {
        setLoading(false);
      }
  };
  // Continue from CreateTemplateDialog...
  const renderSMSForm = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <FormControl fullWidth>
        <TextField
          label="Template ID *"
          value={formData.templateId}
          onChange={handleChange('templateId')}
          required
          disabled={!!editData}
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '48px'
            }
          }}
          placeholder="Enter unique template ID"
        />
      </FormControl>

      <FormControl fullWidth>
        <TextField
          label="Template Name *"
          value={formData.name}
          onChange={handleChange('name')}
          required
          sx={{
            '& .MuiOutlinedInput-root': {
              height: '48px'
            }
          }}
          placeholder="Start with letter, use letters, numbers, underscores"
          helperText="Must start with a letter and can only contain letters, numbers, and underscores"
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel>Type *</InputLabel>
        <Select
          value={formData.type}
          onChange={handleChange('type')}
          label="Type *"
          sx={{ height: '48px' }}
        >
          <MenuItem value="transactional">Transactional</MenuItem>
          <MenuItem value="promotional">Promotional</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth error={!!sendersError}>
        <InputLabel>Sender ID *</InputLabel>
        <Select
          value={formData.sender}
          onChange={handleChange('sender')}
          label="Sender ID *"
          disabled={sendersLoading}
          sx={{ height: '48px' }}
        >
          {sendersLoading ? (
            <MenuItem disabled>Loading senders...</MenuItem>
          ) : senders && senders.length > 0 ? (
            senders.map(sender => (
              <MenuItem key={sender.id} value={sender.name}>
                {sender.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No active senders available</MenuItem>
          )}
        </Select>
        {sendersError && (
          <FormHelperText error>{sendersError}</FormHelperText>
        )}
      </FormControl>

      <FormControl fullWidth>
        <TextField
          label="Template Text *"
          value={formData.text}
          onChange={handleChange('text')}
          multiline
          rows={4}
          required
          placeholder="Enter template text here..."
          helperText={
            <Box component="span" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Enter the message content</span>
              <span>{`${formData.text?.length || 0} characters`}</span>
            </Box>
          }
        />
      </FormControl>
    </Box>
  );

  const renderWhatsAppForm = () => (
    
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <FormControl fullWidth>
        <TextField
          label="Template Name *"
          value={formData.name}
          onChange={handleChange('name')}
          required
          sx={styles.templateFormField}
          placeholder="Enter template name"
          helperText="Only alphanumeric characters, spaces, and underscores allowed"
        />
      </FormControl>
  
      <FormControl fullWidth>
        <TextField
          label="Template ID *"
          value={formData.templateId}
          onChange={handleChange('templateId')}
          required
          disabled={!!editData}
          sx={styles.templateFormField}
          placeholder="Enter template ID (numbers only)"
          helperText="Template ID must contain only numbers"
        />
      </FormControl>
  
      <FormControl fullWidth>
        <InputLabel>Language *</InputLabel>
        <Select
          value={formData.language}
          onChange={handleChange('language')}
          label="Language *"
          sx={styles.templateTypeSelect}
        >
          {whatsappLanguages.map(lang => (
            <MenuItem key={lang.code} value={lang.code}>
              {lang.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  
      <FormControl fullWidth required>
        <InputLabel>Type *</InputLabel>
        <Select
          value={formData.type}
          onChange={handleChange('type')}
          label="Type *"
          sx={styles.templateTypeSelect}
        >
          {WHATSAPP_TEMPLATE_TYPES.map(type => (
            <MenuItem key={type} value={type}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Template type must be image, document, or video</FormHelperText>
      </FormControl>
  
      <FormControl fullWidth>
        <TextField
          label="WhatsApp Number *"
          value={formData.whatsAppNumber}
          onChange={handleChange('whatsAppNumber')}
          required
          sx={styles.templateFormField}
          placeholder="+918555028583"
          helperText="Include country code (e.g., +918555028583)"
        />
      </FormControl>
  
      <FormControl fullWidth>
        <TextField
          label="Template Text *"
          value={formData.text}
          onChange={handleChange('text')}
          multiline
          rows={4}
          required
          sx={{
            ...styles.templateFormField,
            '& .MuiOutlinedInput-root': {
              height: 'auto'
            }
          }}
          placeholder="Enter template text here... Use {{number}} for variables"
          helperText="Use {{number}} for variables (e.g., {{0}}, {{1}})"
        />
      </FormControl>
  
      <Box sx={styles.characterCount}>
        {formData.text?.length || 0} characters
      </Box>
    </Box>
  );

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '8px',
          width: '100%',
          maxWidth: '600px'
        }
      }}
    >
      <DialogTitle sx={{ borderBottom: '1px solid #E6E6E6', p: 3 }}>
        <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 500 }}>
          {editData ? 'Edit' : 'Create'} {templateType === 'sms' ? 'SMS' : 'WhatsApp'} Template
        </Typography>
        <IconButton 
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: '16px',
            top: '16px'
          }}
        >
          <X size={20} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        {error && (
          <Alert 
            severity="error" 
            sx={{ 
              mb: 2,
              fontFamily: 'Roboto',
              fontSize: '12px',
              backgroundColor: '#FEE2E2',
              color: '#DC2626'
            }}
          >
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          {templateType === 'sms' ? renderSMSForm() : renderWhatsAppForm()}
          
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 2, 
            mt: 4,
            borderTop: '1px solid #E6E6E6',
            pt: 3
          }}>
            <Button
              onClick={onClose}
              variant="outlined"
              disabled={loading}
              sx={{
                height: '36px',textTransform: 'none',
                borderColor: '#E6E6E6',
                color: '#666666'
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                height: '36px',
                textTransform: 'none',
                backgroundColor: '#4B87FF',
                '&:hover': {
                  backgroundColor: '#3D78E6'
                }
              }}
            >
              {loading ? (
                <CircularProgress size={20} sx={{ color: 'white' }} />
              ) : (
                editData ? 'Update' : 'Create'
              )}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

// Main BATemplates Component
const BATemplates = () => {
  const [activeTab, setActiveTab] = useState('sms');
  const [searchQuery, setSearchQuery] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [smsTemplates, setSMSTemplates] = useState([]);
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [userId, setUserId] = useState(null);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [senders, setSenders] = useState([]);
  const [sendersLoading, setSendersLoading] = useState(false);
  const [sendersError, setSendersError] = useState('');
  const [sendersList, setSendersList] = useState([]);

  const [wabaId, setWabaId] = useState(null);
  const [wabaLoading, setWabaLoading] = useState(false);
  const [wabaError, setWabaError] = useState(null);


  // Initialize userId
  useEffect(() => {
    const id = getUserId();
    if (!id) {
      handleError('User ID not found');
      return;
    }
    setUserId(parseInt(id));
  }, []);

  // Load templates based on active tab
  useEffect(() => {
    if (userId) {
      if (activeTab === 'sms') {
        loadSMSTemplates();
      } else {
        return;
        //loadWhatsAppTemplates();
      }
    }
  }, [activeTab, page, rowsPerPage, userId]);

  // Load senders for SMS templates
  useEffect(() => {
    if (userId && activeTab === 'sms') {
      loadSenders();
    }
  }, [userId, activeTab]);

  useEffect(() => {
    const fetchWabaId = async () => {
      if (!userId || activeTab !== 'whatsapp') return;

      try {
        setWabaLoading(true);
        const response = await axios.post(
          `${CA_ADMIN_API_URL}/settingsByCode`,
          { isAdmin: "false", settingsCode: "WABA_ID" },
          {
            headers: {
              userid: userId.toString(),
              'Content-Type': 'application/json',
              Authorization: `Bearer ${getAuthToken()}`
            }
          }
        );

        if (response.data?.Data?.[0]?.ParameterValue) {
          setWabaId(response.data.Data[0].ParameterValue);
        } else {
          throw new Error('No WABA ID found in response');
        }
      } catch (error) {
        console.error('Error fetching WABA ID:', error);
        setWabaError(error.message || 'Failed to fetch WABA ID');
        handleError('Failed to fetch WhatsApp Business Account ID');
      } finally {
        setWabaLoading(false);
      }
    };

    fetchWabaId();
  }, [userId, activeTab]);

  const handleError = (message) => {
    setError(message);
    setTimeout(() => setError(''), 6000);
  };

  const handleSuccess = (message) => {
    setSuccess(message);
    setTimeout(() => setSuccess(''), 3000);
  };

  const loadSMSTemplates = async () => {
    if (!userId) return;
    
    try {
      setLoading(true);
      const response = await fetchSMSTemplates(userId, page, rowsPerPage);
      if (response?.data?.content) {
        const filteredTemplates = response.data.content
          .filter(template => !template.deleted)
          .map(template => ({
            ...template,
            status: template.approved
          }));
        setSMSTemplates(filteredTemplates);
      }
    } catch (error) {
      console.error('Error loading SMS templates:', error);
      handleError('Failed to load templates');
    } finally {
      setLoading(false);
    }
  };

  const loadWhatsAppTemplates = async () => {
    try {
      setLoading(true);
      const response = await fetchWhatsAppTemplates(userId, page, rowsPerPage);
      if (response?.data?.content) {
        setWhatsappTemplates(response.data.content);
        setTotalElements(response.data.totalElements);
        setTotalPages(response.data.totalPages);
      }
    } catch (error) {
      console.error('Error loading WhatsApp templates:', error);
      handleError('Failed to load WhatsApp templates');
    } finally {
      setLoading(false);
    }
  };
  
  const loadSenders = async () => {
    if (!userId) return;

    try {
      setSendersLoading(true);
      setSendersError('');
      const response = await senderApi.listSenders(userId);
      if (response?.data?.content) {
        // Filter only active and approved senders
        const activeSenders = response.data.content.filter(
          sender => !sender.deleted && sender.approved
        );
        setSenders(activeSenders);
      }
    } catch (error) {
      console.error('Error loading senders:', error);
      setSendersError('Failed to load senders');
      handleError('Failed to load sender list');
    } finally {
      setSendersLoading(false);
    }
  };

  // Update the handleStatusChange function
const handleStatusChange = async (templateId) => {
  try {
    if (activeTab === 'whatsapp') {
      return;
      // const template = whatsappTemplates.find(t => t.id === templateId);
      // if (!template) {
      //   handleError('Template not found');
      //   return;
      // }

      // if (!template.approved) {
      //   await approveWhatsAppTemplate(userId, templateId);
      //   handleSuccess('WhatsApp template approved successfully');
      // } else {
      //   await rejectWhatsAppTemplate(userId, templateId);
      //   handleSuccess('WhatsApp template rejected successfully');
      // }
      // loadWhatsAppTemplates(); // Reload the templates to get updated status
    } else {
      const template = smsTemplates.find(t => t.id === templateId);
      if (!template || template.deleted) {
        handleError('Template not found or deleted');
        return;
      }

      if (!template.approved) {
        await approveSMSTemplate(userId, templateId);
        handleSuccess('SMS template approved successfully');
      } else {
        await rejectSMSTemplate(userId, templateId);
        handleSuccess('SMS template rejected successfully');
      }
      loadSMSTemplates();
    }
  } catch (error) {
    console.error('Error updating template status:', error);
    const errorMessage = error.response?.data?.message || 'Failed to update template status';
    handleError(errorMessage);
  }
};

  const handleEdit = (template) => {
    setEditingTemplate(template);
    setCreateDialogOpen(true);
  };

  const handleDelete = async (templateId) => {
    if (!window.confirm('Are you sure you want to delete this template?')) return;

    try {
      if (activeTab === 'whatsapp') {
        await deleteWhatsAppTemplate(userId, templateId);
        handleSuccess('WhatsApp template deleted successfully');
        loadWhatsAppTemplates();
      } else {
        await deleteSMSTemplate(userId, templateId);
        handleSuccess('SMS template deleted successfully');
        loadSMSTemplates();
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      handleError('Failed to delete template');
    }
  };

  const handleCloseDialog = () => {
    setCreateDialogOpen(false);
    setEditingTemplate(null);
  };

  const handleSubmitSuccess = (message) => {
    handleSuccess(message);
    if (activeTab === 'sms') {
      loadSMSTemplates();
    } else {
      return null;
      //loadWhatsAppTemplates();
    }
  };

    // Add an effect to load senders when dialog opens
    useEffect(() => {
      if (createDialogOpen && activeTab === 'sms') {
        loadSenders();
      }
    }, [createDialogOpen, activeTab]);
  
    // Update the dialog opening handler
    const handleCreateClick = () => {
      if (activeTab === 'sms') {
        loadSenders(); // Load senders when opening dialog
      }
      setCreateDialogOpen(true);
    };

  // Render functions...
  const renderTemplateTable = () => {
    const templates = activeTab === 'sms' ? smsTemplates : whatsappTemplates;
    const filteredTemplates = templates.filter(template => {
      const searchText = searchQuery.toLowerCase();
      return (
        template.name?.toLowerCase().includes(searchText) ||
        template.templateId?.toLowerCase().includes(searchText) ||
        template.text?.toLowerCase().includes(searchText)
      );
    });
  
    return (
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow>
            {activeTab === 'sms' ? (
              <>
                <TableCell>Template ID</TableCell>
                <TableCell>Sender ID</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Template Name</TableCell>
                <TableCell>Template Text</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell align="center">Actions</TableCell>
              </>
            ) : (
              <>
                <TableCell>Template ID</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Template Text</TableCell>
                <TableCell align="center">Actions</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredTemplates
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((template) => (
              <TableRow key={template.id} hover>
                {activeTab === 'sms' ? (
                  <>
                    <TableCell>{template.templateId}</TableCell>
                    <TableCell>{template.sender || '—'}</TableCell>
                    <TableCell>
                      <Switch
                        checked={template.approved}
                        onChange={() => handleStatusChange(template.id)}
                        disabled={template.deleted}
                        sx={styles.switch}
                      />
                    </TableCell>
                    <TableCell>{template.name}</TableCell>
                    <TableCell>{template.text}</TableCell>
                    <TableCell>{template.updatedOn}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                        <IconButton
                          sx={styles.actionButton}
                          onClick={() => handleEdit(template)}
                        >
                          <Edit size={16} />
                        </IconButton>
                        <IconButton
                          sx={styles.actionButton}
                          onClick={() => handleDelete(template.id)}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{template.templateId}</TableCell>
                    <TableCell>
                      {whatsappLanguages.find(l => l.code === template.language)?.name || template.language}
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={template.approved}
                        onChange={() => handleStatusChange(template.id)}
                        sx={styles.switch}
                      />
                    </TableCell>
                    <TableCell>{template.text}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                        <IconButton
                          sx={styles.actionButton}
                          onClick={() => handleEdit(template)}
                        >
                          <Edit size={16} />
                        </IconButton>
                        <IconButton
                          sx={styles.actionButton}
                          onClick={() => handleDelete(template.id)}
                        >
                          <Trash2 size={16} />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <Box sx={styles.container}>
      <ErrorSnackbar 
        open={!!error} 
        message={error} 
        onClose={() => setError('')} 
      />
      <SuccessSnackbar 
        open={!!success} 
        message={success} 
        onClose={() => setSuccess('')} 
      />

        {/* Header */}
        <Box sx={styles.header}>
        <Typography sx={styles.headerTitle}>
          Templates
        </Typography>
        {/* Only show Create button for SMS */}
        {activeTab === 'sms' && (
          <Button
            variant="contained"
            startIcon={<Plus size={16} />}
            onClick={handleCreateClick}
            sx={styles.createButton}
          >
            New SMS Template
          </Button>
        )}
      </Box>

      {/* Tabs */}
      <Box sx={styles.tabsContainer}>
        <Button
          disableRipple
          onClick={() => setActiveTab('whatsapp')}
          sx={styles.tab(activeTab === 'whatsapp')}
        >
          WhatsApp
        </Button>
        <Button
          disableRipple
          onClick={() => setActiveTab('sms')}
          sx={styles.tab(activeTab === 'sms')}
        >
          SMS
        </Button>
      </Box>

      {/* Main Content */}
      {activeTab === 'whatsapp' ? (
        wabaLoading ? (
          <Box sx={styles.loadingOverlay}>
            <CircularProgress />
          </Box>
        ) : wabaError ? (
          <Box sx={{ p: 3, textAlign: 'center', color: 'error.main' }}>
            <Typography>Failed to load WhatsApp templates. Please try again later.</Typography>
          </Box>
        ) : (
          <WhatsAppTemplatesContainer wabaId={wabaId} />
        )
      ) : (
      <TableContainer component={Paper} sx={styles.tableContainer}>
        <Box sx={styles.searchContainer}>
          <Typography sx={styles.listTitle}>
            List of Templates
            <span className="total">
              Total {(activeTab === 'sms' ? smsTemplates : whatsappTemplates).length}
            </span>
          </Typography>
          <Box sx={styles.searchControls}>
            <TextField
              size="small"
              placeholder={`Search ${activeTab === 'sms' ? 'SMS' : 'WhatsApp'} templates...`}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={styles.searchInput}
              InputProps={{
                startAdornment: (
                  <Box sx={styles.searchIcon}>
                    <Search size={20} />
                  </Box>
                )
              }}
            />
             {/* Add the bulk upload functionality */}
  <input
    accept=".csv,.xlsx,.xls"
    style={{ display: 'none' }}
    id="raised-button-file"
    type="file"
    onChange={async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        handleError('Invalid file type. Please upload a CSV, XLSX, or XLS file');
        return;
      }

      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(`${BASE_URL}/smsTemplate/upload`, formData, {
          headers: {
            'userid': userId,
            'Authorization': `Bearer ${getAuthToken()}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.status === "success") {
          handleSuccess('Templates uploaded successfully');
          loadSMSTemplates(); // Refresh the list
        } else {
          throw new Error(response.data.message || 'Upload failed');
        }
      } catch (err) {
        const errorMessage = err.response?.data?.message || 'Failed to upload templates. Please try again.';
        handleError(errorMessage);
      } finally {
        setLoading(false);
        event.target.value = null; // Reset file input
      }
    }}
  />
  <label htmlFor="raised-button-file">
    <Button
      component="span"
      variant="contained"
      startIcon={<Upload size={16} />}
      sx={{
        ...styles.createButton,
        ml: 2
      }}
      disabled={loading}
    >
      Bulk Upload
    </Button>
  </label>
          </Box>
        </Box>

        {/* Templates Table */}
        {renderTemplateTable()}

        {/* Pagination */}
        <Box sx={styles.paginationContainer}>
          <Box sx={styles.rowsPerPage}>
            <Typography>Show</Typography>
            <Select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setPage(0);
              }}
              size="small"
              sx={{
                height: '32px',
                minWidth: '80px',
                marginLeft: '8px',
                marginRight: '8px'
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography>entries</Typography>
          </Box>

          {/* Pagination Controls */}
          <Box sx={styles.pagination}>
            <Button
              onClick={() => setPage(prev => Math.max(0, prev - 1))}
              disabled={page === 0}
              sx={styles.paginationButton(false)}
            >
              Previous
            </Button>
            {Array.from(
              { length: Math.ceil((activeTab === 'sms' ? smsTemplates : whatsappTemplates).length / rowsPerPage) },
              (_, i) => (
                <Button
                  key={i}
                  onClick={() => setPage(i)}
                  sx={styles.paginationButton(page === i)}
                >
                  {i + 1}
                </Button>
              )
            )}
            <Button
              onClick={() => setPage(prev => 
                Math.min(Math.ceil((activeTab === 'sms' ? smsTemplates : whatsappTemplates).length / rowsPerPage) - 1, prev + 1)
              )}
              disabled={page >= Math.ceil((activeTab === 'sms' ? smsTemplates : whatsappTemplates).length / rowsPerPage) - 1}
              sx={styles.paginationButton(false)}
            >
              Next
            </Button>
          </Box>
        </Box>
      </TableContainer>)}

      {/* Loading Overlay */}
      {loading && (
        <Box sx={styles.loadingOverlay}>
          <CircularProgress />
        </Box>
      )}

      {/* Template Dialog */}
      <CreateTemplateDialog
        open={createDialogOpen}
        onClose={handleCloseDialog}
        templateType={activeTab}
        editData={editingTemplate}
        senders={senders}
        sendersLoading={sendersLoading}
        sendersError={sendersError}
        onSubmitSuccess={handleSubmitSuccess}
        userId={userId}
      />
    </Box>
  );
};

export default BATemplates;