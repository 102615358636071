import React, { useState, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Grid, Tooltip } from '@mui/material';
import OZDialog from "../../../components/admin/common/OZDialog";
import OZSelect from '../../../components/admin/common/OZSelect';
import Divider from '@mui/material/Divider';
import OZDataGrid from '../../../components/admin/common/OZDataGrid/OZDataGrid';
import OZGridToolbar from '../../../components/admin/common/OZDataGrid/OZGridToolbar';
import { showErrorNotification, showSuccessNotification, showWarningNotification, } from '../../../components/admin/common/NotiService';
import {
    getCampaignResetData, getReasonsDropdown, getCampaignDispositonsDropdown,
    getDeleteCampaignData, getResetCampaignData, getagentsDropdown, getskillsDropdown
} from '../../../services/api-service';
import OZInput from '../../../components/admin/common/OZInput';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Stack } from '@mui/system';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import CustomAutoComplete from '../../../components/admin/common/AutoComplete/CustomAutoComplete';
import { connect } from "formik";
import moment from 'moment';
import LoadingScreen from '../../../components/admin/common/OZLoadingScreen/OZLoadingScreen';


const ResetCampaignForm = (props) => {
    const [openDownloadDataGrid, setDownloadDataGrid] = useState(false)
    const [openResetCampaignDataform, setResetCampaignDataform] = useState(true);
    const [rowData, setRowData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [reasonsList, setReasonsList] = useState([]);
    const [dispositonsList, setDispositonsList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [finalData, setFinalData] = useState(false);
    const [disabledByLoading, setDisabledByLoading] = useState(false);
    const [newLoading, setNewLoading] = useState(false);

    useEffect(() => {
        getReasonsListDropDown(props?.ID)
        getDispositonsListDropDown(props?.ID)
        getSkillListDropDown()
        getAgentListDropDown()
    }, []);

    async function getReasonsListDropDown(id) {
        try {
            getReasonsDropdown(id).then(resp => {
                // setReasonsList(resp.Data);
                const updatedData = resp?.Data?.length > 0 ? resp?.Data.map((item,index) => ({
                    ...item,
                    id: index+100
                })) : [{ id: "0_all", dialStatus: "All" }];
                setReasonsList(updatedData);
            }).catch(e => {
                setReasonsList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };
    async function getDispositonsListDropDown(id) {
        try {
            getCampaignDispositonsDropdown(id).then(resp => {
                // setDispositonsList(resp.Data);
                const updatedData = resp?.Data?.length > 0 ? resp.Data.map((item,index) => ({
                    ...item,
                    id: index+100
                })) : [{ id: "0_all", disposition: "All" }];
                setDispositonsList(updatedData);
            }).catch(e => {
                setDispositonsList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function getAgentListDropDown() {
        try {
            getagentsDropdown().then(resp => {
                setAgentList(resp);
            }).catch(e => {
                setAgentList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    async function getSkillListDropDown() {
        try {
            getskillsDropdown().then(resp => {
                setSkillList(resp.Data);
            }).catch(e => {
                setSkillList([]);
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    };

    const DateFormatter = (params) => {
        const date = new Date(params.value);
        const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return date.toLocaleString("en-GB", options);
    }

    const columnDefs = [
        {
            field: "phoneNumber",
            headerName: "Phone No",

        },
        {
            field: "name",
            headerName: "Name",
            cellRenderer: (params) => {
                return (
                    <Tooltip title={params.value ? params?.value : ''} placement='left-start'>
                        <span>{params.value ? params?.value : ''}</span>
                    </Tooltip>
                )
            }
        },
        {
            field: "dialStatus",
            headerName: "Reason",
        },
        {
            field: "disposition",
            headerName: "Disposition",
        },
        {
            field: "dateCreated",
            headerName: "Data Added on", cellRenderer: DateFormatter,
        },
    ];

    const validationSchema = useMemo(() => (yup.object({
        status: yup
            .string("Status is required.")
            .nullable()
            .required("Status is required."),
        // reason: yup
        //     .string("Reason is required.")
        //     .nullable()
        //     .required("Reason is required."),
        dialStatus: yup
            .array()
            .min(1, "At least one dial status is required.")
            .required("Dial status is required."),
        disposition: yup
            .array()
            .min(1, "At least one disposition is required.")
            .required("Disposition is required."),
        skill: yup
            .array()
            .when('dialMethod', {
                is: type => type === 'Skillwise',
                then: yup
                    .array()
                    .nullable()
                    .min(1, "Skill field must have at least 1 item.")
                    .required("Skill is required."),
                otherwise: yup.array().notRequired().nullable()
            }),
        agent: yup
            .array()
            .when('dialMethod', {
                is: type => type === 'Agentwise',
                then: yup
                    .array()
                    .nullable()
                    .min(1, "Agent field must have at least 1 item.")
                    .required("Agent is required."),
                otherwise: yup.array().notRequired().nullable()
            }),
    })), []);



    const closeResetCampaignDataform = () => {
        localStorage.removeItem("ResetForm");
        setResetCampaignDataform(false);
        props.onClose();
    }

    function closeDataGrid() {
        setDownloadDataGrid(false)
    }

    async function getCampaignRegenerateData(body, id) { //api service
        setNewLoading(true);
        try {
            getCampaignResetData(body, id).then(resp => {
                setRowData(resp.Message);
                setColumnData(columnDefs);
                setNewLoading(false);
            }).catch(e => {
                showErrorNotification(JSON.stringify(e));
                setRowData([])
                setNewLoading(false);
            })
        }
        catch (e) {
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function deleteCampaignDataButton(data, id) {
        setNewLoading(true);
        const newData = { ...data, "dateSortBy": "" }
        try {
            getDeleteCampaignData(newData, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${props.props.campaignName} ${resp.Message}`);
                    getCampaignRegenerateData(data, id);
                    setNewLoading(false);
                } else {
                    setNewLoading(false);
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                setNewLoading(false);
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    async function resetCampaignDataButton(data, id) {
        setNewLoading(true);
        const newData = { ...data, "dateSortBy": "" }
        try {
            getResetCampaignData(newData, id).then(resp => {
                if (resp.Status === "Success") {
                    showSuccessNotification(`${props.props.campaignName} ${resp.Message}`);
                    getCampaignRegenerateData(newData, id);
                    setNewLoading(false);
                } else {
                    setNewLoading(false);
                    showErrorNotification(JSON.stringify(resp.Message));
                }
            }).catch(e => {
                setNewLoading(false);
                showErrorNotification(JSON.stringify(e));
            })
        }
        catch (e) {
            setNewLoading(false);
            showErrorNotification(JSON.stringify(e));
        }
    }

    const DataForm = (data) => {
        if (props?.disabled || data?.data === {}) {
            setDisabledByLoading(true);
        } else {
            setDisabledByLoading(false);
        }
        let initData = JSON.parse(localStorage.getItem("ResetForm")) || {
            fromDate: null,
            toDate: null,
            status: "All",
            // dialStatus: "All",
            dialStatus: [{ id: "0_all", dialStatus: "All" }],
            // disposition: "All",
            disposition: [{ id: "0_all", disposition: "All" }],
            skill: [],
            agent: [],
            // reason: "All",
            priority: "",
            dialMethod: data?.data?.dialMethod,
        };

        const MaterialUIPickers = connect(({ name, onChangefunction, formik }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const [value, setValue] = useState(null);

            const onSelect = (newValue) => {
                setValue(newValue);
                let newFormatedValue = newValue?.format("DD-MM-YYYY HH:mm:ss");
                formik.setFieldValue(name, newFormatedValue);
            };

            return (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                        <DateTimePicker
                            disableFuture
                            value={value}
                            views={["year", "month", "day", "hours", "minutes", "seconds"]}
                            onChange={onSelect}
                            inputFormat="DD-MM-YYYY HH:mm:ss"
                            renderInput={(params) => <TextField sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    backgroundColor: '#ffff',
                                    fontSize: '14px',
                                    height: '44px',
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #D6D6D7'
                                },
                                '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                                    // backgroundColor: '#D6D6D7', //TODO
                                    border: '1px solid #D6D6D7'
                                }
                            }} {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            );
        })

        const formButtonStyle = useMemo(
            () => ({
                borderRadius: '8px',
                fontWeight: '400',
                fontSize: '14px',
            }),
            []
        );

        const handleDelete = (values) => {
            let id = data.data.campaignId;
            deleteCampaignDataButton(finalData, id);
        }

        const handleReset = (values) => {
            let id = data.data.campaignId;
            console.log("final data", finalData)
            resetCampaignDataButton(finalData, id);
        }

        return (
            <div>
                <Formik
                    initialValues={initData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        let saveData = structuredClone(values);
                        localStorage.setItem("ResetForm",JSON.stringify(values));
                        let id = data.data.campaignId;

                        if (!saveData.fromDate) {
                            saveData.fromDate = null;
                        }
                        if (!saveData.toDate) {
                            saveData.toDate = null;
                        }

                        if (saveData.fromDate !== null) {
                            if (saveData.toDate === null) {
                                showWarningNotification("ToDate Should not be empty");
                                return;
                            }
                        }

                        if (saveData.toDate !== null) {
                            if (saveData.fromDate === null) {
                                showWarningNotification("FromDate Should not be empty");
                                return;
                            }
                        }

                        if (saveData.toDate === "Invalid Date" || saveData.fromDate === "Invalid Date") {
                            showErrorNotification("Invalid date, Please check the date passed");
                            return;
                        }

                        if (saveData.fromDate !== null && saveData.toDate !== null) {
                            let CDate = new Date().toString();
                            let NstartDate = moment(saveData.fromDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                            let NEndDate = moment(saveData.toDate, 'DD-MM-YYYY HH:mm:ss').toDate();
                            let timestampCDate = new Date(CDate).getTime();
                            let timestampNstartDate = new Date(NstartDate).getTime();
                            let timestampNEndDate = new Date(NEndDate).getTime();
                            if (timestampCDate <= timestampNstartDate) {
                                showErrorNotification("FromDate should not be greater than Current Date");
                                return
                            }
                            if (timestampCDate <= timestampNEndDate) {
                                showErrorNotification("ToDate should not be greater than Current Date");
                                return
                            }
                            if (timestampNstartDate >= timestampNEndDate) {
                                showErrorNotification("ToDate should be greater than FromData");
                                return
                            }
                        }

                        // if (saveData.fromDate !== null && saveData.toDate !== null) {
                        //     if (Date.parse(saveData.toDate) <= Date.parse(saveData.fromDate)) {
                        //         showWarningNotification("toDate should be greater than fromData");
                        //         return
                        //     }
                        // }

                        if (saveData.status === "All") {
                            saveData.status = "";
                        }

                        // if (saveData.disposition === "All") {
                        //     saveData.disposition = "";
                        // }

                        // if (saveData.reason === "All") {
                        //     saveData.reason = "";
                        // }
                        // saveData.dialStatus = saveData.dialStatus.dialStatus;
                        // saveData.disposition = saveData.disposition.disposition;
                        saveData.dialStatus = saveData.dialStatus.map(item => item.dialStatus).join(",");
                        saveData.disposition = saveData.disposition.map(item => item.disposition).join(",");

                        delete saveData.dialMethod;
                        setFinalData(saveData);
                        getCampaignRegenerateData(saveData, id);
                        setDownloadDataGrid(true);
                    }}
                >
                    {(props) => (
                        <form
                            onSubmit={props.handleSubmit} id="resetCampaign-Form" className="ozcw-scrollbar" style={{
                                maxHeight: '70vh'
                            }}
                        >
                            {disabledByLoading && (
                                <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                    <LoadingScreen />
                                </div>
                            )}
                            <div>
                                {!openDownloadDataGrid &&
                                    <Grid container={true} spacing="24px" marginTop={"5px"}>
                                        <Grid item xs={6}>
                                            <label style={{ fontWeight: 400, fontSize: "12px" }}>From Date</label>
                                            <MaterialUIPickers name="fromDate" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <label style={{ fontWeight: 400, fontSize: "12px" }}>To Date</label>
                                            <MaterialUIPickers name="toDate" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <OZSelect
                                                name="status"
                                                label="Status*"
                                                placeholder={"Select"}
                                                options={["All", "Fail", "Success", "Pending"]}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <CustomAutoComplete
                                                mode='Add'
                                                name="dialStatus"
                                                limitTags={2}
                                                placeholder={"Please Select"}
                                                Datas={reasonsList}
                                                labelHeader='Reason *'
                                                optionLabelName='dialStatus'
                                                showCheckBox={true}
                                                multiple={true}
                                                showAvatar={true}
                                                showAvatarInChip
                                                showAllOption="true"
                                                popupIcon={null}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <CustomAutoComplete
                                                mode='Add'
                                                name="disposition"
                                                limitTags={2}
                                                placeholder={"Please Select"}
                                                Datas={dispositonsList}
                                                labelHeader='Disposition *'
                                                optionLabelName='disposition'
                                                showCheckBox={true}
                                                multiple={true}
                                                showAvatar={true}
                                                showAvatarInChip
                                                showAllOption="true"
                                                popupIcon={null}
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <OZInput
                                                name="priority"
                                                label="Priority"
                                                placeholder="Priority"
                                            />
                                        </Grid>

                                        {data?.data?.dialMethod === "Agentwise" && (
                                            <Grid item xs={6}>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    name="agent"
                                                    limitTags={2}
                                                    labelHeader='Agent *'
                                                    placeholder={"Select"}
                                                    Datas={agentList}
                                                    optionLabelName='agentId'
                                                    optionId='agentId'
                                                    showCheckBox={true}
                                                    multiple={true}
                                                    showselectall="true"
                                                    popupIcon={null}
                                                />
                                            </Grid>
                                        )}

                                        {data?.data?.dialMethod === "Skillwise" && (
                                            <Grid item xs={6}>
                                                <CustomAutoComplete
                                                    mode='Add'
                                                    name="skill"
                                                    limitTags={2}
                                                    labelHeader='Skill *'
                                                    placeholder={"Select"}
                                                    Datas={skillList}
                                                    optionLabelName='skillName'
                                                    showCheckBox={true}
                                                    multiple={true}
                                                    showselectall="true"
                                                    popupIcon={null}
                                                />
                                            </Grid>
                                        )}

                                        <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 5 }} justifyContent='end'>
                                            <Grid item>
                                                <Button style={formButtonStyle} onClick={closeResetCampaignDataform} variant="outlined" >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item >
                                                <Button style={formButtonStyle} type="submit" variant="contained" form="resetCampaign-Form" >
                                                    Preview Data
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>}

                                {openDownloadDataGrid && (
                                    <>
                                        {newLoading && (
                                            <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                                                <LoadingScreen />
                                            </div>
                                        )}
                                        <div style={{ minWidth: '1150px' }}>
                                            <Grid container spacing={2} direction={'row'} flexGrow={1} sx={{ marginTop: 1 }} alignItems="center" justifyContent='space-between' >
                                                <div onClick={closeDataGrid} style={{ marginLeft: '15px', marginBottom: '-25px' }} >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/icons/backIcon.svg`}
                                                        alt="Back"
                                                    ></img>
                                                    <Button variant='text' style={{ color: "#536580" }}>Back</Button>
                                                </div>
                                                <Grid item xs={12}>
                                                    <OZGridToolbar rowCount={rowData?.length} hideSearch={true} hideDownload={true} />
                                                    <OZDataGrid
                                                        gridLayout={true}
                                                        columns={columnData}
                                                        name="ResetCampaign"
                                                        data={rowData}
                                                        height="23.3rem"
                                                        resizable={true} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} direction={'row'} flexGrow={1} flex sx={{ marginTop: 13, paddingBottom: '15px' }} alignItems="center" justifyContent='space-between'>
                                                <Grid item>
                                                    <img onClick={() => handleDelete(props.values)} src={`${process.env.PUBLIC_URL}/icons/deleteicon.svg`} alt='remove2'></img>
                                                </Grid>
                                                <Grid item>
                                                    <Button style={formButtonStyle} onClick={closeResetCampaignDataform} variant="outlined" >
                                                        Cancel
                                                    </Button>
                                                    <Button style={{ ...formButtonStyle, marginLeft: "20px" }} onClick={() => handleReset(props.values)} variant="contained">
                                                        Reset
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </>
                                )}
                            </div>
                        </form>
                    )}
                </Formik>

            </div >
        );
    };
    return (
        <div>
            <OZDialog
                hideActions
                title={
                    <label style={{ fontSize: '1.125rem', color: '#000000', fontFamily: 'Inter', fontWeight: '400' }}>
                        Reset Campaign
                    </label>
                }
                open={openResetCampaignDataform}
                onClose={closeResetCampaignDataform}
            >
                <Divider style={{ color: "#E6E5E6" }}></Divider>
                {<DataForm data={props?.props} />}
            </OZDialog>
        </div>
    );
};

export default ResetCampaignForm;
